/** @format */
const peopleIcon = 'icon-people';
const speedIcon = 'icon-speedometer';
const graduationIcon = 'icon-graduation';
const heartIcon = 'icon-heart';

const nav = {
  items: [
    {
      name: 'Properties',
      url: '/properties',
      icon: 'icon-home',
      children: [
        {
          name: 'Cataloging',
          url: '/cataloging',
          icon: 'icon-home custom-nav',
        },
        // {
        //   name: 'Media Requests',
        //   url: '/media-requests',
        //   icon: 'icon-credit-card custom-nav',
        // },
        {
          name: 'Fresh Listings',
          url: '/fresh-listings',
          icon: 'icon-fire custom-nav',
        },
      ],
    },
    {
      name: 'Media Management',
      url: '/media-management',
      icon: speedIcon,
      children: [
        // {
        //   name: 'Properties Media',
        //   url: '/properties-media',
        //   icon: `${speedIcon} custom-nav`,
        // },
        {
          name: 'Areas Media',
          url: '/AreasMedia',
          icon: `${speedIcon} custom-nav`,
        },
      ],
    },
    {
      name: 'User Management',
      url: '/user-management',
      icon: peopleIcon,
      children: [
        {
          name: 'Graana Users',
          url: '/graana-users',
          icon: `${peopleIcon} custom-nav`,
        },
        {
          name: 'Customers Management',
          url: '/customers-management',
          icon: `${peopleIcon} custom-nav`,
        },
        {
          name: 'Agencies Management',
          url: '/agencies-management',
          icon: 'icon-direction custom-nav',
        },
        {
          name: 'Developers Management',
          url: '/developers-management',
          icon: 'icon-vector custom-nav',
        },
      ],
    },
    {
      name: 'Guru Management',
      url: '/guru-management',
      icon: 'icon-grid',
    },
    {
      name: 'Setups',
      url: '/setups',
      icon: 'icon-grid',
      children: [
        {
          name: 'Cities',
          url: '/cities',
          icon: 'icon-briefcase custom-nav',
        },
        {
          name: 'Areas',
          url: '/areas',
          icon: 'icon-location-pin custom-nav',
        },
        {
          name: 'Search Page SEO',
          url: '/seo-areas',
          icon: 'icon-location-pin custom-nav',
        },
        {
          name: 'Area Groups',
          url: '/area-groups',
          icon: 'icon-layers custom-nav',
        },
        {
          name: 'Zones',
          url: '/zones',
          icon: 'icon-direction custom-nav',
        },
        {
          name: 'Regions',
          url: '/regions',
          icon: 'icon-map custom-nav',
        },
      ],
    },
    {
      name: 'Projects Management',
      url: '/project-management',
      icon: 'icon-notebook',
      children: [
        {
          name: 'Projects',
          url: '/projects',
          icon: 'icon-notebook custom-nav',
        },
        {
          name: 'Advanced Projects',
          url: '/advanced-projects',
          icon: 'icon-notebook custom-nav',
        },
        {
          name: 'Cover / Footer Ads',
          url: '/cover-footer-ads',
          icon: 'icon-star custom-nav',
        },
        {
          name: 'Featured / Mega',
          url: '/featured-mega',
          icon: 'icon-badge custom-nav',
        },
      ],
    },
    {
      name: 'Wanted Voice Leads',
      url: '/wanted-voice-leads',
      icon: heartIcon,
    },
    {
      name: 'RWR Management',
      url: '/rwr-management',
      icon: speedIcon,
    },
    {
      name: 'Dashboards',
      url: '/dashboards',
      icon: speedIcon,
      children: [
        {
          name: 'OPVS Requests',
          url: '/opvs-requests',
          icon: `${speedIcon} custom-nav`,
        },
        {
          name: 'Listings in Zone',
          url: '/zonal-dashboard',
          icon: `${speedIcon} custom-nav`,
        },
        {
          name: 'Geotagged Properties',
          url: '/geotagged-properties',
          icon: 'icon-home custom-nav',
        },
        {
          name: 'Careers (CVs)',
          url: '/careers-cvs',
          icon: `${graduationIcon} custom-nav`,
        },
        {
          name: 'Jobs Dashboard',
          url: '/jobs-dashboard',
          icon: `${speedIcon} custom-nav`,
        },
        {
          name: 'Invest Leads (Wanted)',
          url: '/invest-leads-wanted',
          icon: `${heartIcon} custom-nav`,
        },
      ],
    },
    {
      name: 'Wanted',
      url: '/wanted',
      icon: heartIcon,
    },
    {
      name: 'Dispute',
      url: '/dispute',
      icon: heartIcon,
    },
    {
      name: 'Reports',
      urls: '/reports',
      icon: 'icon-speedometer',
      children: [
        {
          name: 'Complaints',
          url: '/complaints',
          icon: 'icon-loop custom-nav',
        },
        {
          name: 'Feedback',
          url: '/feedback',
          icon: 'icon-loop custom-nav',
        },
        {
          name: 'Inquiries',
          url: '/inquiries',
          icon: 'icon-question custom-nav',
        },
        {
          name: 'Activity Logs',
          url: '/activity-logs',
          icon: 'icon-heart custom-nav',
        },
      ],
    },
    {
      name: 'Access Management',
      url: '/access-management',
      icon: 'icon-cup',
      children: [
        {
          name: 'Users',
          url: '/users',
          icon: `${peopleIcon} custom-nav`,
        },
        // {
        //   name: 'Field Force Management',
        //   url: '/field-force-management',
        //   icon: 'fa fa-bicycle custom-nav',
        // },
        {
          name: 'Permission Groups',
          url: '/permission-groups',
          icon: 'icon-cup custom-nav',
        },
      ],
    },
    {
      name: 'Push Notifications',
      url: '/push-notification-form',
      icon: 'icon-grid',
    },
    {
      name: 'Logout',
      url: '/logout',
      icon: 'icon-logout',
      // variant: 'graana-red',
    },
  ],
};

export default nav;
