/** @format */

import moment from 'moment';
import { connect } from 'react-redux';
import axios from '../utils/axiosInstance';
import 'react-select/dist/react-select.css';
import DateFnsUtils from '@date-io/moment';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Alert,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { DateRangePicker, isInclusivelyBeforeDay, SingleDatePicker } from 'react-dates';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import { CanEdit, CanView } from '../hoc/CanDo';
import { BACK_MESSAGE, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../static/_constants';
import { WANTED_HEADER, wantedAllRowData } from '../common/ExportCSVData';
import {
  filterExportData,
  generateCSV,
  handleUnload,
  logPageView,
  setHeader,
} from '../common/actions';
import * as types from '../static/_types';
import SearchBar from '../components/SearchBar';
import Loading from '../components/Loading';

const loadingResponseMessage = 'Loading Wanted Leads...';
const agencyWantedListing = 'agency-wanted-listing';
const wantedListing = 'wanted-listing';
const followupDone = 'follow-up-done';
const shortDateFormat = 'MMM DD, HH:mm';
const styleInlineBlock = 'inline-block';
const btnPrimary = 'btn-primary';
const cifCompleted = 'cif-completed';
const notAnswering = 'not-answering';
const poweredOff = 'powered-off';
const irrelevantQuery = 'irrelevant-query';
const invalidNo = 'invalid-no';
const blacklistLeads = 'blacklisted-leads';
const cleanLeads = 'clean-leads';
const dateFormat = 'MMM DD, YYYY';
class Wanted extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      responseMessage: loadingResponseMessage,
      loading: true,
      properties: [],
      listingsModalToggle: false,
      isDataChanged: false,
      activeClientPage: 1,
      clientPages: 1,
      activePage: 1,
      pages: 1,
      q: '',
      activeButton: 'new',
      pageSize: DEFAULT_PAGE_SIZE,
      total: '',
      startDate: null,
      endDate: null,
      focusedInput: null,
      selectedBulkAction: '',
      selectedStatus: '',
      activePendingButton: 'pending',
      subFilter: '',
      cmLead: false,
      wantedParams: [],
      activePurpose: 'sale',
      modalVisible: false,
      modalComments: false,
      loadingModal: true,
      airaModal: false,
      modalAiraLogs: [],
      showFilters: false,
      followModal: false,
      followStatus: null,
      focusedTemp: false,
      userModal: false,
      wantedProperty: {
        readyForCataloguer: false,
      },
      newUser: {
        phone: '+92',
      },
      userPhone: '+92',
      dartStats: null,
      dartFormatted: '00:00:00',
      qualifiedLeadRatio: 0,
      totalClosedRatio: 0,
      dateSearch: false,
      referModal: false,
      followDate: null,
      followTime: null,
      timeMaterial: null,
      activeOverdue: 'all',
      scheduleLogModal: false,
      scheduleModal: false,
      scheduleTime: null,
      blacklistCustomer: {
        blacklisted: '',
      },
      wantedPropertyId: null,
      loadingDart: true,
      loadingQLR: true,
      loadingLCR: true,
      loadingCPM: true,
      loadingCPD: true,
      newLeadsNotification: 0,
      alertVisible: false,
      clearButton: false,
      setClearButton: 0,
    };

    this.dataToExport = [];
    this.endPoint = '/api/dhoondo/wanted';
    this.statsEndPoint = '/api/dhoondo/user/wanted-stats';

    this.pageListingType =
      match.path === '/agencies-management/agency-wanted/:agencyId'
        ? agencyWantedListing
        : wantedListing;

    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.handleClientPageSelect = this.handleClientPageSelect.bind(this);
    this.getCSV = this.getCSV.bind(this);
    this.getCurrentCSV = this.getCurrentCSV.bind(this);
    this.handleMyWanted = this.handleMyWanted.bind(this);
    this.handleOverdue = this.handleOverdue.bind(this);
    this.handleBlacklistFilter = this.handleBlacklistFilter.bind(this);
    this.handleReason = this.handleReason.bind(this);
    this.handleShared = this.handleShared.bind(this);
    this.handleFilterWanted = this.handleFilterWanted.bind(this);
    this.handleRefer = this.handleRefer.bind(this);
    this.renderClientHistory = this.renderClientHistory.bind(this);
    this.renderRefer = this.renderRefer.bind(this);
    this.renderCreateUser = this.renderCreateUser.bind(this);
    this.renderFollowUp = this.renderFollowUp.bind(this);
    this.renderSchedule = this.renderSchedule.bind(this);
    this.renderAiraLogs = this.renderAiraLogs.bind(this);
    this.renderCallLogs = this.renderCallLogs.bind(this);
    this.renderScheduleLog = this.renderScheduleLog.bind(this);
    this.renderCommentHistory = this.renderCommentHistory.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderDownloadAll = this.renderDownloadAll.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderSearchFields = this.renderSearchFields.bind(this);
    this.renderSubzoneSearch = this.renderSubzoneSearch.bind(this);
    this.renderZoneSearch = this.renderZoneSearch.bind(this);
    this.renderAreaSearch = this.renderAreaSearch.bind(this);
    this.renderAllSearch = this.renderAllSearch.bind(this);
    this.renderStats = this.renderStats.bind(this);
    this.renderShowFilter = this.renderShowFilter.bind(this);
    this.renderDateFilter = this.renderDateFilter.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.renderNewFilter = this.renderNewFilter.bind(this);
    this.renderFollowUpFilter = this.renderFollowUpFilter.bind(this);
    this.renderAssignedFilter = this.renderAssignedFilter.bind(this);
    this.renderRejectedFilter = this.renderRejectedFilter.bind(this);
    this.renderRefreshAlert = this.renderRefreshAlert.bind(this);
    this.renderNavBar = this.renderNavBar.bind(this);
    this.renderPurposeNav = this.renderPurposeNav.bind(this);
    this.renderFiltersNav = this.renderFiltersNav.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderTableHead = this.renderTableHead.bind(this);
    this.renderTableHeadFirst = this.renderTableHeadFirst.bind(this);
    this.renderTableHeadSecond = this.renderTableHeadSecond.bind(this);
    this.renderTableHeadThird = this.renderTableHeadThird.bind(this);
    this.renderTableBody = this.renderTableBody.bind(this);
    this.renderTableBodyFirst = this.renderTableBodyFirst.bind(this);
    this.renderTableBodySecond = this.renderTableBodySecond.bind(this);
    this.renderTableBodyThird = this.renderTableBodyThird.bind(this);
    this.renderTableBodyFourth = this.renderTableBodyFourth.bind(this);
    this.renderTableBodyActions = this.renderTableBodyActions.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.renderBulk = this.renderBulk.bind(this);
    this.renderPageSize = this.renderPageSize.bind(this);
    this.singlePending = this.singlePending.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    setHeader(dispatch, 'Wanted');
    logPageView('Wanted');
    this.fetchProperties();
    axios
      .get('/api/zone/subzones', {
        params: {
          all: true,
          active: true,
        },
      })
      .then((response) => {
        this.setState({ areagroups: response.data.items, isLoadingAreagroup: false }, () => {
          let { areagroups } = this.state;
          areagroups = areagroups.map((areagroup) => ({
            ...areagroup,
            info: `${areagroup.name} (ID: ${areagroup.id})`,
          }));
          this.setState({ areagroups, isLoadingAreagroup: false });
        });
      });
    this.fetchStats();
    this.timerId = setInterval(() => this.getNewLeadsNotification(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
    this.timerId = null;
  }

  handleRefer(e) {
    e.preventDefault();
    const { referrerId, followStatus, wantedPropertyId } = this.state;
    this.setState({
      loading: true,
      properties: [],
      pages: 1,
      activePage: 1,
      responseMessage: loadingResponseMessage,
      referModal: false,
    });
    axios
      .patch(`${this.endPoint}/${wantedPropertyId}`, {
        referrerId,
        reason: followStatus,
      })
      .then(() => {
        this.setState({
          loadingDart: true,
          loadingQLR: true,
          loadingLCR: true,
          loadingCPD: true,
          loadingCPM: true,
        });
        this.fetchStats();
        this.fetchProperties();
      });
  }

  handleUserInput = (event) => {
    const { value, name } = event.target;
    const { newUser } = this.state;
    newUser[name] = value;
    this.setState({ newUser });
    this.setState({ isDataChanged: true });
  };

  handleUserPhone(phone) {
    let { userPhone } = this.state;
    const { newUser } = this.state;
    newUser.phone = phone;
    userPhone = phone;
    if (userPhone.length <= 12 && newUser.phone.length <= 12) {
      this.setState({ phoneError: true });
    } else {
      this.setState({ phoneError: false });
    }
    this.setState({ userPhone, newUser });
    this.setState({ isDataChanged: true });
  }

  handleOverdue(status) {
    this.setState(
      {
        activeOverdue: status,
        activePage: 1,
        properties: [],
        loading: true,
        responseMessage: loadingResponseMessage,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handleBlacklistFilter(status) {
    this.setState(
      {
        activeBlacklist: status,
        activePage: 1,
        properties: [],
        loading: true,
        responseMessage: loadingResponseMessage,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handleMyWanted(status) {
    const { activePurpose } = this.state;
    if (activePurpose !== 'invest') {
      this.setState(
        {
          myWanted: status,
          activePage: 1,
          properties: [],
          loading: true,
          responseMessage: loadingResponseMessage,
        },
        () => {
          this.fetchProperties();
        }
      );
    }
  }

  handleReason(reason, status) {
    this.setState(
      {
        activeButton: status,
        activeReason: reason,
        activePage: 1,
        properties: [],
        colSearch: '',
        loading: true,
        responseMessage: loadingResponseMessage,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handleShared(reason, status) {
    this.setState(
      {
        activeButton: status,
        activeShare: reason,
        activePage: 1,
        properties: [],
        activePendingButton: '',
        loading: true,
        responseMessage: loadingResponseMessage,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handleFilterWanted(status) {
    this.setState(
      {
        activeButton: status,
        activePendingButton: '',
        activeOverdue: 'all',
        activeShare: null,
        loading: true,
        subFilter: '',
        colSearch: '',
        responseMessage: loadingResponseMessage,
        properties: [],
        activePage: 1,
        activeReason: null,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handleSearchType(value) {
    const { setClearButton, clearButton, q } = this.state;
    this.setState(
      {
        type: value ? value.value : null,
        q: '',
        clearButton: false,
      },
      () => {
        if (!value) {
          if (clearButton || (setClearButton === 2 && !q)) {
            this.setState({
              loading: true,
              properties: [],
              activePage: 1,
              responseMessage: loadingResponseMessage,
            });
            this.fetchProperties();
          }
        }
      }
    );
  }

  handleSearch(event) {
    const { q, type, startDate, endDate } = this.state;
    if ((q && type) || startDate || endDate) {
      if (event && event.target && event.target.name === 'dateSearch') {
        this.setState({ dateSearch: true });
      }
      this.setState(
        {
          loading: true,
          activePage: 1,
          properties: [],
          responseMessage: loadingResponseMessage,
        },
        () => {
          this.fetchProperties();
        }
      );
    } else if (!q && !type && (startDate || endDate)) {
      if (event && event.target && event.target.name === 'dateSearch') {
        this.setState({ dateSearch: true });
      }
      this.setState(
        {
          type: null,
          loading: true,
          activePage: 1,
          properties: [],
          responseMessage: loadingResponseMessage,
        },
        () => {
          this.fetchProperties();
        }
      );
    }
    if (q && type) {
      this.setState({ clearButton: true });
    }
    if (!q && !type && (startDate || endDate)) {
      this.setState({ clearButton: false, setClearButton: 0 });
    } else {
      this.setState({ setClearButton: 2 });
    }
  }

  handlePageSize(event) {
    this.setState(
      {
        pageSize: event.target.value,
        activePage: 1,
        properties: [],
        loading: true,
        responseMessage: loadingResponseMessage,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handlePageSelect(page) {
    this.setState(
      {
        activePage: page.selected + 1,
        properties: [],
        loading: true,
        responseMessage: loadingResponseMessage,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handleClientPageSelect(page) {
    this.setState(
      {
        activeClientPage: page.selected + 1,
        clientHistory: [],
        loadingClient: true,
      },
      () => {
        this.fetchClientHistory();
      }
    );
  }

  onDatesChange(startDate, endDate) {
    if (!startDate && !endDate) {
      this.setState({ dateSearch: true, startDate, endDate });
    } else {
      this.setState({
        startDate,
        endDate,
        dateSearch: false,
      });
    }
  }

  getNewLeadsNotification = () => {
    const { newLeadsNotification } = this.state;
    axios.get('/api/dhoondo/wanted/new-leads').then((response) => {
      if (response.data.newLead) {
        this.setState({
          newLeadsNotification: response.data.leadId.length + newLeadsNotification,
          alertVisible: true,
        });
      }
    });
  };

  getPrevParams() {
    const { wantedParams } = this.props;
    const { history } = this.props;
    let statusValue;
    if (history.location.state) {
      history.location.state.message = '';
    }
    this.setState({
      activePage: wantedParams.activePage,
      activePurpose: wantedParams.activePurpose,
      pageSize: wantedParams.pageSize,
      q: wantedParams.q,
      type: wantedParams.type,
      activeButton: wantedParams.activeButton,
      startDate: wantedParams.startDate,
      endDate: wantedParams.endDate,
      activeReason: wantedParams.activeReason,
      cmLead: wantedParams.cmLead,
      myWanted: wantedParams.myWanted,
      activePendingButton: wantedParams.activePendingButton,
      showFilters: wantedParams.showFilters,
      clearButton: wantedParams.clearButton,
      zone: {
        id: wantedParams.zoneId,
      },
      areagroup: {
        id: wantedParams.areagroupId,
      },
    });
    if (wantedParams.activeButton === 'new') {
      statusValue = 'pending';
      wantedParams.activePendingButton = 'new';
    } else {
      statusValue = wantedParams.activeButton;
    }
    if (wantedParams.activePurpose === 'invest') {
      statusValue = 'approved';
      wantedParams.activePendingButton = '';
    }
    if (
      history.location &&
      history.location.state &&
      history.location.state.button &&
      history.location.state.button === 'assigned'
    ) {
      statusValue = 'approved';
      wantedParams.activePendingButton = '';
      wantedParams.activeButton = 'approved';
      this.setState({ activeButton: 'approved' });
      history.location.state.button = '';
    }
    return {
      params: {
        cmLead: wantedParams.activePurpose === 'invest',
        purpose: wantedParams.activePurpose !== 'invest' ? wantedParams.activePurpose : null,
        page: wantedParams.activePage,
        myWanted: wantedParams.myWanted ? true : null,
        startDate: moment(wantedParams.startDate).toISOString(),
        endDate: moment(wantedParams.endDate).toISOString(),
        pageSize: wantedParams.pageSize,
        q: wantedParams.type && wantedParams.clearButton ? wantedParams.q : null,
        searchby: wantedParams.q ? wantedParams.type : null,
        status: wantedParams.activeButton !== 'inprogress' ? statusValue : null,
        inProgress: wantedParams.activeButton === 'inprogress' ? true : null,
        substatus:
          wantedParams.activeButton === 'approved' || wantedParams.activeButton === 'new'
            ? wantedParams.activePendingButton
            : null,
        reason: wantedParams.activeButton !== 'new' ? wantedParams.activeReason : null,
      },
    };
  }

  getClientParams() {
    const { customerId, activeClientPage, pageSize } = this.state;
    return {
      params: {
        searchby: 'customer',
        q: customerId,
        page: activeClientPage,
        pageSize,
      },
    };
  }

  getParams() {
    const {
      activePage,
      activePurpose,
      activeOverdue,
      activeBlacklist,
      pageSize,
      q,
      type,
      startDate,
      endDate,
      activeReason,
      activeShare,
      cmLead,
      myWanted,
      colSearch,
      showFilters,
      clearButton,
      cancelToken,
      zone,
      areagroup,
    } = this.state;
    let { activeButton } = this.state;
    let { activePendingButton } = this.state;
    let statusValue;
    let shareValue;
    let blacklistValue;
    if (activeButton === 'new') {
      statusValue = 'pending';
      activePendingButton = 'new';
    } else {
      statusValue = activeButton;
    }
    if (activeBlacklist === blacklistLeads) {
      blacklistValue = true;
    } else if (activeBlacklist === cleanLeads) {
      blacklistValue = false;
    }
    if (activePurpose === 'invest') {
      statusValue = 'approved';
      activePendingButton = '';
      activeButton = '';
    }
    if (activeShare === 'shared') {
      shareValue = true;
    } else if (activeShare === 'not-shared') {
      shareValue = false;
    }
    this.setState(
      {
        wantedParams: {
          activePage,
          activePurpose,
          pageSize,
          q,
          type,
          activeButton: !activeButton ? 'new' : activeButton,
          startDate,
          endDate,
          activeReason,
          cmLead,
          myWanted,
          activePendingButton,
          activeShare,
          colSearch,
          showFilters,
          clearButton,
          zoneId: zone ? zone.id : null,
          areagroupId: areagroup ? areagroup.id : null,
        },
      },
      () => {
        const { dispatch } = this.props;
        const { wantedParams } = this.state;
        dispatch({
          type: types.SET_WANTED,
          payload: wantedParams,
        });
      }
    );
    return {
      cancelToken: cancelToken.token,
      params: {
        cmLead: activePurpose === 'invest',
        purpose: activePurpose !== 'invest' ? activePurpose : null,
        page: activePage,
        myWanted: myWanted ? true : null,
        colSearch: colSearch || null,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
        pageSize,
        q: type && clearButton ? q : null,
        searchby: q ? type : null,
        status: activeButton !== 'inprogress' ? statusValue : null,
        inProgress: activeButton === 'inprogress' && statusValue !== 'approved' ? true : null,
        substatus:
          activeButton === 'approved' || activeButton === 'new' ? activePendingButton : null,
        reason: activeButton !== 'new' && activeReason !== 'refer' ? activeReason : null,
        shared: shareValue === true || shareValue === false ? shareValue : null,
        getReferred: activeReason === 'refer' ? true : null,
        overDue: activeOverdue === 'overdue' ? true : null,
        followUpDone: activeOverdue === followupDone ? true : null,
        blacklist: activeBlacklist ? blacklistValue : null,
      },
    };
  }

  getCurrentCSV() {
    const { loadingBigData } = this.state;
    if (!loadingBigData) {
      this.setState({ loadingBigData: true });

      let dataToExport = [WANTED_HEADER, ...this.dataToExport];
      dataToExport = filterExportData(dataToExport);
      generateCSV('Wanted', dataToExport);
      this.setState({ loadingBigData: false });
    }
  }

  getListingId(newProperty) {
    const { activePurpose, wantedProperty } = this.state;
    if (
      (activePurpose !== 'invest' &&
        (activePurpose === 'sell' || activePurpose === 'rentout' || activePurpose === 'rent out') &&
        newProperty.properties &&
        newProperty.properties.length > 0 &&
        newProperty.properties[0] &&
        newProperty.properties[0].readyForCataloguer) ||
      wantedProperty.readyForCataloguer
    ) {
      return (
        <td>
          <button
            type="button"
            style={{
              background: 'none',
              border: 'none',
              margin: 0,
              padding: 0,
              cursor: 'pointer',
              color: 'blue',
              textDecoration: 'underline',
            }}
            onClick={() => this.renderPropertyModal(newProperty.properties.map((prop) => prop.id))}
          >
            {newProperty.properties.map((prop) => prop.id)}
          </button>
        </td>
      );
    }
    if (
      activePurpose !== 'invest' &&
      (activePurpose === 'sell' || activePurpose === 'rentout' || activePurpose === 'rent out') &&
      newProperty.properties[0] &&
      !newProperty.properties[0].readyForCataloguer
    ) {
      return <td>N/A</td>;
    }
    if (
      activePurpose !== 'invest' &&
      (activePurpose === 'sell' || activePurpose === 'rentout' || activePurpose === 'rent out') &&
      newProperty.properties &&
      newProperty.properties.length === 0
    ) {
      return <td>N/A</td>;
    }
    return null;
  }

  getCSV() {
    const { loadingBigData } = this.state;
    let tempParams = this.getParams();
    tempParams = tempParams.params;
    const allParams = {
      all: true,
      status: tempParams.status,
      substatus: tempParams.substatus,
      purpose: tempParams.purpose,
      myWanted: tempParams.myWanted,
      cmLead: tempParams.cmLead,
    };
    if (!loadingBigData) {
      this.setState({ loadingBigData: true });
      axios
        .get(this.endPoint, {
          params: {
            all: true,
            ...allParams,
          },
        })
        .then((response) => {
          const properties = response.data.rows;
          const data = [];
          if (properties && properties.length) {
            properties.map((property, index) => {
              const rowData = wantedAllRowData(properties, property, index);
              data.push(rowData);
              return rowData;
            });
          }
          let dataToExport = [WANTED_HEADER, ...data];
          dataToExport = filterExportData(dataToExport);
          generateCSV('Wanted', dataToExport);
          this.setState({ loadingBigData: false });
        });
    }
  }

  getSearchTypes = () => {
    const { activeButton } = this.state;
    const id = { name: 'Id', value: 'id' };
    const armsuser = { name: 'ARMS Users', value: 'armsuser' };
    const city = { name: 'City', value: 'city' };
    const area = { name: 'Area', value: 'area' };
    const phone = { name: 'Phone', value: 'phone' };
    const zone = { name: 'Zone', value: 'zone' };
    const subzone = { name: 'Area Group', value: 'subzone' };
    const airaEmail = { name: 'AIRA', value: 'aira_email' };
    if (activeButton === 'approved') {
      return [id, armsuser, city, area, phone, zone, subzone, airaEmail];
    }
    return [id, city, area, phone, zone, subzone, airaEmail];
  };

  setArea(area) {
    this.setState({
      area: {
        id: area ? area.id : null,
        name: area ? area.name : '',
      },
      q: area ? area.id : null,
    });
  }

  setAreagroup(areagroup) {
    this.setState({
      clearButton: false,
      areagroup: {
        id: areagroup ? areagroup.id : null,
        name: areagroup ? areagroup.name : '',
      },
      q: areagroup ? areagroup.id : null,
    });
  }

  setZone(zone) {
    this.setState({
      clearButton: false,
      zone: {
        id: zone ? zone.id : null,
        name: zone ? zone.zone_name : '',
      },
      q: zone ? zone.zone_name : null,
    });
  }

  setSystemUser(systemuser) {
    this.setState({ systemUser: { id: systemuser.id, email: systemuser.email } });
    this.setState({ referrerId: systemuser.id });
  }

  getSystemUser() {
    this.setState({ referModal: true, isLoadingSystemUsers: true });
    axios
      .get('/api/system-user/basic-info?getActive=true', {
        params: {
          all: true,
        },
      })
      .then((response) => {
        this.setState({ systemUsers: response.data }, () => {
          let { systemUsers } = this.state;
          systemUsers = systemUsers.map((systemUser) => ({
            ...systemUser,
            info: `${systemUser.email} (ID: ${systemUser.id})`,
          }));
          this.setState({ systemUsers, isLoadingSystemUsers: false });
        });
      });
  }

  setCity(city) {
    const { user } = this.props;
    this.setState({
      clearButton: false,
      city: {
        id: city ? city.id : null,
        name: city ? city.name : '',
      },
      areas: [],
      q: null,
      isLoadingExternally: true,
    });
    if (city && city.id) {
      axios
        .get('/api/area', {
          params: {
            city_id: city.id,
            zone_id: user.zone_id,
            all: true,
          },
        })
        .then((response) => {
          this.setState({
            areas: response.data.items,
            isLoadingExternally: false,
          });
        });
    }
  }

  setModal(wantedId) {
    const { properties } = this.state;
    this.setState({ modalComments: true, loadingComments: true });
    axios.get(`/api/dhoondo/wanted/${wantedId}/aira-comments`).then((response) => {
      this.setState({ wantedComments: response.data.rows, loadingComments: false });
    });
    properties.forEach((property) => {
      if (property.id === wantedId) {
        this.setState(
          {
            armsComments: property.leadComments,
            commentId: wantedId,
          },
          () => {
            const { wantedComments, armsComments } = this.state;
            this.setState({ wantedComments, armsComments });
          }
        );
      }
      return true;
    });
  }

  setDate(event) {
    const { value } = event.target;
    if (value === 'followup') {
      this.setState({ followupDate: 'today', startDate: moment(), endDate: moment() });
    }
    this.setState({ colSearch: value, dateSearch: false });
  }

  setFollowupdate(event) {
    const { value } = event.target;
    this.setState({ followupDate: value, dateSearch: false });
    if (value === 'today') {
      this.setState({ startDate: moment(), endDate: moment() });
    } else if (value === 'tomorrow') {
      this.setState({ startDate: moment().add(1, 'days'), endDate: moment().add(1, 'days') });
    } else if (value === 'custom') {
      this.setState({ startDate: null, endDate: null });
    }
  }

  static getCity() {
    return axios
      .get('/api/city', {
        params: {
          all: true,
        },
      })
      .then((response) => ({ options: response.data.items }));
  }

  static getArray(length) {
    return [...Array(length).keys()];
  }

  static getZone() {
    return axios
      .get('/api/zone/filter', {
        params: {
          all: true,
          status: 'active',
        },
      })
      .then((response) => ({ options: response.data }));
  }

  assign = (e) => {
    if (window.confirm('Are you sure you want to assign the lead?')) {
      this.singleApproveWanted(e, 'approved');
    }
  };

  reject = (e) => {
    const { reason } = this.state;
    if (window.confirm('Are you sure you want to decline the lead?')) {
      this.singleRejected(e, reason);
    }
  };

  getCityCell = (inquiryItem) => {
    if (inquiryItem.city && !inquiryItem.other_city) {
      return <td>{inquiryItem.city.name}</td>;
    }
    return inquiryItem.other_city ? <td>Other</td> : <td />;
  };

  getFollowUpFilterText = (reason) => {
    if (reason === 'rwr_reopened') {
      return 'reopened';
    }
    return reason.replace(/-/g, ' ');
  };

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  };

  clearSearch() {
    this.setState(
      {
        setClearButton: 1,
        clearButton: false,
        q: '',
        area: '',
        city: '',
        zone: '',
        areagroup: '',
        responseMessage: loadingResponseMessage,
        loading: true,
        properties: [],
        activePage: 1,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  fetchClientHistory() {
    axios.get(this.endPoint, this.getClientParams()).then((response) => {
      this.saveClientResponseToState(response);
    });
  }

  viewClientHistory(customerId) {
    this.setState(
      {
        clientModal: true,
        customerId,
        loadingClient: true,
        clientHistory: [],
        clientPages: 1,
        activeClientPage: 1,
      },
      () => {
        this.fetchClientHistory();
      }
    );
  }

  clearDates() {
    const { followupDate, colSearch } = this.state;
    this.setState(
      {
        dateSearch: false,
        startDate: null,
        endDate: null,
        loading: true,
        activePage: 1,
        properties: [],
        responseMessage: loadingResponseMessage,
      },
      () => {
        axios.get(this.endPoint, this.getParams()).then((response) => {
          if (followupDate === 'today' && colSearch === 'followup') {
            this.setState({
              startDate: moment(),
              endDate: moment(),
            });
          } else if (followupDate === 'tomorrow' && colSearch === 'followup') {
            this.setState({
              startDate: moment().add(1, 'days'),
              endDate: moment().add(1, 'days'),
            });
          }
          this.saveResponseToState(response);
        });
      }
    );
  }

  calculateDart(response) {
    this.setState(
      {
        dartStats: response.data.averageResponseTime
          ? response.data.averageResponseTime.sumDifferenceInSeconds
          : '00:00:00',
        loadingDart: false,
      },
      () => {
        let { dartStats } = this.state;
        dartStats = parseFloat(dartStats, 10);
        const dartHour = Math.floor(dartStats / 60 / 60);
        const dartMinute = Math.floor(dartStats / 60) - dartHour * 60;
        const dartSecond = parseInt(dartStats, 10) % 60;
        const dartFormatted = `${dartHour.toString().padStart(2, '0')}:${dartMinute
          .toString()
          .padStart(2, '0')}:${dartSecond.toString().padStart(2, '0')}`;
        this.setState({ dartFormatted });
      }
    );
  }

  calculateLCR(response) {
    this.setState({
      totalClosedRatio:
        response.data.leadConversionRatio && response.data.leadConversionRatio !== 'NaN'
          ? response.data.leadConversionRatio.totalClosedRatio
          : 0,
      loadingLCR: false,
    });
  }

  calculateQLR(response) {
    this.setState({
      qualifiedLeadRatio:
        response.data.qualifiedLeadRatio && response.data.qualifiedLeadRatio !== 'NaN'
          ? response.data.qualifiedLeadRatio.userActualLeadsRatio
          : 0,
      loadingQLR: false,
    });
  }

  calculateCPM(response) {
    this.setState({ cpm: response.data ? response.data.callsPerMonth : 0, loadingCPM: false });
  }

  calculateCPD(response) {
    this.setState({ cpd: response.data ? response.data.callsPerDay : 0, loadingCPD: false });
  }

  createUser(event) {
    event.preventDefault();
    const { loading, newUser, phoneError, userPhone, leadIdUser, leadUser } = this.state;
    newUser.phone = userPhone;
    newUser.wanted_id = leadIdUser;
    leadUser.area_id = leadUser.wanted_areas && leadUser.wanted_areas.map((areas) => areas.areaId);
    this.setState({ leadUser }, () => {
      newUser.lead = leadUser;
      this.setState({ newUser }, () => {
        if (!loading) {
          this.setState({
            loading: true,
          });
          if (!phoneError) {
            axios
              .post('/api/user', newUser)
              .then((response) => {
                if (response) {
                  window.alert('User created successfully');
                }
                this.setState({
                  loading: false,
                  userModal: false,
                  newUser: {},
                  userPhone: '+92',
                });
              })
              .catch((error) => {
                if (error.response.data === 'Email already exists') {
                  window.alert('User already exists');
                }
                this.setState({
                  loading: false,
                });
              });
          } else {
            window.alert('Enter valid phone number');
            this.setState({ loading: false });
          }
        }
      });
    });
    this.setState({ isDataChanged: false });
  }

  clearInput() {
    this.inputTitle.value = '';
  }

  submitComment(wantedId) {
    const { message } = this.state;
    axios
      .post(`/api/dhoondo/comment/${wantedId}`, { comment: message })
      .then(() => {
        this.clearInput();
        axios.get(`/api/dhoondo/wanted/${wantedId}/aira-comments`).then((response) => {
          this.setState(
            {
              wantedComments: response.data.rows,
            },
            () => {
              const { wantedComments } = this.state;
              this.setState({ wantedComments });
              this.scrollToBottom();
            }
          );
        });
      })
      .then(() => {
        this.setState({ message: '' });
      });
  }

  showDetails(wantedId) {
    const { activePurpose } = this.state;
    this.setState({
      modalVisible: true,
      loadingModal: true,
    });
    if (activePurpose !== 'invest') {
      axios.get(`/api/dhoondo/call-log/${wantedId}?cmLead=false`).then((response) => {
        this.setState({
          callLogs: response.data,
          callLength: response.data ? response.data.total : 0,
          loadingModal: false,
        });
      });
    } else {
      axios.get(`/api/dhoondo/call-log/${wantedId}?cmLead=true`).then((response) => {
        this.setState({
          callLogs: response.data,
          loadingModal: false,
        });
      });
    }
  }

  selectAllRecords(e) {
    let { properties: allProperties } = this.state;

    allProperties = allProperties.map((property) => {
      const newProperty = property;
      newProperty.selected = e.target.checked;
      return newProperty;
    });
    this.setState({ properties: allProperties });
  }

  selectRecord(e, selectedProperty) {
    let { properties: allProperties } = this.state;
    allProperties = allProperties.map((property) => {
      const newProperty = property;
      if (newProperty.id === selectedProperty.id) {
        newProperty.selected = e.target.checked;
      }
      return newProperty;
    });
    this.setState({ properties: allProperties });
  }

  changePurpose(purpose) {
    this.setState(
      {
        activePurpose: purpose,
        activePage: 1,
        properties: [],
        loading: true,
        responseMessage: loadingResponseMessage,
        myWanted: purpose === 'invest' ? true : null,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  async fetchProperties() {
    let { cancelToken } = this.state;
    const { match, history, back, dispatch, wantedParams } = this.props;
    if (this.pageListingType === agencyWantedListing) {
      axios.get(`/api/agency/agencywanted/${match.params.agencyId}`).then((response) => {
        this.saveResponseToState(response);
      });
    } else if (
      (back && history.action === 'POP' && wantedParams) ||
      (history.action !== 'POP' &&
        history.location.state &&
        history.location.state.message === 'submit' &&
        wantedParams) ||
      (history.action !== 'POP' &&
        history.location.state &&
        history.location.state.message === 'breadcrumbs' &&
        wantedParams) ||
      (history.action !== 'POP' &&
        history.location &&
        history.location.state &&
        history.location.state.button &&
        history.location.state.button === 'assigned' &&
        wantedParams)
    ) {
      dispatch({
        type: types.CLEAR_BACK,
      });
      axios.get(this.endPoint, this.getPrevParams()).then((response) => {
        this.saveResponseToState(response);
      });
    } else {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Operation cancelled due to new request');
      }
      cancelToken = axios.CancelToken.source();
      this.setState({ cancelToken }, () => {
        axios
          .get(this.endPoint, this.getParams())
          .then((response) => {
            this.saveResponseToState(response);
          })
          .catch((/* error */) => {
            // console.log(error);
          });
      });
    }
  }

  fetchStats() {
    axios.get(`${this.statsEndPoint}?type=dart`).then((response) => {
      this.calculateDart(response);
    });
    axios.get(`${this.statsEndPoint}?type=lcr`).then((response) => {
      this.calculateLCR(response);
    });
    axios.get(`${this.statsEndPoint}?type=qlr`).then((response) => {
      this.calculateQLR(response);
    });
    axios.get(`${this.statsEndPoint}?type=cpm`).then((response) => {
      this.calculateCPM(response);
    });
    axios.get(`${this.statsEndPoint}?type=cpd`).then((response) => {
      this.calculateCPD(response);
    });
  }

  saveClientResponseToState(response) {
    return this.setState((prevState) => ({
      loadingClient: false,
      clientHistory: response.data.rows,
      clientPages: Math.ceil(response.data.count / prevState.pageSize),
    }));
  }

  blacklistClient(customerId, status, wantedId) {
    const { blacklistCustomer } = this.state;
    this.setState({ loading: true });
    blacklistCustomer.blacklisted = !status;
    blacklistCustomer.wanted_id = wantedId;
    axios
      .put(`/api/dhoondo/blacklist-client/${customerId}`, blacklistCustomer)
      .then(() => {
        if (status) {
          window.alert('Client removed from black-list');
        } else {
          window.alert('Client added to black-list');
        }
      })
      .then(() => {
        this.setState(
          { loading: true, activePage: 1, responseMessage: loadingResponseMessage, properties: [] },
          () => {
            this.fetchProperties();
          }
        );
      });
  }

  saveResponseToState(response) {
    return this.setState(
      (prevState) => ({
        loading: false,
        total: response.data.count,
        properties: response.data.rows,
        responseMessage: 'No Wanted Leads Found',
        pages: Math.ceil(response.data.count / prevState.pageSize),
      }),
      () => {
        if (this.myRef && this.myRef.offsetTop) {
          window.scrollTo(0, this.myRef.offsetTop);
        }
      }
    );
  }

  singleRejected(event, reason) {
    event.preventDefault();
    const { wantedPropertyId, loading } = this.state;
    if (!loading) {
      this.setState(
        {
          loading: true,
          properties: [],
          pages: 1,
          activePage: 1,
          responseMessage: loadingResponseMessage,
        },
        () => {
          axios
            .put(this.endPoint, {
              wantedIds: wantedPropertyId,
              status: 'rejected',
              reason,
            })
            .then((response) => {
              if (typeof response.data === 'string') {
                window.alert(response.data);
                this.setState({
                  loading: false,
                });
              } else {
                this.setState({
                  referModal: false,
                });
              }
              this.setState({
                loadingDart: true,
                loadingQLR: true,
                loadingLCR: true,
                loadingCPD: true,
                loadingCPM: true,
              });
              this.fetchStats();
              this.fetchProperties();
            });
        }
      );
    }
  }

  scheduleModal(wantedId) {
    this.setState(
      {
        scheduleModal: true,
        wantedPropertyId: wantedId,
        scheduleLoading: true,
      },
      () => {
        axios.get(`/api/property/wantedproperty/${wantedId}`).then((response) => {
          this.setState({
            scheduleDate: response.data.results.agent_schedule_date
              ? moment(response.data.results.agent_schedule_date)
              : null,
            scheduleTime: response.data.results.agent_schedule_time,
            timeMaterial: response.data.results.agent_schedule_time
              ? moment(`22 Jan, 2021 ${response.data.results.agent_schedule_time}`)
              : null,
            scheduleLoading: false,
          });
        });
      }
    );
  }

  scheduleViewing(event) {
    event.preventDefault();
    const { scheduleDate, scheduleTime, wantedPropertyId } = this.state;
    axios
      .patch(`/api/dhoondo/wanted/${wantedPropertyId}/scheduleAgent`, {
        agent_schedule_date: scheduleDate,
        agent_schedule_time: scheduleTime,
      })
      .then(() => {
        this.setState({
          scheduleModal: false,
          scheduleDate: null,
          scheduleTime: null,
          timeMaterial: null,
        });
      });
  }

  singlePending(event) {
    event.preventDefault();
    const { loading, followDate, followTime, followStatus, wantedPropertyId } = this.state;
    if (!loading) {
      if (followDate && !followTime) {
        window.alert('Time is required');
      } else {
        this.setState(
          {
            loading: true,
            properties: [],
            pages: 1,
            activePage: 1,
            followModal: false,
            responseMessage: loadingResponseMessage,
          },
          () => {
            axios
              .put(this.endPoint, {
                wantedIds: wantedPropertyId,
                status: 'pending',
                reason: followStatus,
                followup_date: followDate,
                followup_time: followTime,
              })
              .then((response) => {
                if (typeof response.data === 'string') {
                  window.alert(response.data);
                  this.setState({ loading: false });
                } else {
                  this.setState({
                    followModal: false,
                    followDate: null,
                    followTime: null,
                    timeMaterial: null,
                  });
                  this.setState({
                    loadingDart: true,
                    loadingQLR: true,
                    loadingLCR: true,
                    loadingCPD: true,
                    loadingCPM: true,
                  });
                  this.fetchStats();
                  this.fetchProperties();
                }
              });
          }
        );
      }
    }
  }

  propertyModal() {
    const { listingsModalToggle, listingsModal } = this.state;
    return (
      <Modal size="md" isOpen={listingsModalToggle}>
        <ModalHeader toggle={() => this.setState({ listingsModalToggle: false })}>
          <div className="grey">{`Listings Details: ${listingsModal ? listingsModal.id : ''}`}</div>
        </ModalHeader>
        <ModalBody style={{ overflowY: 'auto' }}>
          <div style={{ maxHeight: '450px' }}>
            <div className="space-0">
              <span>Purpose:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.purpose : ''}</strong>
            </div>
            <div className="space-0">
              <span>Type:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.type : ''}</strong>
            </div>
            <div className="space-0">
              <span>Subtype:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.subtype : ''}</strong>
            </div>
            <div className="space-0">
              <span>City:</span>
              &nbsp;
              <strong>{listingsModal && listingsModal.city ? listingsModal.city.name : ''}</strong>
            </div>
            <div className="space-0">
              <span>Area:</span>
              &nbsp;
              <strong>{listingsModal && listingsModal.area ? listingsModal.area.name : ''}</strong>
            </div>
            <div className="space-0">
              <span>Location:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.address : ''}</strong>
            </div>
            <div className="space-0">
              <span>Size:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.size : ''}</strong>
            </div>
            <div className="space-0">
              <span>Size Unit:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.size_unit : ''}</strong>
            </div>
            <div className="space-0">
              <span>Description:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.description : ''}</strong>
            </div>
            <div className="space-0">
              <span>Price:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.price : ''}</strong>
            </div>
            <div className="space-0">
              <span>Posted By:</span>
              &nbsp;
              <strong>{listingsModal && listingsModal.user ? listingsModal.user.email : ''}</strong>
            </div>
            <div className="space-0">
              <span>Phone Number:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.phone : ''}</strong>
            </div>
            <div className="space-0">
              <span>Status:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.status : ''}</strong>
            </div>
            <div className="space-0">
              <span>Comments:</span>
              &nbsp;
              <strong>{listingsModal ? listingsModal.comments : ''}</strong>
            </div>
            <div className="space-0">
              <span>Posted At:</span>
              &nbsp;
              <strong>
                {listingsModal ? moment(listingsModal.createdAt).format(dateFormat) : ''}
              </strong>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  singleApproveWanted(event, status) {
    const { userName, wantedAreasError } = this.state;
    event.preventDefault();
    if (userName) {
      if (wantedAreasError.length >= 1) {
        const { wantedPropertyId, loading } = this.state;
        if (!loading) {
          this.setState(
            {
              loading: true,
              properties: [],
              pages: 1,
              activePage: 1,
              responseMessage: loadingResponseMessage,
            },
            () => {
              axios
                .put(this.endPoint, {
                  wantedIds: wantedPropertyId,
                  status,
                  share: false,
                })
                .then((response) => {
                  if (typeof response.data === 'string') {
                    window.alert(response.data);
                    this.setState({
                      loading: false,
                    });
                  } else {
                    this.setState({});
                    window.alert('Lead moved to assigned status');
                    this.setState({
                      loadingDart: true,
                      loadingQLR: true,
                      loadingLCR: true,
                      loadingCPD: true,
                      loadingCPM: true,
                    });
                    this.fetchStats();
                    this.fetchProperties();
                  }
                });
            }
          );
        }
      } else {
        window.alert('Cannot assign lead without area');
      }
    } else {
      window.alert('Cannot assign lead without client name');
    }
  }

  matchCheck(propertyId, propertyName, propertyAreas) {
    const { history } = this.props;
    if (propertyName) {
      if (propertyAreas.length >= 1) {
        history.push(`/wanted/edit-property/match-properties/${propertyId}`);
      } else {
        window.alert('Cannot match properties without area');
      }
    } else {
      window.alert('Cannot match properties without name');
    }
  }

  updateProperty(id) {
    this.setState(
      {
        wantedProperty: {
          readyForCataloguer: true,
        },
      },
      () => {
        const { wantedProperty } = this.state;
        axios.put(`/api/property/${id}`, wantedProperty).then(() => {
          window.alert('Property assigned to cataloguer');
        });
      }
    );
  }

  showLogs(wantedId) {
    axios.get(`/api/dhoondo/aira-log/wanted/${wantedId}`).then((response) => {
      this.setState({
        modalAiraLogs: response.data,
        airaModal: true,
      });
    });
  }

  dataChanged() {
    const { isDataChanged } = this.state;
    if (!isDataChanged || window.confirm(BACK_MESSAGE)) {
      this.setState({ userModal: false, isDataChanged: false });
    } else {
      this.setState({ userModal: true });
    }
  }

  refreshPage() {
    this.setState(
      {
        responseMessage: loadingResponseMessage,
        loading: true,
        properties: [],
        activePage: 1,
        activeButton: 'new',
        activePendingButton: 'pending',
        pageSize: DEFAULT_PAGE_SIZE,
        pages: 1,
        activePurpose: 'sale',
        cmLead: false,
        startDate: null,
        endDate: null,
        q: null,
        type: null,
        newLeadsNotification: 0,
      },
      () => {
        this.setState({ alertVisible: false });
        this.fetchProperties();
      }
    );
  }

  approveWanted(event) {
    event.preventDefault();
    const { properties, loading, selectedStatus, selectRejected } = this.state;
    if (!loading) {
      let propertiesIds = properties.map((prop) => (prop.selected ? prop.id : null));
      propertiesIds = propertiesIds.filter((prop) => !!prop);

      this.setState(
        {
          loading: true,
        },
        () => {
          axios
            .put(this.endPoint, {
              wantedIds: propertiesIds,
              status: selectedStatus !== 'inprogress' ? selectedStatus : 'pending',
              reason: selectRejected,
            })
            .then(() => {
              this.setState({
                loading: false,
              });
              this.setState({
                loadingDart: true,
                loadingQLR: true,
                loadingLCR: true,
                loadingCPD: true,
                loadingCPM: true,
              });
              this.fetchStats();
              this.fetchProperties();
            });
        }
      );
    }
  }

  renderPropertyModal = (propertyId) => {
    axios.get(`/api/property/${propertyId}`).then((response) => {
      this.setState({ listingsModal: response.data.property, listingsModalToggle: true });
    });
  };

  renderClientHistory() {
    const { clientModal, loadingClient, clientHistory, activeClientPage, clientPages } = this.state;
    return (
      <Modal size="lg" isOpen={clientModal}>
        <ModalHeader
          toggle={() => {
            this.setState({ clientModal: false });
          }}
        >
          <div className="grey">Client History</div>
        </ModalHeader>
        <ModalBody>
          <div className="modalInput">
            <div className="table-responsive" style={{ borderTopLeftRadius: 10 }}>
              {loadingClient ? (
                <div className="col-sm-12 text-center space-1">
                  <i className="fa fa-spinner fa-pulse" style={{ fontSize: '30px' }} />
                </div>
              ) : null}
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Posted By</th>
                    <th>Type</th>
                    <th>City</th>
                    <th>Area</th>
                    <th>Phone</th>
                    <th>Date Added</th>
                  </tr>
                </thead>
                <tbody>
                  {clientHistory && clientHistory.length >= 1
                    ? clientHistory.map((client) => (
                        <tr key={client.id}>
                          <td>{client.id}</td>
                          <td>{client.name}</td>
                          <td>{client.subtype}</td>
                          {this.getCityCell(client)}
                          <td>
                            {client.wanted_areas
                              ? client.wanted_areas.map(
                                  (wantedAreas) => `${wantedAreas.area.name}, `
                                )
                              : 'null'}
                          </td>
                          <td>{client.phone}</td>
                          <td>{moment(client.createdAt).format(shortDateFormat)}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 col-sm-12 text-center">
              <ReactPaginate
                previousLabel="‹"
                nextLabel="›"
                forcePage={activeClientPage - 1}
                breakLabel={
                  <span
                    role="presentation"
                    className="customPage"
                    tabIndex="-1"
                    onClick={(e) => e.preventDefault()}
                  >
                    ...
                  </span>
                }
                breakClassName="break-me"
                pageCount={clientPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handleClientPageSelect}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderRefer() {
    const { referModal, isLoadingSystemUsers, systemUser, systemUsers } = this.state;
    return (
      <Modal size="md" isOpen={referModal}>
        <ModalHeader
          toggle={() => {
            this.setState({ referModal: false });
          }}
        >
          <div className="grey">Refer</div>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={this.handleRefer}>
            <div>
              <div className="mb-2">
                <Select
                  style={{ borderRadius: 5 }}
                  name="systemUser"
                  isLoading={isLoadingSystemUsers}
                  multi={false}
                  value={systemUser ? systemUser.id : null}
                  onChange={(value) => this.setSystemUser(value)}
                  options={systemUsers}
                  valueKey="id"
                  labelKey="info"
                  clearable={false}
                  required
                />
              </div>
              <div className="text-end">
                <Button className="btn btn-primary" color="primary">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }

  renderCreateUser() {
    const { userModal, newUser, userPhone, loading } = this.state;
    return (
      <Modal
        size="md"
        isOpen={userModal}
        toggle={() => {
          this.dataChanged();
          this.setState({ newUser: {}, userPhone: '+92' });
        }}
      >
        <ModalHeader
          isOpen={userModal}
          toggle={() => {
            this.dataChanged();
            this.setState({ newUser: {}, userPhone: '+92' });
          }}
        >
          <div className="gray">Sign Up</div>
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={(event) => {
              this.createUser(event);
            }}
          >
            <div>
              <input
                className="modalInput"
                type="text"
                name="first_name"
                placeholder="First name*"
                required
                onChange={this.handleUserInput}
                pattern="[a-zA-Z][a-zA-Z ]*"
                title="This field cannot start with an empty space or include a number."
                value={newUser.first_name}
              />
              <input
                className="modalInput"
                type="text"
                name="last_name"
                placeholder="Last name*"
                required
                onChange={this.handleUserInput}
                pattern="[a-zA-Z][a-zA-Z ]*"
                title="This field cannot start with an empty space or include a number."
                value={newUser.last_name}
              />
              <input
                className="modalInput"
                type="email"
                name="email"
                placeholder="Email Address*"
                value={newUser.email}
                required
                onChange={this.handleUserInput}
                autoComplete="off"
              />
              <input
                className="modalInput"
                type="password"
                name="password"
                placeholder="Password*"
                required
                onChange={this.handleUserInput}
                autoComplete="new-password"
              />
              <PhoneInput
                name="phone"
                country="pk"
                inputStyle={{ width: '100%' }}
                enableLongNumbers
                onChange={(phone) => this.handleUserPhone(phone)}
                required
                value={userPhone}
              />
              <h5 style={{ marginTop: '10px' }}>Birthday</h5>
              <div className="row space-2">
                <div className="col-5">
                  <select
                    name="month"
                    className="form-select custom-select"
                    onChange={this.handleUserInput}
                    required
                  >
                    <option value="this.state.user.month">Month</option>
                    {[
                      'January',
                      'February',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'August',
                      'September',
                      'October',
                      'November',
                      'December',
                    ].map((month, index) => (
                      <option key={month} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3 no-padding">
                  <select
                    name="day"
                    className="form-select custom-select"
                    onChange={this.handleUserInput}
                    required
                  >
                    <option value="user.day">Day</option>
                    {Wanted.getArray(31).map((val, index) => (
                      <option key={val} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-4">
                  <select
                    name="year"
                    className="form-select custom-select"
                    onChange={this.handleUserInput}
                    required
                  >
                    <option value="this.state.user.year">Year</option>
                    {Wanted.getArray(100).map((val, index) => (
                      <option key={val} value={2016 - index}>
                        {2016 - index}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <label>
                <input type="checkbox" /> I’d like to receive coupons, promotions, surveys, and
                updates via email about Graana and its partners.
              </label>
              <Button
                style={{ color: 'white', width: '100%', marginTop: '10px' }}
                className={`btn btn-block btn-xlg btn-graana-red space-1 ${
                  loading ? 'disabled' : ''
                }`}
              >
                <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} /> Sign up
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }

  renderFollowUp() {
    const { followModal, followStatus, followDate, focusedTemp, timeMaterial, followTime } =
      this.state;
    return (
      <Modal size="sm" isOpen={followModal}>
        <ModalHeader
          toggle={() => {
            this.setState({ followModal: false });
          }}
        >
          <div className="grey" style={{ textTransform: 'capitalize' }}>
            Follow Up: {followStatus ? followStatus.replace(/-/g, ' ') : ''}
          </div>
        </ModalHeader>
        <ModalBody>
          <form id="demo-form2" onSubmit={this.singlePending}>
            <div className="modalInput">
              <label style={{ marginRight: 5 }} className="control-label">
                Follow Up Date
              </label>
              <SingleDatePicker
                style={{ display: styleInlineBlock }}
                date={followDate}
                onDateChange={(date) => this.setState({ followDate: date })}
                placeholder="Select Date..."
                focused={focusedTemp}
                onFocusChange={({ focused }) => this.setState({ focusedTemp: focused })}
                id="date_input"
                numberOfMonths={1}
                required={!!followTime}
              />
            </div>
            <div className="modalInput">
              <label style={{ marginRight: 5 }} className="control-label">
                Follow Up Time
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  value={timeMaterial}
                  onChange={(val) =>
                    this.setState({ followTime: moment(val).format('HH:mm'), timeMaterial: val })
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
            <Button
              style={{ position: 'relative', left: '200px' }}
              // onClick={(e) => this.singlePending(e, followStatus)}
              className="btn btn-primary"
              color="primary"
            >
              Save
            </Button>
          </form>
        </ModalBody>
      </Modal>
    );
  }

  renderSchedule() {
    const { scheduleModal, scheduleDate, focusedTemp, timeMaterial, scheduleLoading } = this.state;
    return (
      <Modal size="sm" isOpen={scheduleModal}>
        <ModalHeader
          toggle={() => {
            this.setState({ scheduleModal: false });
          }}
        >
          <div className="grey" style={{ textTransform: 'capitalize' }}>
            Schedule Viewing
          </div>
        </ModalHeader>
        {scheduleLoading ? (
          <div className="col-sm-12 text-center space-1">
            <i className="fa fa-spinner fa-pulse" style={{ fontSize: '30px' }} />
          </div>
        ) : null}
        {!scheduleLoading ? (
          <ModalBody>
            <div className="modalInput">
              <label style={{ marginRight: 5 }} className="control-label">
                Schedule Date
              </label>
              <SingleDatePicker
                style={{ display: styleInlineBlock }}
                date={scheduleDate}
                onDateChange={(date) => this.setState({ scheduleDate: date })}
                placeholder="Select Date..."
                focused={focusedTemp}
                onFocusChange={({ focused }) => this.setState({ focusedTemp: focused })}
                id="date_input"
                numberOfMonths={1}
              />
            </div>
            <div className="modalInput">
              <label style={{ marginRight: 5 }} className="control-label">
                Schedule Time
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  value={timeMaterial}
                  onChange={(val) =>
                    this.setState({ scheduleTime: moment(val).format('HH:mm'), timeMaterial: val })
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
            <Button
              style={{ position: 'relative', left: '200px' }}
              onClick={(e) => this.scheduleViewing(e)}
              className="btn btn-primary"
              color="primary"
            >
              Save
            </Button>
          </ModalBody>
        ) : null}
      </Modal>
    );
  }

  renderAiraLogs() {
    const { airaModal, modalAiraLogs } = this.state;
    return (
      <Modal size="lg" isOpen={airaModal}>
        <ModalHeader
          toggle={() => {
            this.setState({ airaModal: false });
          }}
        >
          <div className="grey">
            {modalAiraLogs && modalAiraLogs[0]
              ? `AIRA Logs for Lead ID: ${modalAiraLogs[0].wanted_id}`
              : 'No Logs Found'}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="modalInput">
            <div className="table-responsive" style={{ borderTopLeftRadius: 10 }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>AIRA</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Date Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {modalAiraLogs && modalAiraLogs.length >= 1
                    ? modalAiraLogs.map((modalAira) => (
                        <tr key={modalAira.id}>
                          <td style={{ textTransform: 'capitalize' }}>
                            {modalAira.system_user
                              ? `${modalAira.system_user.first_name} ${modalAira.system_user.last_name}`
                              : ''}
                          </td>
                          <td>{modalAira.status}</td>
                          <td>{modalAira.reason}</td>
                          <td>
                            {modalAira.updatedAt
                              ? moment(modalAira.updatedAt).format(shortDateFormat)
                              : ''}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderCallLogs() {
    const { modalVisible, loadingModal, callLogs, callLength } = this.state;
    let firstCallDate;
    let firstCallTime;
    let lastCallDate;
    let lastCallTime;
    if (callLogs && callLogs.length >= 1) {
      firstCallDate = moment(callLogs.diaries[0].date).format(dateFormat);
      firstCallTime = moment(callLogs.diaries[0].date).format('hh:mm a');
      lastCallDate = moment(callLogs.diaries[callLength].date).format(dateFormat);
      lastCallTime = moment(callLogs.diaries[callLength].date).format('hh:mm a');
    } else {
      firstCallDate = 'N/A';
      firstCallTime = 'N/A';
      lastCallDate = 'N/A';
      lastCallTime = 'N/A';
    }
    return (
      <Modal size="md" isOpen={modalVisible}>
        <ModalHeader
          toggle={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <div className="grey">Call Logs</div>
        </ModalHeader>
        {loadingModal ? (
          <div className="col-sm-12 text-center space-1">
            <i className="fa fa-spinner fa-pulse" style={{ fontSize: '30px' }} />
          </div>
        ) : null}
        {!loadingModal ? (
          <ModalBody>
            <div>
              <label className="modalInput">First Call Date: {firstCallDate}</label>
              <label className="modalInput">First Call Time: {firstCallTime}</label>
              <label className="modalInput">Last Call Date: {lastCallDate}</label>
              <label className="modalInput">Last Call Time: {lastCallTime}</label>
            </div>
          </ModalBody>
        ) : null}
      </Modal>
    );
  }

  renderScheduleLog() {
    const { scheduleLogModal, scheduleLogDate, scheduleLogTime } = this.state;
    return (
      <Modal size="md" isOpen={scheduleLogModal}>
        <ModalHeader
          toggle={() => {
            this.setState({ scheduleLogModal: false });
          }}
        >
          <div className="grey">Viewing Schedule</div>
        </ModalHeader>
        <ModalBody>
          <div>
            <label className="modalInput">
              Viewing Date:{' '}
              {scheduleLogDate ? moment(scheduleLogDate).format(dateFormat) : 'Not Specified'}
            </label>
            <label className="modalInput">Viewing Time: {scheduleLogTime || 'Not Specified'}</label>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderCommentHistory() {
    const { modalComments, wantedComments, armsComments, message, commentId, loadingComments } =
      this.state;
    return (
      <Modal size="md" isOpen={modalComments}>
        <ModalHeader
          toggle={() =>
            this.setState({
              modalComments: false,
              wantedComments: [],
              armsComments: [],
            })
          }
        >
          <div className="grey">Comment History</div>
        </ModalHeader>
        {loadingComments ? (
          <div className="col-sm-12 text-center space-1">
            <i className="fa fa-spinner fa-pulse" style={{ fontSize: '30px' }} />
          </div>
        ) : null}
        <ModalBody>
          <div className="x_panel">
            {wantedComments && wantedComments.length < 1 ? (
              <div style={{ position: 'absolute', top: '43%', left: '26%' }}>
                <h4 style={{ color: '#D3D3D3' }}>No comments to show</h4>
              </div>
            ) : null}
            <div className="custom-scroll commentsMainWrap">
              {wantedComments
                ? wantedComments.map((commentItem, index) => (
                    <div key={`${index + 1}`} className="commentHistoryWrap text-end">
                      <p>
                        {commentItem.comment}
                        <span
                          style={{ textTransform: 'capitalize', color: '#26a59a' }}
                          className="commentdate"
                        >
                          {commentItem.system_user
                            ? `${commentItem.system_user.first_name} ${commentItem.system_user.last_name}`
                            : ''}
                        </span>
                        <span className="commentdate">
                          {moment(commentItem.createdAt).format('LLL')}
                        </span>
                      </p>
                    </div>
                  ))
                : null}
              {armsComments
                ? armsComments.map((armsComment, index) => (
                    <div key={`${index + 1}`} className="commentHistoryWrap">
                      <p>
                        {armsComment.value}
                        <span
                          style={{ textTransform: 'capitalize', color: '#26a59a' }}
                          className="commentdate"
                        >
                          {armsComment.armsuser
                            ? `${armsComment.armsuser.firstName} ${armsComment.armsuser.lastName}`
                            : ''}
                        </span>
                        <span className="commentdate">
                          {moment(armsComment.createdAt).format('LLL')}
                        </span>
                      </p>
                    </div>
                  ))
                : null}
              <div
                style={{ float: 'left', clear: 'both' }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
              />
            </div>
            <div className="commentInputMain">
              <input
                type="text"
                name="comment"
                display="Comment"
                placeholder="Press Enter to Comment"
                required
                id="inputTitle"
                ref={(el) => {
                  this.inputTitle = el;
                }}
                className="form-control"
                onChange={(event) => {
                  this.setState({ message: event.target.value });
                }}
                onKeyUp={(event) => {
                  event.preventDefault();
                  if (event.key === 'Enter' && !(message === '')) {
                    this.submitComment(commentId);
                  }
                }}
              />
              {message === '' ? null : (
                <button
                  aria-label="save"
                  type="submit"
                  className="fa fa-paper-plane-o commentBtn"
                  onClick={() => {
                    this.submitComment(commentId);
                  }}
                />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderHeader() {
    const { user } = this.props;
    const { startDate, endDate, loadingBigData } = this.state;
    return (
      <div className="col-sm-2">
        <h3>
          Wanted
          {(user && user.role === 'manager') ||
          (startDate && endDate && endDate.diff(startDate, 'days') < 32) ? (
            <span
              className="export-data"
              onClick={this.getCSV}
              id="export_data"
              role="presentation"
              tabIndex="-1"
            >
              <i className={loadingBigData && 'fa fa-spinner fa-pulse red'} />
              <i className={!loadingBigData && 'fa fa-download'} />
              <UncontrolledTooltip placement="top" target="export_data">
                export all wanted leads
              </UncontrolledTooltip>
            </span>
          ) : null}
        </h3>
      </div>
    );
  }

  renderSubzoneSearch() {
    const { isLoadingAreagroup, areagroup, areagroups } = this.state;
    return (
      <div>
        <Select
          style={{ borderRadius: 5 }}
          name="areagroup"
          isLoading={isLoadingAreagroup}
          multi={false}
          value={areagroup ? areagroup.id : null}
          onChange={(value) => this.setAreagroup(value)}
          options={areagroups}
          valueKey="id"
          labelKey="info"
          clearable={false}
          required
        />
      </div>
    );
  }

  renderZoneSearch() {
    const { zone } = this.state;
    return (
      <div>
        <Select.Async
          style={{ borderRadius: 5 }}
          name="zone"
          multi={false}
          value={zone ? zone.id : null}
          onChange={(value) => this.setZone(value)}
          loadOptions={(input) => Wanted.getZone(input)}
          valueKey="id"
          labelKey="zone_name"
          clearable={false}
          placeholder="Select Zone"
          required
        />
      </div>
    );
  }

  renderAreaSearch() {
    const { city, area, areas, isLoadingExternally } = this.state;
    return (
      <div>
        <div className="col-sm-6 no-padding" style={{ display: styleInlineBlock }}>
          <Select.Async
            style={{ borderRadius: 5 }}
            name="city"
            multi={false}
            value={city ? city.id : null}
            onChange={(value) => this.setCity(value)}
            loadOptions={(input) => Wanted.getCity(input)}
            valueKey="id"
            labelKey="name"
            clearable={false}
            placeholder="Select City"
            required
          />
        </div>
        <div className="col-sm-6 no-padding" style={{ display: styleInlineBlock }}>
          <Select
            style={{ borderRadius: 5 }}
            name="area"
            isLoading={isLoadingExternally}
            multi={false}
            value={area ? area.id : null}
            onChange={(value) => this.setArea(value)}
            options={areas}
            valueKey="id"
            labelKey="name"
            clearable={false}
            required
          />
        </div>
      </div>
    );
  }

  renderAllSearch() {
    const { q, type } = this.state;
    return (
      <div>
        <input
          className="form-control"
          type={type === 'id' ? 'number' : 'text'}
          name="search"
          placeholder="Search"
          value={q}
          onChange={(event) =>
            this.setState({
              q: event.target.value ? event.target.value : undefined,
              clearButton: false,
            })
          }
          onKeyPress={(event) => {
            if (event.key === 'Enter' && type && q) {
              this.handleSearch(event);
            }
          }}
        />
      </div>
    );
  }

  renderSearchFields() {
    const { type } = this.state;
    return (
      <div>
        {type === 'subzone' ? this.renderSubzoneSearch() : null}
        {type === 'zone' ? this.renderZoneSearch() : null}
        {type === 'area' ? this.renderAreaSearch() : null}
        {type === 'id' ||
        type === 'armsuser' ||
        type === 'aira_email' ||
        type === 'city' ||
        type === 'phone' ||
        !type
          ? this.renderAllSearch()
          : null}
      </div>
    );
  }

  renderSearch() {
    const { type, clearButton, q } = this.state;
    const searchTypes = this.getSearchTypes();
    return (
      <SearchBar
        type={type}
        searchTypes={searchTypes}
        q={q}
        clearButton={clearButton}
        handleSearchType={(value) => this.handleSearchType(value)}
        renderSearch={() => this.renderSearchFields()}
        handleSearch={(e) => this.handleSearch(e)}
        clearSearch={() => this.clearSearch()}
      />
    );
  }

  renderStats() {
    const {
      dartFormatted,
      qualifiedLeadRatio,
      totalClosedRatio,
      loadingDart,
      loadingLCR,
      loadingQLR,
      loadingCPD,
      loadingCPM,
      cpm,
      cpd,
    } = this.state;
    return (
      <div className="col-sm-4 text-start">
        <button
          id="cpm"
          style={{
            marginRight: 2,
            cursor: 'default',
            width: '95px',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingCPM ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              cpm
            )}
          </p>
          Calls/mo
          <UncontrolledTooltip placement="bottom" target="cpm">
            Calls per month
          </UncontrolledTooltip>
        </button>
        <button
          id="cpd"
          style={{
            marginRight: 2,
            marginLeft: 2,
            cursor: 'default',
            width: '95px',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingCPD ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              cpd
            )}
          </p>
          Calls/d
          <UncontrolledTooltip placement="bottom" target="cpd">
            Calls per day
          </UncontrolledTooltip>
        </button>
        <button
          id="dart_stats"
          style={{
            marginRight: 2,
            marginTop: 2,
            marginLeft: 2,
            cursor: 'default',
            width: '95px',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingDart ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              dartFormatted
            )}
          </p>
          DART
          <UncontrolledTooltip placement="bottom" target="dart_stats">
            Daily Average Response Time of logged in AIRA (Time landed vs. Time Responded)
          </UncontrolledTooltip>
        </button>
        <button
          id="qlr"
          style={{
            marginRight: 2,
            marginLeft: 2,
            marginTop: 2,
            cursor: 'default',
            width: '95px',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingQLR ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              `${qualifiedLeadRatio} %`
            )}
          </p>
          QLR
          <UncontrolledTooltip placement="bottom" target="qlr">
            Qualified Lead Ratio of logged in AIRA (New Leads vs. Leads Assigned)
          </UncontrolledTooltip>
        </button>
        <button
          id="lcr"
          style={{
            marginRight: 2,
            marginLeft: 2,
            marginTop: 2,
            cursor: 'default',
            width: '95px',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingLCR ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              `${totalClosedRatio} %`
            )}
          </p>
          LCR
          <UncontrolledTooltip placement="bottom" target="lcr">
            Lead Conversion Ratio of logged in AIRA (New Leads vs. Closed Won)
          </UncontrolledTooltip>
        </button>
      </div>
    );
  }

  renderShowFilter() {
    const { showFilters } = this.state;
    return (
      <div className="col-sm-4 text-end">
        <Link to="/wanted/addLead" className="btn btn-success ms-10">
          Add Leads
        </Link>
        <button
          type="button"
          className={`btn btn-default ms-2 ${showFilters ? btnPrimary : ''}`}
          onClick={() => this.setState((prevState) => ({ showFilters: !prevState.showFilters }))}
        >
          Filters
          <span className={`ms-2 ${showFilters ? 'fa fa-caret-up' : 'fa fa-caret-down'}`} />
        </button>
      </div>
    );
  }

  renderDateFilter() {
    const {
      activeButton,
      activePurpose,
      colSearch,
      followupDate,
      startDate,
      endDate,
      focusedInput,
      dateSearch,
    } = this.state;
    return (
      <div className="col-sm-12 text-end">
        {(activeButton === 'approved' || activeButton === 'inprogress') &&
        activePurpose !== 'invest' ? (
          <select
            className="form-select custom-select"
            style={{
              width: '20%',
              display: 'inline',
            }}
            value={colSearch}
            onChange={(event) => this.setDate(event)}
          >
            <option value="">Added Date</option>
            {activeButton === 'approved' && <option value="status">Assigned Date</option>}
            {activeButton === 'inprogress' && activePurpose !== 'invest' && (
              <option value="followup">Follow Up Date</option>
            )}
          </select>
        ) : null}
        {colSearch === 'followup' && activePurpose !== 'invest' ? (
          <select
            className="form-select custom-select"
            style={{ width: '14%' }}
            onChange={(event) => this.setFollowupdate(event)}
          >
            <option value="today">Today</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="custom">Custom</option>
          </select>
        ) : null}
        {(colSearch === 'followup' && followupDate === 'custom') || colSearch !== 'followup' ? (
          <div style={{ display: styleInlineBlock }}>
            &nbsp;<strong> From: </strong> &nbsp;
            <DateRangePicker
              isOutsideRange={
                colSearch === 'followup'
                  ? () => false
                  : (day) => !isInclusivelyBeforeDay(day, moment())
              }
              startDate={startDate}
              endDate={endDate}
              displayFormat={() => 'DD/MM/YYYY'}
              startDateId="date_input_start"
              endDateId="date_input_end"
              onDatesChange={({ startDate: dateStart, endDate: dateEnd }) =>
                this.onDatesChange(dateStart, dateEnd)
              }
              focusedInput={focusedInput}
              onFocusChange={(input) => this.setState({ focusedInput: input })}
              showClearDates
              minimumNights={0}
              style={{ borderRadius: 5 }}
            />
          </div>
        ) : null}
        {!dateSearch && (
          <button
            name="dateSearch"
            type="button"
            className="btn btn-primary"
            onClick={(e) => this.handleSearch(e)}
          >
            Apply
          </button>
        )}
        {dateSearch && (
          <button type="button" className="btn btn-success" onClick={() => this.clearDates()}>
            Clear
          </button>
        )}
      </div>
    );
  }

  renderNewFilter() {
    const { activeButton } = this.state;
    return (
      <button
        type="button"
        style={{
          marginRight: 5,
          marginBottom: 0,
        }}
        className={`${activeButton === 'new' ? btnPrimary : ''} btn btn-default`}
        onClick={() => this.handleFilterWanted('new')}
      >
        New
      </button>
    );
  }

  renderFollowUpFilter() {
    const { activeButton, activeReason } = this.state;
    return (
      <UncontrolledButtonDropdown
        style={{
          marginRight: 5,
          marginBottom: 0,
          borderRadius: 5,
        }}
      >
        <Button
          id="caret"
          style={{ textTransform: 'capitalize' }}
          color={`${activeButton === 'inprogress' ? 'primary' : ''} default`}
          onClick={() => this.handleFilterWanted('inprogress')}
        >
          {`${
            activeReason === 'call-back' ||
            activeReason === cifCompleted ||
            activeReason === notAnswering ||
            activeReason === poweredOff ||
            activeReason === 'overseas' ||
            activeReason === 'test-lead' ||
            activeReason === 'interested-in-selling' ||
            activeReason === 'interested-to-rentout' ||
            activeReason === 'other' ||
            activeReason === 'rwr_reopened'
              ? this.getFollowUpFilterText(activeReason)
              : 'Follow Ups'
          }`}
        </Button>
        <DropdownToggle caret color={`${activeButton === 'inprogress' ? 'primary' : ''} default`} />
        <DropdownMenu>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason('call-back', 'inprogress')}
            color={`${activeReason === 'call-back' ? 'primary' : ''} default`}
          >
            Call back
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason(cifCompleted, 'inprogress')}
            color={`${activeReason === cifCompleted ? 'primary' : ''} default`}
          >
            CIF completed
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason(notAnswering, 'inprogress')}
            color={`${activeReason === notAnswering ? 'primary' : ''} default`}
          >
            Not answering
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason(poweredOff, 'inprogress')}
            color={`${activeReason === poweredOff ? 'primary' : ''} default`}
          >
            Powered off
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason('overseas', 'inprogress')}
            color={`${activeReason === 'overseas' ? 'primary' : ''} default`}
          >
            Overseas
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason('test-lead', 'inprogress')}
            color={`${activeReason === 'test-lead' ? 'primary' : ''} default`}
          >
            Test Lead
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason('rwr_reopened', 'inprogress')}
            color={`${activeReason === 'rwr_reopened' ? 'primary' : ''} default`}
          >
            Reopen
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason('other', 'inprogress')}
            color={`${activeReason === 'other' ? 'primary' : ''} default`}
          >
            Other
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }

  renderAssignedFilter() {
    const { activeButton, activeShare, activePurpose } = this.state;
    return (
      <UncontrolledButtonDropdown
        style={{
          marginRight: 5,
          marginBottom: 0,
        }}
      >
        <Button
          id="caret"
          style={{ textTransform: 'capitalize' }}
          color={`${activeButton === 'approved' ? 'primary' : ''} default`}
          onClick={() => this.handleFilterWanted('approved')}
        >
          {`${!activeShare ? 'Assigned' : activeShare}`}
        </Button>
        {activePurpose !== 'sell' && activePurpose !== 'rentout' && activePurpose !== 'rent out' ? (
          <div>
            <DropdownToggle
              caret
              color={`${activeButton === 'approved' ? 'primary' : ''} default`}
            />
            <DropdownMenu>
              <DropdownItem
                style={{ border: 'none' }}
                onClick={() => this.handleShared('shared', 'approved')}
                color={`${activeShare === true ? 'primary' : ''} default`}
              >
                Shared
              </DropdownItem>
              <DropdownItem
                style={{ border: 'none' }}
                onClick={() => this.handleShared('not-shared', 'approved')}
                color={`${activeShare === false ? 'primary' : ''} default`}
              >
                Not Shared
              </DropdownItem>
            </DropdownMenu>
          </div>
        ) : null}
      </UncontrolledButtonDropdown>
    );
  }

  renderRejectedFilter() {
    const { activeButton, activeReason } = this.state;
    return (
      <UncontrolledButtonDropdown
        style={{
          marginRight: 5,
          marginBottom: 0,
        }}
      >
        <Button
          id="caret"
          style={{ textTransform: 'capitalize' }}
          color={`${activeButton === 'rejected' ? 'primary' : ''} default`}
          onClick={() => this.handleFilterWanted('rejected')}
        >
          {`${
            activeReason === irrelevantQuery ||
            activeReason === invalidNo ||
            activeReason === 'duplicate' ||
            activeReason === 'refer'
              ? activeReason.replace(/-/g, ' ')
              : 'Rejected'
          }`}
        </Button>
        <DropdownToggle caret color={`${activeButton === 'rejected' ? 'primary' : ''} default`} />
        <DropdownMenu>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason(irrelevantQuery, 'rejected')}
            color={`${activeReason === irrelevantQuery ? 'primary' : ''} default`}
          >
            Irrelevant Query
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason(invalidNo, 'rejected')}
            color={`${activeReason === invalidNo ? 'primary' : ''} default`}
          >
            Invalid No.
          </DropdownItem>
          <DropdownItem
            style={{ border: 'none' }}
            onClick={() => this.handleReason('duplicate', 'rejected')}
            color={`${activeReason === 'duplicate' ? 'primary' : ''} default`}
          >
            Duplicate
          </DropdownItem>
          <DropdownItem
            onClick={() => this.handleReason('refer', 'rejected')}
            style={{ backgroundColor: '#a4b7c1' }}
          >
            Refer
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }

  renderFilter() {
    const { activePurpose } = this.state;
    return (
      <div className="col-sm-12 text-end" style={{ marginTop: '10px', paddingRight: 10 }}>
        {activePurpose !== 'invest' ? this.renderNewFilter() : null}
        {activePurpose !== 'invest' && this.renderFollowUpFilter()}
        {activePurpose !== 'invest' && this.renderAssignedFilter()}
        {activePurpose !== 'invest' && this.renderRejectedFilter()}
      </div>
    );
  }

  renderPurposeNav() {
    const { activeButton, activePurpose } = this.state;
    return (
      <Nav tabs style={{ borderBottom: 'none' }}>
        {activeButton !== 'outdated' ? (
          <NavItem>
            <NavLink
              className={`${activePurpose === 'sale' ? 'active' : ''}`}
              onClick={() => {
                this.changePurpose('sale');
              }}
              style={{ color: '#20a8d8', fontWeight: '600' }}
            >
              Buy
            </NavLink>
          </NavItem>
        ) : null}
        <NavItem>
          <NavLink
            className={`${activePurpose === 'rent' ? 'active' : ''}`}
            onClick={() => {
              this.changePurpose('rent');
            }}
            style={{ color: '#20a8d8', fontWeight: '600' }}
          >
            Rent
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activePurpose === 'sell' ? 'active' : ''}`}
            onClick={() => {
              this.changePurpose('sell');
            }}
            style={{ color: '#28a745', fontWeight: '600' }}
          >
            Sell
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activePurpose === 'rentout' ? 'active' : ''}`}
            onClick={() => {
              this.changePurpose('rentout');
            }}
            style={{ color: '#28a745', fontWeight: '600' }}
          >
            Rentout
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activePurpose === 'invest' ? 'active' : ''}`}
            onClick={() => {
              this.changePurpose('invest');
            }}
            style={{ color: '#ed9e3d', fontWeight: '600' }}
          >
            Invest
          </NavLink>
        </NavItem>
      </Nav>
    );
  }

  renderFiltersNav() {
    const { activeButton, activePurpose, activeOverdue, activeReason, myWanted, activeBlacklist } =
      this.state;
    return (
      <div className="col-sm-12 text-end" style={{ paddingRight: 0, marginTop: '10px' }}>
        {activePurpose !== 'invest' && (
          <UncontrolledButtonDropdown style={{ marginRight: 5, marginBottom: 0, borderRadius: 5 }}>
            <Button
              id="caret"
              style={{ textTransform: 'capitalize' }}
              color={`${activeBlacklist && 'primary'} default`}
              onClick={() => this.handleBlacklistFilter('all')}
            >
              {`${
                activeBlacklist === blacklistLeads || activeBlacklist === cleanLeads
                  ? activeBlacklist.replace(/-/g, ' ')
                  : 'All'
              }`}
            </Button>
            <DropdownToggle caret color={`${activeBlacklist && 'primary'} default`} />
            <DropdownMenu>
              <DropdownItem
                style={{ border: 'none' }}
                onClick={() => this.handleBlacklistFilter(blacklistLeads)}
                color={`${activeBlacklist === blacklistLeads && 'primary'} default`}
              >
                Blacklisted Leads
              </DropdownItem>
              <DropdownItem
                style={{ border: 'none' }}
                onClick={() => this.handleBlacklistFilter(cleanLeads)}
                color={`${activeBlacklist === cleanLeads && 'primary'} default`}
              >
                Clean Leads
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )}
        {activePurpose !== 'invest' &&
          activeButton !== 'new' &&
          activeButton !== 'approved' &&
          activeButton !== 'rejected' && (
            <UncontrolledButtonDropdown
              style={{
                marginRight: 5,
                marginBottom: 0,
                borderRadius: 5,
              }}
            >
              <Button
                id="caret"
                style={{ textTransform: 'capitalize' }}
                color={`${activeOverdue && 'primary'} default`}
                onClick={() => this.handleOverdue('all')}
              >
                {`${
                  activeOverdue === 'overdue' || activeOverdue === followupDone
                    ? activeOverdue.replace(/-/g, ' ')
                    : 'All Follow Ups'
                }`}
              </Button>
              <DropdownToggle caret color={`${activeOverdue && 'primary'} default`} />
              <DropdownMenu>
                {activeButton !== 'invest' && activeButton === 'inprogress' && (
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={() => this.handleOverdue('overdue')}
                    color={`${activeOverdue === 'overdue' && 'primary'} default`}
                  >
                    Overdue
                  </DropdownItem>
                )}
                <DropdownItem
                  style={{ border: 'none' }}
                  onClick={() => this.handleOverdue(followupDone)}
                  color={`${activeReason === followupDone && 'primary'} default`}
                >
                  Follow Up Done
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          )}
        <button
          type="button"
          style={{
            marginBottom: 0,
            marginRight: '15px',
          }}
          className={`${myWanted ? btnPrimary : ''} btn btn-default`}
          onClick={() => this.handleMyWanted(!myWanted)}
        >
          My Leads
        </button>
      </div>
    );
  }

  renderRefreshAlert() {
    const { alertVisible, newLeadsNotification, activeButton } = this.state;
    if (activeButton === 'new') {
      return (
        <div className="row justify-content-between sticky-alert">
          <div className="col-6 offset-3 text-center">
            <Alert
              color="warning"
              isOpen={alertVisible}
              toggle={() => this.setState({ alertVisible: false })}
            >
              {`You have ${newLeadsNotification} new leads. Click `}
              <button
                type="button"
                className="button-alert alert-link"
                onClick={() => this.refreshPage()}
              >
                here
              </button>{' '}
              to refresh.
            </Alert>
          </div>
        </div>
      );
    }
    return null;
  }

  renderNavBar() {
    const { pageSize, activePage, total } = this.state;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
        {this.renderPurposeNav()}
        {total ? (
          <div style={{ marginBottom: 5, alignSelf: 'flex-end' }}>
            Showing {(activePage - 1) * pageSize + 1} -{' '}
            {activePage * pageSize >= total ? total : activePage * pageSize} / {total}
          </div>
        ) : null}
      </div>
    );
  }

  renderTableHeadFirst() {
    const { activePurpose, activeButton } = this.state;
    const lateIcon = activeButton === 'new' && activePurpose !== 'invest' ? <th> </th> : null;
    const id = activePurpose !== 'invest' ? <th>ID</th> : null;
    const postedBy = activePurpose !== 'invest' ? <th>Posted By</th> : null;
    const postedByInvest = activePurpose === 'invest' ? <th>Posted By</th> : null;
    const type = activePurpose !== 'invest' ? <th>Type</th> : null;
    const country = activePurpose !== 'invest' ? <th>Country</th> : null;
    const city = activePurpose !== 'invest' ? <th>City</th> : null;
    const cityInvest = activePurpose === 'invest' ? <th>City</th> : null;
    const area = activePurpose !== 'invest' ? <th>Area</th> : null;
    const phone = activePurpose !== 'invest' ? <th>Phone</th> : null;
    const listingId =
      activePurpose !== 'invest' &&
      (activePurpose === 'sell' || activePurpose === 'rentout' || activePurpose === 'rent out') ? (
        <th>Property ID</th>
      ) : null;
    const firstAira = activePurpose !== 'invest' ? <th>First Response</th> : null;
    const assignedAira = activePurpose !== 'invest' ? <th>Assigned AIRA</th> : null;
    const assignedBy =
      activeButton === 'approved' && activePurpose !== 'invest' ? <th>Assigned By</th> : null;
    const statusInvest = activePurpose === 'invest' ? <th>Status</th> : null;
    const armsId =
      activeButton === 'approved' && activePurpose !== 'invest' ? <th>ARMS Id</th> : null;
    return [
      lateIcon,
      id,
      postedBy,
      postedByInvest,
      type,
      country,
      city,
      cityInvest,
      area,
      phone,
      listingId,
      firstAira,
      assignedAira,
      assignedBy,
      statusInvest,
      armsId,
    ];
  }

  renderTableHeadSecond() {
    const { activeButton, activePurpose } = this.state;
    const assignedArmsUser =
      activeButton === 'approved' && activePurpose !== 'invest' ? (
        <th>Assigned ARMS User</th>
      ) : null;
    const assignedUserNumber =
      activeButton === 'approved' && activePurpose !== 'invest' ? (
        <th>Assigned User Number</th>
      ) : null;
    const status =
      activeButton === 'approved' && activePurpose !== 'invest' ? <th>Status</th> : null;
    const rejectedBy =
      activeButton === 'rejected' && activePurpose !== 'invest' ? <th>Rejected By</th> : null;
    const reason =
      (activeButton === 'rejected' || activeButton === 'inprogress') &&
      activePurpose !== 'invest' ? (
        <th>Reason</th>
      ) : null;
    const phone = activePurpose !== 'invest' ? null : <th>Phone</th>;
    const firstAiraInvest = activePurpose === 'invest' ? <th>First Response</th> : null;
    const dateAddedInvest = activePurpose === 'invest' ? <th>Date added</th> : null;
    const assignedTo = (
      <CanEdit>
        {activeButton === 'assigned' && activePurpose !== 'invest' ? <th>Assigned To</th> : null}
      </CanEdit>
    );
    const dateAdded = activePurpose !== 'invest' ? <th>Date added</th> : null;
    const followupDate =
      activeButton === 'inprogress' && activePurpose !== 'invest' ? <th>Follow Up Date</th> : null;
    return [
      assignedArmsUser,
      assignedUserNumber,
      status,
      rejectedBy,
      reason,
      phone,
      firstAiraInvest,
      dateAddedInvest,
      assignedTo,
      dateAdded,
      followupDate,
    ];
  }

  renderTableHeadThird() {
    const { activeButton, activePurpose } = this.state;
    const dateAssignedInvest =
      activePurpose === 'invest' || activeButton === 'approved' ? <th>Date Assigned</th> : null;
    const assignedAt =
      activeButton === 'assigned' && activePurpose !== 'invest' ? <th>Assigned at</th> : null;
    const closedAt =
      activeButton === 'closed' && activePurpose !== 'invest' ? <th>Closed at</th> : null;
    const rejectedDate =
      activeButton === 'rejected' && activePurpose !== 'invest' ? <th>Rejected Date</th> : null;
    const tsa = <th>Agree to Terms of Service</th>;
    const actions = (
      <div>
        <th style={{ paddingTop: '14px' }} className="text-center">
          Actions
        </th>
      </div>
    );
    return [dateAssignedInvest, assignedAt, closedAt, rejectedDate, tsa, actions];
  }

  renderTableHead() {
    const { properties } = this.state;
    return (
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              checked={
                properties.length &&
                properties.filter((prop) => prop.selected).length === properties.length
              }
              onChange={(e) => this.selectAllRecords(e)}
            />
          </th>
          {this.renderTableHeadFirst()}
          {this.renderTableHeadSecond()}
          {this.renderTableHeadThird()}
        </tr>
      </thead>
    );
  }

  renderTableBodyFirst(property, newProperty, index) {
    const { activePurpose, activeButton } = this.state;
    const startTimeWarning = moment(property.createdAt).add(30, 'minutes');
    const endTimeWarning = moment(property.createdAt).add(60, 'minutes');
    const timeNow = moment();
    const checkbox = (
      <td>
        <input
          type="checkbox"
          name="selected_properties"
          checked={property.selected ? property.selected : false}
          onChange={(e) => this.selectRecord(e, property)}
        />
      </td>
    );
    const lateIcon =
      activeButton === 'new' && activePurpose !== 'invest' ? (
        <td className="custom">
          {timeNow.isAfter(startTimeWarning) && timeNow.isBefore(endTimeWarning) && (
            <div className="media_icon logo-wrapper" id={`warning${index}`}>
              <svg className="verified" viewBox="0 0 12 12">
                <use xlinkHref="/img/info_material_icon.svg#Path_4" />
              </svg>
              <UncontrolledTooltip placement="bottom" target={`warning${index}`}>
                Lead overdue since 30 mins or more
              </UncontrolledTooltip>
            </div>
          )}
          {timeNow.isAfter(endTimeWarning) && (
            <div className="media_icon logo-wrapper" id={`error_${index}`}>
              <svg className="verified" viewBox="0 0 15 15">
                <use xlinkHref="/img/problem_material_icon.svg#Path_6" />
              </svg>
              <UncontrolledTooltip placement="bottom" target={`error_${index}`}>
                Lead overdue since 1 hr or more
              </UncontrolledTooltip>
            </div>
          )}
        </td>
      ) : null;

    const id =
      activePurpose !== 'invest' ? (
        <td className="text-center">
          {property.voiceLeadId ? (
            <span className="badge rounded-pill bg-danger">Voice Lead</span>
          ) : null}
          {newProperty.id}
        </td>
      ) : null;
    const name = <td>{newProperty.name}</td>;
    const subtype = activePurpose !== 'invest' ? <td>{newProperty.subtype}</td> : null;
    const country =
      activePurpose !== 'invest' ? (
        <td>
          {newProperty.country ? newProperty.country.replace(/\b\w/g, (l) => l.toUpperCase()) : ''}
        </td>
      ) : null;
    const city = activePurpose !== 'invest' ? this.getCityCell(newProperty) : null;
    const cityInvest = activePurpose === 'invest' ? this.getCityCell(newProperty) : null;
    const statusInvest =
      activePurpose === 'invest' ? (
        <td>
          {' '}
          {newProperty.armsProjectLeads
            ? newProperty.armsProjectLeads.map((cmLead) => cmLead.status)
            : null}
        </td>
      ) : null;
    return [checkbox, lateIcon, id, name, subtype, country, city, cityInvest, statusInvest];
  }

  renderTableBodySecond(newProperty, propertyPhone) {
    const { activePurpose } = this.state;
    const area =
      activePurpose !== 'invest' ? (
        <td>
          {newProperty.wanted_areas
            ? newProperty.wanted_areas.map((wantedAreas) => `${wantedAreas.area.name}, `)
            : 'null'}
        </td>
      ) : null;
    const phone =
      activePurpose !== 'invest' ? (
        <td>
          {newProperty.customer && newProperty.customer.blacklisted && (
            <span className="badge rounded-pill bg-dark">Blacklisted</span>
          )}
          {propertyPhone}
        </td>
      ) : null;
    const listingId = this.getListingId(newProperty);
    const firstAira =
      activePurpose !== 'invest' ? (
        <td>{newProperty.firstAira ? newProperty.firstAira.email : ''}</td>
      ) : null;
    const email = newProperty.zone && newProperty.zone.email && activePurpose !== 'invest' && (
      <td>
        {newProperty.substatus === 'rwr_reopen'
          ? newProperty.rwrUserId && newProperty.rwrUserId.email
          : newProperty.zone.email}
      </td>
    );
    const emailCity =
      // eslint-disable-next-line no-nested-ternary
      ((newProperty.zone && !newProperty.zone.email) || !newProperty.zone) &&
      activePurpose !== 'invest' ? (
        newProperty.substatus === 'rwr_reopen' ? (
          <td>
            {newProperty.rwrUserId && newProperty.rwrUserId.email
              ? newProperty.rwrUserId.email
              : ''}
          </td>
        ) : (
          <td>
            {newProperty.city && newProperty.city.default_zone_email
              ? newProperty.city.default_zone_email
              : ''}
          </td>
        )
      ) : null;
    return [area, phone, listingId, firstAira, email, emailCity];
  }

  renderTableBodyThird(newProperty, propertyPhone) {
    const { activeButton, activePurpose } = this.state;
    const assignedBy =
      activeButton === 'approved' && activePurpose !== 'invest' ? (
        <td>{newProperty.leadAssignedBy ? newProperty.leadAssignedBy.email : ''}</td>
      ) : null;
    const armLeadId =
      activeButton === 'approved' && activePurpose !== 'invest' ? (
        <td>{newProperty.armsLeads ? newProperty.armsLeads.map((armLead) => armLead.id) : ''}</td>
      ) : null;
    const armsUser =
      activeButton === 'approved' && activePurpose !== 'invest' ? (
        <td>
          {newProperty.assigned_to_armsuser_id && newProperty.armsuser
            ? `${newProperty.armsuser.firstName} ${newProperty.armsuser.lastName}`
            : null}
        </td>
      ) : null;
    const armsUserNumber =
      activeButton === 'approved' && activePurpose !== 'invest' ? (
        <td>
          {newProperty.assigned_to_armsuser_id && newProperty.armsuser
            ? newProperty.armsuser.phoneNumber
            : null}
        </td>
      ) : null;
    const armsStatus =
      activeButton === 'approved' && activePurpose !== 'invest' ? (
        <td>
          {newProperty.armsLeads ? newProperty.armsLeads.map((armsLead) => armsLead.status) : null}
        </td>
      ) : null;
    const rejectedBy =
      activeButton === 'rejected' && activePurpose !== 'invest' ? (
        <td>{newProperty.leadRejectedBy ? newProperty.leadRejectedBy.email : ''}</td>
      ) : null;
    const reason =
      (activeButton === 'rejected' || activeButton === 'inprogress') &&
      activePurpose !== 'invest' ? (
        <td>{newProperty.reason}</td>
      ) : null;
    const phoneInvest =
      activePurpose !== 'invest' ? null : (
        <td>
          {newProperty.customer && newProperty.customer.blacklisted && (
            <span className="badge rounded-pill bg-dark">Blacklisted</span>
          )}
          {propertyPhone}
        </td>
      );
    const firstAiraInvest =
      activePurpose === 'invest' ? (
        <td>{newProperty.firstAira ? newProperty.firstAira.email : ''}</td>
      ) : null;
    const date =
      activePurpose === 'invest' ? (
        <td>{moment(newProperty.createdAt).format(shortDateFormat)}</td>
      ) : null;
    return [
      assignedBy,
      armLeadId,
      armsUser,
      armsUserNumber,
      armsStatus,
      rejectedBy,
      reason,
      phoneInvest,
      firstAiraInvest,
      date,
    ];
  }

  renderTableBodyFourth(newProperty, index) {
    const { activeButton, activePurpose } = this.state;
    const agencyName =
      activeButton === 'assigned' && activePurpose !== 'invest' ? (
        <td> {newProperty.agency ? newProperty.agency.name : null}</td>
      ) : null;
    const createdAt =
      activePurpose !== 'invest' ? (
        <td style={{ whiteSpace: 'nowrap' }}>
          {moment(newProperty.createdAt).format(shortDateFormat)}
        </td>
      ) : null;
    const followupDate =
      activeButton === 'inprogress' && activePurpose !== 'invest' ? (
        <td style={{ whiteSpace: 'nowrap' }}>{`${
          newProperty.followup_date ? moment(newProperty.followup_date).format('MMM DD, ') : ''
        } ${newProperty.followup_time ? newProperty.followup_time : ''} `}</td>
      ) : null;
    const createdAtInvest =
      activePurpose === 'invest' ? (
        <td>{newProperty.createdAt && moment(newProperty.createdAt).format(shortDateFormat)}</td>
      ) : null;
    const statusDate =
      activePurpose !== 'invest' && activeButton === 'approved' ? (
        <td>
          {newProperty.status_date && moment(newProperty.status_date).format(shortDateFormat)}
        </td>
      ) : null;
    const assignedAt =
      activeButton === 'assigned' && activePurpose !== 'invest' ? (
        <td>{moment(newProperty.assigned_at).format(shortDateFormat)}</td>
      ) : null;
    const updatedAt =
      activeButton === 'closed' && activePurpose !== 'invest' ? (
        <td>{moment(newProperty.updatedAt).format(shortDateFormat)}</td>
      ) : null;
    const updatedAtRejected =
      activeButton === 'rejected' && activePurpose !== 'invest' ? (
        <td>{moment(newProperty.updatedAt).format(shortDateFormat)}</td>
      ) : null;
    const tsa = newProperty.terms_signed ? (
      <td style={{ textAlign: 'center' }}>
        <div className="media_icon logo-wrapper">
          <svg className="verified" viewBox="0 0 630 630" id={`title${index}`}>
            <use xlinkHref="/img/verified_checkmark.svg#Capa_1" />
          </svg>
        </div>
      </td>
    ) : (
      <td />
    );

    return [
      agencyName,
      createdAt,
      followupDate,
      createdAtInvest,
      statusDate,
      assignedAt,
      updatedAt,
      updatedAtRejected,
      tsa,
    ];
  }

  renderTableBodyActions(newProperty, property) {
    const { subFilter, activeButton, activePurpose } = this.state;
    return (
      <CanEdit>
        <td colSpan="10" className="cus-z-index" style={{ padding: 0 }}>
          <div
            className={`${
              activeButton === 'approved' || activePurpose === 'invest'
                ? 'wanted-assigned-filter'
                : 'wanted-filter'
            }`}
          >
            {this.pageListingType === wantedListing ? (
              <CanView>
                <UncontrolledDropdown
                  style={{
                    marginLeft: 5,
                    marginBottom: 0,
                    display: styleInlineBlock,
                  }}
                  className="action-cus"
                >
                  <DropdownToggle caret color="primary">
                    Action
                  </DropdownToggle>
                  <DropdownMenu>
                    {(activeButton !== 'approved' || activePurpose === 'invest') && (
                      <Link
                        to={{
                          pathname: `${
                            newProperty.cmLead
                              ? `/wanted/edit-invest/${newProperty.id}`
                              : `/wanted/edit-property/${newProperty.id}`
                          }`,
                        }}
                        style={{ textDecoration: 'none' }}
                      >
                        <DropdownItem style={{ border: 'none' }}>
                          Edit Client Info Form
                        </DropdownItem>
                      </Link>
                    )}
                    <DropdownItem
                      onClick={() =>
                        this.blacklistClient(
                          newProperty.customer.id,
                          newProperty.customer.blacklisted,
                          newProperty.id
                        )
                      }
                      style={{ border: 'none' }}
                    >
                      {newProperty.customer && !newProperty.customer.blacklisted
                        ? 'Add to blacklist'
                        : 'Remove from blacklist'}
                    </DropdownItem>
                    {(activeButton === 'approved' ||
                      activeButton === 'inprogress' ||
                      activeButton === 'rejected') &&
                    activePurpose !== 'invest' ? (
                      <DropdownItem
                        style={{ border: 'none' }}
                        onClick={() => this.showLogs(newProperty.id)}
                      >
                        View Logs
                      </DropdownItem>
                    ) : null}
                    {activePurpose !== 'invest' && newProperty.customer_id && (
                      <DropdownItem
                        style={{ border: 'none' }}
                        onClick={() => this.viewClientHistory(newProperty.customer_id)}
                      >
                        Client History
                      </DropdownItem>
                    )}
                    {activeButton === 'approved' &&
                    activePurpose !== 'invest' &&
                    activePurpose !== 'sell' &&
                    activePurpose !== 'rentout' ? (
                      <DropdownItem
                        style={{ border: 'none' }}
                        onClick={() => this.showDetails(newProperty.id)}
                      >
                        Call Logs
                      </DropdownItem>
                    ) : null}
                    {!property.user_id && activePurpose !== 'invest' ? (
                      <DropdownItem
                        style={{ border: 'none' }}
                        onClick={() =>
                          this.setState({
                            userModal: true,
                            leadIdUser: newProperty.id,
                            leadUser: newProperty,
                          })
                        }
                      >
                        Create User
                      </DropdownItem>
                    ) : null}
                    {activeButton === 'inprogress' &&
                    activePurpose !== 'invest' &&
                    activePurpose !== 'sell' &&
                    activePurpose !== 'rentout' &&
                    activePurpose !== 'rent out' ? (
                      <DropdownItem
                        style={{ border: 'none' }}
                        onClick={() =>
                          this.matchCheck(property.id, property.name, property.wanted_areas)
                        }
                      >
                        Match Properties
                      </DropdownItem>
                    ) : null}
                    {activeButton === 'inprogress' && activePurpose !== 'invest' ? (
                      <DropdownItem
                        style={{ border: 'none' }}
                        onClick={() =>
                          this.scheduleModal(
                            newProperty.id,
                            newProperty.agent_schedule_date,
                            newProperty.agent_schedule_time
                          )
                        }
                      >
                        Schedule Viewing
                      </DropdownItem>
                    ) : null}
                    {activeButton === 'approved' && activePurpose !== 'invest' ? (
                      <DropdownItem
                        onClick={() =>
                          this.setState({
                            scheduleLogModal: true,
                            scheduleLogDate: newProperty.agent_schedule_date,
                            scheduleLogTime: newProperty.agent_schedule_time,
                          })
                        }
                        style={{ border: 'none' }}
                      >
                        View Schedule
                      </DropdownItem>
                    ) : null}
                    {activeButton === 'approved' &&
                    newProperty.wanted_properties &&
                    newProperty.wanted_properties.length >= 1 ? (
                      <Link
                        to={{
                          pathname: `/wanted/saved-properties/${newProperty.id}`,
                        }}
                        style={{ textDecoration: 'none' }}
                      >
                        <DropdownItem style={{ border: 'none' }}>Shared Properties</DropdownItem>
                      </Link>
                    ) : null}
                    {activeButton === 'inprogress' &&
                    (newProperty.purpose === 'sale' ||
                      newProperty.purpose === 'buy' ||
                      newProperty.purpose === 'rent' ||
                      ((newProperty.purpose === 'sell' ||
                        newProperty.purpose === 'rentout' ||
                        newProperty.purpose === 'rent out') &&
                        newProperty.properties &&
                        newProperty.properties.length)) ? (
                      <DropdownItem
                        style={{ border: 'none' }}
                        onClick={(e) => {
                          this.setState(
                            {
                              wantedPropertyId: [newProperty.id],
                              userName: newProperty.name,
                              wantedAreasError: newProperty.wanted_areas
                                ? newProperty.wanted_areas
                                : null,
                            },
                            () => {
                              this.assign(e);
                            }
                          );
                        }}
                      >
                        Assign
                      </DropdownItem>
                    ) : null}
                    {/* {this.pageListingType === wantedListing &&
                      (activePurpose === 'sell' ||
                        activePurpose === 'rentout' ||
                        activePurpose === 'rent out') &&
                      newProperty.properties[0] &&
                      newProperty.properties[0].id &&
                      (activeButton === 'approved' ||
                        activeButton === 'new' ||
                        activeButton === 'inprogress') && (
                        <Link
                          to={{
                            pathname: `/cataloging/edit-property/${newProperty.properties[0].id}`,
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <DropdownItem style={{ border: 'none' }}>
                            Edit Property Info Form
                          </DropdownItem>
                        </Link>
                      )} */}
                    {/* {this.pageListingType === wantedListing &&
                      (activePurpose === 'sell' ||
                        activePurpose === 'rentout' ||
                        activePurpose === 'rent out') &&
                      newProperty.properties[0] &&
                      newProperty.properties[0].id &&
                      (activeButton === 'approved' ||
                        activeButton === 'new' ||
                        activeButton === 'inprogress') &&
                      newProperty.properties[0] &&
                      !newProperty.properties[0].readyForCataloguer &&
                      newProperty.properties[0].cataloguerCheck === 1 &&
                      !wantedProperty.readyForCataloguer && (
                        <DropdownItem
                          style={{ border: 'none' }}
                          onClick={() =>
                            this.updateProperty(
                              newProperty.properties.map(
                                (wantedPropertyCatalog) => wantedPropertyCatalog.id
                              )
                            )
                          }
                        >
                          Ready for Cataloguer
                        </DropdownItem>
                      )} */}
                    {activePurpose !== 'invest' && activeButton !== 'approved' && (
                      <UncontrolledDropdown direction="left">
                        <DropdownToggle
                          style={{ width: '100%', borderRadius: 0 }}
                          caret
                          color="secondary"
                        >
                          Refer
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              this.setState(
                                {
                                  wantedPropertyId: [newProperty.id],
                                  followStatus: 'interested-to-invest',
                                },
                                () => {
                                  this.getSystemUser();
                                }
                              );
                            }}
                            style={{ border: 'none' }}
                          >
                            Interested to Invest
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.setState(
                                {
                                  wantedPropertyId: [newProperty.id],
                                  followStatus: 'low-budget',
                                },
                                () => {
                                  this.getSystemUser();
                                }
                              );
                            }}
                            style={{ border: 'none' }}
                          >
                            Low Budget
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CanView>
            ) : null}
            {(activeButton === 'new' ||
              activeButton === 'inprogress' ||
              (activeButton === 'rejected' &&
                (activePurpose === 'sell' ||
                  activePurpose === 'sale' ||
                  activePurpose === 'rent' ||
                  activePurpose === 'rentout' ||
                  activePurpose === 'rent out'))) &&
            activePurpose !== 'invest' ? (
              <UncontrolledDropdown
                style={{
                  marginLeft: 5,
                  marginBottom: 0,
                  display: styleInlineBlock,
                }}
              >
                <DropdownToggle caret color="warning">
                  Follow Up
                </DropdownToggle>
                <DropdownMenu top>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={() => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          followStatus: 'call-back',
                        },
                        () => {
                          this.setState({ followModal: true });
                        }
                      );
                    }}
                  >
                    Call back
                  </DropdownItem>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={() => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          followStatus: cifCompleted,
                        },
                        () => {
                          this.setState({ followModal: true });
                        }
                      );
                    }}
                  >
                    CIF completed
                  </DropdownItem>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={() => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          followStatus: notAnswering,
                        },
                        () => {
                          this.setState({ followModal: true });
                        }
                      );
                    }}
                  >
                    Not answering
                  </DropdownItem>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={() => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          followStatus: poweredOff,
                        },
                        () => {
                          this.setState({ followModal: true });
                        }
                      );
                    }}
                  >
                    Powered off
                  </DropdownItem>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={() => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          followStatus: 'overseas',
                        },
                        () => {
                          this.setState({ followModal: true });
                        }
                      );
                    }}
                  >
                    Overseas
                  </DropdownItem>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={() => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          followStatus: 'test-lead',
                        },
                        () => {
                          this.setState({ followModal: true });
                        }
                      );
                    }}
                  >
                    Test Lead
                  </DropdownItem>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={() => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          followStatus: 'other',
                        },
                        () => {
                          this.setState({ followModal: true });
                        }
                      );
                    }}
                  >
                    Other
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null}
            {(activeButton === 'new' ||
              activeButton === 'inprogress' ||
              (activeButton === 'rejected' &&
                (activePurpose === 'sell' ||
                  activePurpose === 'sale' ||
                  activePurpose === 'rent' ||
                  activePurpose === 'rentout' ||
                  activePurpose === 'rent out'))) &&
            activePurpose !== 'invest' &&
            !newProperty.cmLead &&
            activeButton !== 'rejected' ? (
              <UncontrolledDropdown
                style={{
                  marginLeft: 5,
                  marginBottom: 0,
                  display: styleInlineBlock,
                }}
              >
                <DropdownToggle caret color="danger">
                  Reject
                </DropdownToggle>
                <DropdownMenu style={{ zIndex: '1018' }}>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={(e) => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          reason: irrelevantQuery,
                        },
                        () => {
                          this.reject(e);
                        }
                      );
                    }}
                    color={`${subFilter === irrelevantQuery ? 'primary' : ''} default`}
                  >
                    Irrelevant Query
                  </DropdownItem>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={(e) => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          reason: invalidNo,
                        },
                        () => {
                          this.reject(e);
                        }
                      );
                    }}
                    color={`${subFilter === invalidNo ? 'primary' : ''} default`}
                  >
                    Invalid No.
                  </DropdownItem>
                  <DropdownItem
                    style={{ border: 'none' }}
                    onClick={(e) => {
                      this.setState(
                        {
                          wantedPropertyId: [newProperty.id],
                          reason: 'duplicate',
                        },
                        () => {
                          this.reject(e);
                        }
                      );
                    }}
                    color={`${subFilter === 'duplicate' ? 'primary' : ''} default`}
                  >
                    Duplicate
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null}
            {this.pageListingType === wantedListing ? (
              <button
                type="button"
                className="btn btn-secondary position-relative"
                style={{ marginLeft: 5 }}
                onClick={() => this.setModal(newProperty.id)}
              >
                <span
                  style={{ cursor: 'pointer', color: 'white' }}
                  className="fa fa-comment-o white"
                  title="Add Comments"
                  aria-hidden="true"
                />
                {newProperty.unread_comments && newProperty.unread_comments !== '0' && (
                  <span
                    style={{
                      borderRadius: 10,
                    }}
                    className="position-absolute badge bg-danger"
                  >
                    {newProperty.unread_comments}
                  </span>
                )}
              </button>
            ) : null}
          </div>
        </td>
      </CanEdit>
    );
  }

  renderTableBody() {
    const { properties, responseMessage } = this.state;
    const { wantedID } = this.props;
    return (
      <tbody>
        {properties && properties.length >= 1 ? (
          properties.map((property, index) => {
            const newProperty = property;
            const rowData = wantedAllRowData(properties, newProperty, index);
            if (property.selected) {
              this.dataToExport.push(rowData);
            }
            let propertyPhone;
            if (property.phone) {
              propertyPhone = property.phone.replace('+92+920', '+92 ');
              propertyPhone = propertyPhone.replace('-', '');
              propertyPhone = propertyPhone.replace(' ', '');
            }
            let idWanted;
            if (wantedID) {
              idWanted = parseInt(wantedID.id, 10);
            }
            return (
              <tr
                ref={
                  idWanted && idWanted === property.id
                    ? (r) => {
                        this.myRef = r;
                      }
                    : null
                }
                key={property.id}
                className={`hover__for__btns ${
                  idWanted && idWanted === property.id ? 'rowFilter' : ''
                }`}
              >
                {this.renderTableBodyFirst(property, newProperty, index)}
                {this.renderTableBodySecond(newProperty, propertyPhone)}
                {this.renderTableBodyThird(newProperty, propertyPhone)}
                {this.renderTableBodyFourth(newProperty, index)}
                {this.renderTableBodyActions(newProperty, property)}
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="20" className="text-center">
              {responseMessage}
            </td>
          </tr>
        )}
      </tbody>
    );
  }

  renderTable() {
    const { loading } = this.state;
    return (
      <div className="table-responsive">
        <table id="tableWanted" className="table " style={!loading ? { minHeight: '560px' } : null}>
          {this.renderTableHead()}
          {this.renderTableBody()}
        </table>
      </div>
    );
  }

  renderPagination() {
    const { user } = this.props;
    const { startDate, endDate } = this.state;
    const { activePage, pages } = this.state;
    return (
      <div className="text-center">
        <ReactPaginate
          previousLabel="‹"
          nextLabel="›"
          forcePage={activePage - 1}
          breakLabel={
            <span
              role="presentation"
              className="customPage"
              tabIndex="-1"
              onClick={(e) => e.preventDefault()}
            >
              ...
            </span>
          }
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageSelect}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
        {this.renderBulk()}
        {(user && user.role === 'manager') ||
        (startDate && endDate && endDate.diff(startDate, 'days') < 32)
          ? this.renderDownloadAll()
          : null}
        {this.renderPageSize()}
      </div>
    );
  }

  renderDownloadAll() {
    const { loadingBigData } = this.state;
    return (
      <button type="button" className="download-all btn btn-success" onClick={this.getCSV}>
        Download All&nbsp;
        <i className={`fa ${loadingBigData ? 'fa-spinner fa-pulse red' : 'fa-download'}`} />
      </button>
    );
  }

  renderBulk() {
    const {
      properties,
      selectedBulkAction,
      selectedStatus,
      selectRejected,
      // activeButton,
    } = this.state;
    return properties.some((prop) => prop.selected) ? (
      <div className="page-bulk-actions">
        <div className="input-group">
          <select
            name="bulk_action"
            className="form-select custom-select"
            value={selectedBulkAction}
            onChange={(e) =>
              this.setState({
                selectedBulkAction: e.target.value,
              })
            }
          >
            <option value="">Bulk Actions</option>
            <option value="export">Export CSV</option>
          </select>
          {selectedBulkAction && selectedBulkAction === 'export' ? (
            <div className="input-group-append">
              <button type="button" className="btn btn-md btn-success" onClick={this.getCurrentCSV}>
                Download
              </button>
            </div>
          ) : null}
          {selectRejected || selectedStatus === 'approved' ? (
            <div className="input-group-append">
              <button
                type="button"
                className="btn btn-md btn-success"
                onClick={(e) => {
                  this.approveWanted(e);
                }}
              >
                Save
              </button>
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }

  renderPageSize() {
    const { pageSize } = this.state;
    return (
      <select
        name="pageSize"
        className="form-select custom-select pagination-page-size"
        onChange={(event) => this.handlePageSize(event)}
        required
        value={pageSize}
      >
        {PAGE_SIZE_OPTIONS.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    );
  }

  renderLoading() {
    const { loading } = this.state;
    return <Loading loading={loading} />;
  }

  render() {
    this.dataToExport = [];
    const { isDataChanged, userModal, showFilters } = this.state;
    return (
      <div className="row animated fadeIn">
        {window.removeEventListener('beforeunload', handleUnload)}

        {isDataChanged && userModal
          ? window.addEventListener('beforeunload', handleUnload)
          : window.removeEventListener('beforeunload', handleUnload)}
        {this.propertyModal()}
        {this.renderClientHistory()}
        {this.renderRefer()}
        {this.renderCreateUser()}
        {this.renderFollowUp()}
        {this.renderSchedule()}
        {this.renderAiraLogs()}
        {this.renderCallLogs()}
        {this.renderScheduleLog()}
        {this.renderCommentHistory()}
        <div className="col-12">
          <div className="row space-1">
            {this.renderSearch()}
            {this.renderStats()}
            {this.renderShowFilter()}
          </div>

          {showFilters && (
            <div className="row space-1">
              {this.renderDateFilter()}
              {this.renderFilter()}
              {this.renderFiltersNav()}
            </div>
          )}
          {this.renderLoading()}
          {this.renderRefreshAlert()}
          {this.renderNavBar()}
          {this.renderTable()}

          {this.renderPagination()}
        </div>
      </div>
    );
  }
}
Wanted.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  wantedParams: PropTypes.instanceOf(Object),
  wantedID: PropTypes.instanceOf(Object).isRequired,
  back: PropTypes.instanceOf(Boolean),
  user: PropTypes.instanceOf(Object).isRequired,
};

Wanted.defaultProps = {
  wantedParams: [],
  back: false,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  wantedParams: state.wantedParams.wantedParams,
  wantedID: state.wantedID.wantedID,
  back: state.back.back,
});

export default connect(mapStateToProps)(Wanted);
