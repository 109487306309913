/** @format */

// Bed, Bath, Bed Max, Bath Max should be null if no value added
import 'react-phone-input-2/lib/style.css';
import 'react-select/dist/react-select.css';
import Card from '@mui/material/Card';
import {
  Alert,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Dropdown,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import DateFnsUtils from '@date-io/moment';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import React from 'react';
import RichTextEditor from 'react-rte';
import Select from 'react-select';
import axios from '../utils/axiosInstance';
import { connect } from 'react-redux';
import moment from 'moment';
import * as turf from '@turf/turf';
import appImages from '../assets';
import Loader from '../components/Loader';
import AlertMessage from '../components/AlertMessage';
import {
  PROPERTY_SUBTYPES,
  RTE_TOOLBAR_CONFIG,
  SIZES,
  PROPERTY_FEATURES,
} from '../static/_constants';
import { CanAdd } from '../hoc/CanDo';
import { convertPrice, propMapApi, handleUnload, setHeader, logPageView } from '../common/actions';
import * as types from '../static/_types';
import MapContainer from './MapContainer';
// import AddProperties from './AddProperties';
import GoogleMapContainer from '../components/google-map';
import Loading from '../components/Loading';

const mapEndpoint = propMapApi();
const areaEndpoint = '/api/area/city-areas';
const maintinanaceCharges = 'Maintenance Charges';
const securityDeposit = 'Security Deposit';
const wantedAddLeadRoute = '/wanted/addLead';
const wantedVoiceLeads = '/wanted-voice-leads';
const voiceAddLeadRoute = '/wanted-voice-leads/voice-lead-form/:leadId';
const voiceEditLeadRoute = '/wanted-voice-leads/edit-voice-lead-form/:propertyId';
const wantedProjectAddLeadRoute = '/invest-leads-wanted/addCMLead';
const wantedEditPropertyRoute = '/wanted/edit-property/:propertyId';
const wantedEditPropertyInfoRoute = '/wanted/wanted-edit-property/:propertyId';
const investEditPropertyRoute = '/wanted/edit-invest/:propertyId';
const rwrEditPropertyRoute = '/rwr-management/edit-lead/:propertyId';
const wantedProjectEditPropertyRoute = '/invest-leads-wanted/edit-property/:propertyId';
const armsProjectEndpointUrl = '/api/armsProject';
const wantedProjectsRoute = '/invest-leads-wanted';
const bathAlertMsg = 'Max bath should be greater than min bath';
const bedAlertMsg = 'Max bed should be greater than min bed';
const priceAlertmsg = 'Max price should be greater than min price';
const sizeAlertMsg = 'Max size should be greater than min size';
const addWantedEndpointUrl = '/api/property/add-wanted';
const editPropertyRoute = '/cataloging/edit-property/:propertyId';
const wantedUpdateEndpointUrl = '/api/dhoondo/wanted';
const propertiesRoute = '/cataloging';
const wantedRoute = '/wanted';
const col3 = 'col-md-3 col-sm-3';
const col2 = 'col-md-2 col-sm-2';
const col8 = 'col-md-8 col-sm-8';
const col6 = 'col-md-6 col-sm-6';
const styleInlineBlock = 'inline-block';
const styleInlineFlex = 'inline-flex';

class PropertyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isDataChanged: false,
      cmLead: false,
      isWantedProperty: false,
      // propertyError: false,
      multiAreaValues: [],
      message: '',
      user: {},
      cityId: null,
      wantedProperty: {
        readyForCataloguer: false,
      },
      customer: {
        first_name: '',
        last_name: '',
      },
      property: {
        purpose: '',
        type: '',
        city: {},
        area: {},
        phones: [],
        show_address: false,
        phone: '92',
        owner_phone: '92',
        poc_phone: '92',
        countryCode: 'pk',
        dialCode: '92',
        ownerCountryCode: 'pk',
        ownerDialCode: '92',
        pocCountryCode: 'pk',
        pocDialCode: '92',
        area_id: [],
        category: 'friend-&-family',
        projectType: 'office',
        size_unit: 'marla',
        user: {
          phone: '',
        },
      },
      deletedPhonesId: [],
      description: RichTextEditor.createEmptyValue(),
      isLoadingExternally: false,
      areas: [],
      projectTypes: [],
      projectNames: [],
      logIds: null,
      btnChecked: false,
      validNumber: true,
      showUsers: false,
      wantedID: [],
      propertyID: [],
      editRCMPhoneValid: true,
      editPropertyPhoneValid: true,
      userModal: false,
      mapPlots: '',
      mapCenter: '',
      mapPlot: '',
      statsLead: false,
      loadingGeotagged: false,
      userPhoneCheck: false,
      mapZoom: 8,
      mapCities: '',
      chosenMapCity: '',
      housingSchemes: '',
      chosenHousingScheme: '',
      phaseSector: '',
      chosenPhaseSector: '',
      blockSubsectors: '',
      chosenBlockSubsector: '',
      chosenMapPlot: '',
      plotsUnavailable: false,
      changePlot: false,
      showAreaBanner: false,
      address: [{ type: '', value: '', remainingLetters: 65 }],
      addressType: {
        type: '',
        value: '',
      },
      actionDropdown: false,
      followModal: false,
      timeMaterial: null,
      followTime: null,
      blacklistCustomer: {
        blacklisted: '',
      },
      // wantedSaved: false,
      isManualGeoTag: false,
      mainLoading: true,
      baseMapCenter: [33.706782, 73.0531593],
      baseMapZoom: 13,
      chosenStreet: '',
      streets: '',
      filteredPlots: '',
      oldPurpose: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCMChange = this.handleCMChange.bind(this);
    this.handleType = this.handleType.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeLead = this.handleInputChangeLead.bind(this);
    this.handleAddressType = this.handleAddressType.bind(this);
    this.handleAddressInput = this.handleAddressInput.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleUserCheckbox = this.handleUserCheckbox.bind(this);
    this.updateProperty = this.updateProperty.bind(this);
    this.addCM = this.addCM.bind(this);
    this.renderGeneralFeatrues = this.renderGeneralFeatrues.bind(this);
    this.renderWantedFeatures = this.renderWantedFeatures.bind(this);
    this.renderSellWantedFeatures = this.renderSellWantedFeatures.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleCheckboxPlot = this.handleCheckboxPlot.bind(this);
    this.handleOwnerCheckBox = this.handleOwnerCheckBox.bind(this);
    this.handleExclusiveCheckbox = this.handleExclusiveCheckbox.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.handleLeads = this.handleLeads.bind(this);
    this.handleLeadsPending = this.handleLeadsPending.bind(this);
    this.renderFollowUp = this.renderFollowUp.bind(this);
    this.renderCreateUser = this.renderCreateUser.bind(this);
    this.renderComments = this.renderComments.bind(this);
    this.renderCancelButton = this.renderCancelButton.bind(this);
    this.renderLoader = this.renderLoader.bind(this);
    this.selectedFeatures = {};

    this.statsEndPoint = `${mapEndpoint}geotagged`;
    this.graanaStatsEndPoint = '/api/property/lead-dashboard/property-stats';
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    setHeader(
      dispatch,
      match.path === editPropertyRoute || match.path === wantedEditPropertyInfoRoute
        ? 'Property Form'
        : 'Lead Form'
    );
    logPageView(
      match.path === editPropertyRoute || match.path === wantedEditPropertyInfoRoute
        ? 'Property Form'
        : 'Lead Form'
    );
    this.getMapCities();
    if (
      match.path === wantedAddLeadRoute ||
      match.path === voiceAddLeadRoute ||
      match.path === wantedProjectAddLeadRoute
    ) {
      this.setState({
        setCountryCode: true,
        statsLead: true,
        statsAreas: [],
        mainLoading: false,
      });
      axios
        .get(`${mapEndpoint}city-name`, {
          params: {
            all: true,
          },
        })
        .then((responseGeo) => {
          this.setState({
            cityOptions: responseGeo.data,
          });
        });
    }
    if (
      match.path === wantedEditPropertyRoute ||
      match.path === investEditPropertyRoute ||
      match.path === voiceEditLeadRoute ||
      match.path === wantedProjectEditPropertyRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      this.setState(
        {
          wantedID: {
            id: match.params.propertyId,
          },
        },
        () => {
          const { wantedID } = this.state;
          dispatch({
            type: types.SET_WANTEDID,
            payload: wantedID,
          });
        }
      );
      dispatch({
        type: types.SET_BACK,
        payload: true,
      });
      axios
        .get(
          `${
            match.path === investEditPropertyRoute
              ? `/api/property/wantedproperty/${match.params.propertyId}?cmLead=true`
              : `/api/property/wantedproperty/${match.params.propertyId}`
          }`
        )
        .then((response) => {
          this.setState({ wantedNewProperty: response?.data?.results?.properties });
          this.setState({ mainLoading: false });
          this.selectedFeatures = response?.data?.results?.features
            ? JSON.parse(response.data.results.features)
            : {};
          axios
            .get(areaEndpoint, {
              params: {
                city_id: response.data.results.city_id,
                wanted:
                  match.path === wantedAddLeadRoute ||
                  match.path === voiceAddLeadRoute ||
                  match.path === wantedEditPropertyRoute ||
                  match.path === investEditPropertyRoute ||
                  match.path === voiceEditLeadRoute ||
                  match.path === rwrEditPropertyRoute
                    ? true
                    : null,
                property:
                  (match.path !== wantedAddLeadRoute &&
                    match.path !== voiceAddLeadRoute &&
                    match.path !== wantedEditPropertyRoute &&
                    match.path !== investEditPropertyRoute &&
                    match.path !== voiceEditLeadRoute) ||
                  match.path === rwrEditPropertyRoute
                    ? true
                    : null,
              },
            })
            .then((res) => {
              this.setState({
                isLoadingExternally: false,
                areas: res.data,
              });
            });
          this.setState(
            {
              property: response.data.results,
              bath: response.data.results.bath,
              bathMax: response.data.results.bath_max,
              bed: response.data.results.bed,
              bedMax: response.data.results.bed_max,
              description: RichTextEditor.createValueFromString(
                response.data.results.description || '',
                'html'
              ),
              isLoadingExternally: false,
              wantedAreas: response.data.results.wanted_areas,
              cmLead: response.data.results.cmLead,
              userFullName: response.data.results.name,
              userPhone: response.data.results.phone,
              user: { phone: response.data.results.phone },
              multiAreaValues: response.data.results.wanted_areas
                ? response.data.results.wanted_areas.map((area) => area.area)
                : null,
              cityId: response.data.results.other_city ? 'other' : response.data.results.city_id,
            },
            () => {
              this.scrollToBottom();
              const { property } = this.state;
              const { userFullName, userPhone } = this.state;
              if (property.purpose === 'sale') {
                this.setState({ oldPurpose: 'sale' });
                this.setState((prevState) => ({
                  property: {
                    ...prevState.property,
                    purpose: 'buy',
                  },
                }));
              }
              if (userPhone) {
                this.setState(
                  (prevState) => ({
                    ...prevState.user,
                    user: {
                      phone: userPhone,
                    },
                  }),
                  () => {
                    this.setState({ userPhoneCheck: true });
                  }
                );
              }
              if (userFullName) {
                const userFullNameSplit = userFullName.split(' ');
                this.setState((prevState) => ({
                  ...prevState.user,
                  user: {
                    first_name: userFullNameSplit[0],
                    last_name:
                      userFullNameSplit.length > 1
                        ? userFullNameSplit[userFullNameSplit.length - 1]
                        : null,
                  },
                }));
              }
              if (property.wanted_areas) {
                this.setState({
                  statsAreas: {
                    areaId: property.wanted_areas.map((wantedArea) => wantedArea.area_id),
                    active: property.wanted_areas.map((wantedArea) => wantedArea.area?.active),
                  },
                });
              }
              if (property.status === 'pending' && property.substatus !== 'good') {
                this.setState({ statsLead: true });
              }
              if (
                property.purpose === 'sell' ||
                property.purpose === 'rentout' ||
                property.purpose === 'rent out'
              ) {
                this.setState({ statsLead: false });
              }
              if (property.phone) {
                property.phone = property.phone.replace(/\s/g, '');
              } else {
                property.phone = '+92';
              }
              if (property.purpose === 'rent out') {
                property.purpose = 'rentout';
                this.setState({ property });
              }
              if (!property.size_unit) {
                property.size_unit = 'marla';
              }
              if (!property.size) {
                property.size = 0;
              }
              if (!property.max_size) {
                property.max_size = 0;
              }
              if ((property.phone && property.countryCode === 'pk') || property.phone) {
                property.phone = property.phone.replace(/\s/g, '');
                if (
                  property.phone.charAt(0) !== '+' &&
                  property.phone.charAt(0) !== '9' &&
                  property.phone.charAt(0) !== '0' &&
                  (property.countryCode === 'pk' || !property.countryCode)
                ) {
                  property.phone = `+92${property.phone}`;
                } else if (property.phone.includes('9203')) {
                  property.phone = property.phone.replace('9203', '923');
                } else if (property.phone.includes('923') || property.phone.includes('92 ')) {
                  // return agent.phone;
                } else if (property.phone.charAt(0) === '0') {
                  property.phone = property.phone.replace('0', '+92');
                }
              }
              this.setState({ setCountryCode: true });
              if (property.cmLead) {
                axios
                  .get(armsProjectEndpointUrl, {
                    params: {
                      all: true,
                    },
                  })
                  .then((res) => {
                    this.setState({ projectNames: res.data }, () => {
                      const { projectNames, customer } = this.state;
                      if (property.name) {
                        customer.first_name = property.name.split(' ').slice(0, -1).join(' ');
                        customer.last_name = property.name.split(' ').slice(-1).join(' ');
                      }
                      PROPERTY_SUBTYPES.commercial.forEach((subtype) => {
                        if (property.subtype === subtype.value) {
                          property.type = 'commercial';
                        }
                        return true;
                      });
                      PROPERTY_SUBTYPES.residential.forEach((subtype) => {
                        if (property.subtype === subtype.value) {
                          property.type = 'residential';
                        }
                        return true;
                      });
                      PROPERTY_SUBTYPES.plot.forEach((subtype) => {
                        if (property.subtype === subtype.value) {
                          property.type = 'plot';
                        }
                        return true;
                      });
                      this.setState({ property });
                      projectNames.forEach((project) => {
                        if (project.id === property.projectId) {
                          const projectTypes =
                            project &&
                            project.productTypes &&
                            project.productTypes.map((item) => ({
                              name: item.name,
                              id: item.id,
                            }));
                          this.setState({ projectTypes });
                        }
                        return true;
                      });
                    });
                  });
              }
              const { wantedAreas } = this.state;
              if (wantedAreas) {
                const areaIds = wantedAreas.map((item) => item.area_id);
                this.setState((prevState) => ({
                  property: {
                    ...prevState.property,
                    area_id: areaIds,
                  },
                }));
              }
            }
          );
          const { property, statsLead } = this.state;
          if (statsLead) {
            axios
              .get(`${mapEndpoint}city-name`, {
                params: {
                  all: true,
                },
              })
              .then((responseGeo) => {
                this.setState(
                  {
                    cityOptions: responseGeo.data,
                  },
                  () => {
                    const { cityOptions } = this.state;
                    if (property.city) {
                      cityOptions.map((geoCity) => {
                        if (geoCity.city_name === property.city.name) {
                          this.setState(
                            {
                              city: {
                                id: geoCity.id,
                                city_name: geoCity.city_name,
                              },
                            },
                            () => {
                              const { city } = this.state;
                              axios
                                .get(this.statsEndPoint, {
                                  params: {
                                    cityId: city.id,
                                  },
                                })
                                .then((responseCity) => {
                                  this.setState({
                                    cityData: responseCity.data,
                                    loadingGeotagged: false,
                                  });
                                });
                              axios
                                .get(`${mapEndpoint}housing-scheme-name`, {
                                  params: {
                                    cityId: city.id,
                                  },
                                })
                                .then((responseHousing) => {
                                  this.setState({ cityId: city ? city.id : null });
                                  this.setState({
                                    housingSchemesOptions: responseHousing.data,
                                  });
                                });
                            }
                          );
                        }
                        return true;
                      });
                    }
                  }
                );
              });
          }
        });
    } else if (
      match.path !== wantedAddLeadRoute &&
      match.path !== voiceAddLeadRoute &&
      match.path !== wantedProjectAddLeadRoute
    ) {
      this.setState(
        {
          propertyID: {
            id: match.params.propertyId,
          },
        },
        () => {
          const { propertyID } = this.state;
          dispatch({
            type: types.SET_PROPERTYID,
            payload: propertyID,
          });
        }
      );
      dispatch({
        type: types.SET_BACK,
        payload: true,
      });
      axios.get(`/api/property/${match.params.propertyId}`).then((response) => {
        this.getMapCities(true);
        this.setState({ mainLoading: false });
        this.selectedFeatures =
          response.data.property && response.data.property.features
            ? JSON.parse(response.data.property.features)
            : {};
        this.setState(
          {
            property: response.data ? response.data.property : null,
            bath: response.data.property.bath,
            bed: response.data.property.bed,
            electricMeters: response.data.property.electric_meters,
            gasMeters: response.data.property.gas_meters,
            description: RichTextEditor.createValueFromString(
              response.data.property.description || '',
              'html'
            ),
            locateManually: response.data.property.locate_manually,
            address:
              response.data.property.categorized_address &&
              response.data.property.categorized_address.length > 0
                ? response.data.property.categorized_address.map((adrs) => ({
                    ...adrs,
                    remainingLetters: 65 - adrs.value.length,
                  }))
                : [{ type: '', value: '' }],
            propsureId: response.data.property.propsure_id,
          },
          () => {
            this.setState({ setCountryCode: true });
            const { lat, lon } = response.data.property;
            if (response.data.property.locate_manually && lat && lon) {
              this.setState({ plotMarker: [lat, lon] });
            }
          }
        );
        const { property } = this.state;
        if (property.wanted && property.wanted.voiceLeadId) {
          if (!property.agency_id && property.user_id) {
            this.setState(
              {
                showUsers: true,
                userSearch: property.user ? property.user.first_name : null,
                selectedUserId: property.user_id,
              },
              () => {
                this.handleUserSearch();
              }
            );
          }
          if (property.user && property.user.agency) {
            axios
              .get('/api/agency', {
                params: {
                  city_id: property.user.agency.city_id,
                  all: true,
                },
              })
              .then((responseAgency) => {
                this.setState(
                  {
                    isLoadingExternally: false,
                    agencies: responseAgency.data.items,
                  },
                  () => {
                    this.setAgencyInfo();
                  }
                );
              });
            this.setState({ selectedAgencyId: property.agency_id }, () => {
              axios.get(`/api/agency/${[property.agency_id]}/users`).then((responseAgent) => {
                this.setState(
                  {
                    isLoadingAgents: false,
                    agents: responseAgent.data,
                  },
                  () => {
                    this.setAgentInfo();
                  }
                );
                this.setState({ selectedAgentId: property.user_id });
              });
            });
          }
        }
        let { countryPropertyCode } = this.state;
        const { phones, lat, lon } = property;
        if (lat && lon) {
          this.fetchPlotsByPoint(lat, lon);
          this.getSelectedPlot(property.propsure_id);
        }
        if (
          property.owner_phone === property.poc_phone &&
          property.owner_name === property.poc_name
        ) {
          this.setState({ sameAsOwner: true });
        }
        if (
          property.phone &&
          (property.phone.startsWith('92') ||
            property.phone.startsWith('+92') ||
            property.phone.startsWith('03'))
        ) {
          countryPropertyCode = 'pk';
        }
        if (property.phone && countryPropertyCode === 'pk') {
          property.phone = property.phone.replace(/\s/g, '');
          if (
            property.phone.charAt(0) !== '+' &&
            property.phone.charAt(0) !== '9' &&
            property.phone.charAt(0) !== '0'
          ) {
            property.phone = `+92${property.phone}`;
          } else if (property.phone.includes('9203')) {
            property.phone = property.phone.replace('9203', '+923');
          } else if (property.phone.includes('923') || property.phone.includes('92 ')) {
            // return agent.phone;
          } else if (property.phone.charAt(0) === '0') {
            property.phone = property.phone.replace('0', '+92');
          }
        }
        if (property.user) {
          if (property.user.phone) {
            property.user.phone = property.user.phone.replace(/\s/g, '');
            if (
              property.user.phone.charAt(0) !== '+' &&
              property.user.phone.charAt(0) !== '9' &&
              property.user.phone.charAt(0) !== '0'
            ) {
              property.user.phone = `+92${property.user.phone}`;
            } else if (property.user.phone.includes('9203')) {
              property.user.phone = property.user.phone.replace('9203', '+923');
            } else if (property.user.phone.includes('923') || property.user.phone.includes('92 ')) {
              // return agent.phone;
            } else if (property.user.phone.charAt(0) === '0') {
              property.user.phone = property.user.phone.replace('0', '+92');
            }
          }
        }
        if (phones) {
          if (phones.length === 1) {
            if (phones[0].phone) {
              if (
                phones[0].phone.charAt(0) !== '+' &&
                phones[0].phone.charAt(0) !== '9' &&
                phones[0].phone.charAt(0) !== '0'
              ) {
                phones[0].phone = `+92${phones[0].phone}`;
              } else if (phones[0].phone.includes('9203')) {
                phones[0].phone = phones[0].phone.replace('9203', '+923');
              } else if (phones[0].phone.includes('923') || phones[0].phone.includes('92 ')) {
                // return agent.phone;
              } else {
                phones[0].phone = phones[0].phone.replace('0', '+92');
              }
            }
          } else if (phones.length === 2) {
            if (phones[0].phone) {
              if (
                phones[0].phone.charAt(0) !== '+' &&
                phones[0].phone.charAt(0) !== '9' &&
                phones[0].phone.charAt(0) !== '0'
              ) {
                phones[0].phone = `+92${phones[0].phone}`;
              } else if (phones[0].phone.includes('9203')) {
                phones[0].phone = phones[0].phone.replace('9203', '+923');
              } else if (phones[0].phone.includes('923') || phones[0].phone.includes('92 ')) {
                // return agent.phone;
              } else {
                phones[0].phone = phones[0].phone.replace('0', '+92');
              }
            }
            if (phones[1].phone) {
              if (
                phones[1].phone.charAt(0) !== '+' &&
                phones[1].phone.charAt(0) !== '9' &&
                phones[1].phone.charAt(0) !== '0'
              ) {
                phones[1].phone = `+92${phones[1].phone}`;
              } else if (phones[1].phone.includes('9203')) {
                phones[1].phone = phones[1].phone.replace('9203', '+923');
              } else if (phones[1].phone.includes('923') || phones[1].phone.includes('92 ')) {
                // return agent.phone;
              } else {
                phones[1].phone = phones[1].phone.replace('0', '+92');
              }
            }
          }
          this.setState({ removeKey: Math.random() });
        }
        this.setState({ setCountryCode: true });
        if (response && response.data.property && response.data.property.city_id) {
          axios
            .get(areaEndpoint, {
              params: {
                city_id: response.data.property.city_id,
                wanted:
                  match.path === wantedAddLeadRoute ||
                  match.path === voiceAddLeadRoute ||
                  match.path === wantedEditPropertyRoute ||
                  match.path === investEditPropertyRoute ||
                  match.path === voiceEditLeadRoute ||
                  match.path === rwrEditPropertyRoute
                    ? true
                    : null,
                property:
                  (match.path !== wantedAddLeadRoute &&
                    match.path !== voiceAddLeadRoute &&
                    match.path !== wantedEditPropertyRoute &&
                    match.path !== investEditPropertyRoute &&
                    match.path !== voiceEditLeadRoute) ||
                  match.path === rwrEditPropertyRoute
                    ? true
                    : null,
              },
            })
            .then((res) => {
              this.setState({
                isLoadingExternally: false,
                areas: res.data,
              });
            });
        } else {
          this.setState({ isLoadingExternally: false });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { propertyError, dispatch } = this.props;
    if (propertyError !== prevProps.propertyError) {
      // disabling this rule for now. Will be reimplented when code is refactored to functional component.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: false,
        //  wantedSaved: false
      });
      dispatch({
        type: types.CLEAR_PROPERTYERROR,
      });
    }
  }

  handleChange(event) {
    const { value, name } = event.target;
    const { customer } = this.state;
    customer[name] = value;
    this.setState({
      customer: {
        ...customer,
      },
    });
  }

  static getArray(length) {
    return [...Array(length).keys()];
  }

  handleAddressType(event, index) {
    const { address } = this.state;
    const { value } = event.target;
    address[index].type = value;
    this.setState((prevState) => ({
      addressType: {
        ...prevState.addressType,
        type: value,
      },
      address,
    }));
    this.setState({ isDataChanged: true });
  }

  handleAddressInput(event, index) {
    const { address } = this.state;
    const { value } = event.target;
    address[index].value = value;
    address[index].remainingLetters = 65 - value.length;
    this.setState((prevState) => ({
      addressType: {
        ...prevState.addressType,
        value,
      },
      address,
    }));
    this.setState({ isDataChanged: true });
  }

  handleMatch(e) {
    e.preventDefault();
    const { match, history } = this.props;
    const { property, customer, deletedPhonesId } = this.state;
    const { phones } = property;
    if (property.name) {
      customer.first_name = property.name.split(' ').slice(0, -1).join(' ');
      customer.last_name = property.name.split(' ').slice(-1).join(' ');
    }
    if (
      (match.path === wantedEditPropertyRoute ||
        match.path === investEditPropertyRoute ||
        match.path === voiceEditLeadRoute ||
        match.path === wantedProjectEditPropertyRoute ||
        match.path === rwrEditPropertyRoute) &&
      property.phone
    ) {
      property.phone = property.phone.replace('-', '');
      property.phone = property.phone.replace(' ', '');
      property.phone = property.phone.split('');
      property.phone = property.phone.join('');
      if (property.phone.charAt(0) !== '+') {
        property.phone = `+${property.phone}`;
      }
    }
    const updatedProperty = {
      ...property,
      amenities: this.selectedFeatures,
      phones,
      area_id: property.area_id
        ? property.area_id
        : property.wanted_areas.map((areas) => areas.area_id),
      deletedPhonesId,
      features: null,
      customer,
    };
    if (property.phone.length > 10) {
      this.setState({ editRCMPhoneValid: true, loading: true });
      axios
        .put(`/api/property/update-wanted/${match.params.propertyId}`, updatedProperty)
        .then((/* response */) => {
          this.setState({ validRCMResponse: true });
          if (
            match.path === wantedEditPropertyRoute ||
            match.path === investEditPropertyRoute ||
            match.path === voiceEditLeadRoute ||
            match.path === rwrEditPropertyRoute
          ) {
            setTimeout(() => {
              history.push({
                pathname: `/wanted/edit-property/match-properties/${match.params.propertyId}`,
                state: { message: 'submit' },
              });
            }, 2000);
          } else {
            setTimeout(() => {
              history.push({
                pathname: wantedProjectsRoute,
                state: { message: 'submit' },
              });
            }, 2000);
          }
        });
    } else {
      this.setState({ loading: false });
      this.setState({ editRCMPhoneValid: false });
    }
  }

  handlePhone(phone) {
    const { customer } = this.state;
    customer.phone = phone;
    this.setState({
      customer: {
        ...customer,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handleUserPhone(phone) {
    let { userPhone } = this.state;
    const { user } = this.state;
    user.phone = phone;
    userPhone = phone;
    if (userPhone.length > 15 && user.phone.length > 15) {
      this.setState({ phoneError: true });
    } else {
      this.setState({ phoneError: false });
    }
    this.setState({ userPhone, user });
    this.setState({ isDataChanged: true });
  }

  handlePhoneProperty(phone, country) {
    if (phone.length >= 1) {
      const { property } = this.state;
      property.phone = phone;
      property.countryCode = country.countryCode;
      property.dialCode = country.dialCode;
      this.setState({
        property: {
          ...property,
        },
      });
    }
    this.setState({ isDataChanged: true });
  }

  handleCheckbox(event) {
    const { name, checked } = event.target;
    const { property } = this.state;
    if (name === 'locate_manually') {
      property.lat = null;
      property.lon = null;
    }
    if (name === 'eligibleForReward') {
      property.eligibleForReward = true;
    }
    property[name] = checked;
    this.setState({
      property: {
        ...property,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handleManualGeotagging(status, type) {
    const { property } = this.state;
    if (!status) {
      property.locate_manually = status;
    }
    this.setState({
      property: {
        ...property,
      },
      isManualGeoTag: type !== 'geoTag',
    });
  }

  handleCheckboxPlot(event) {
    const { checked } = event.target;
    this.setState({ changePlot: checked });
  }

  handlePhoneOwner(phone, country) {
    const { property } = this.state;
    property.owner_phone = phone;
    property.ownerCountryCode = country.countryCode;
    property.ownerDialCode = country.dialCode;
    this.setState({
      property: {
        ...property,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handlePhonePoc(phone, country) {
    const { property } = this.state;
    property.poc_phone = phone;
    property.pocCountryCode = country.countryCode;
    property.pocDialCode = country.dialCode;
    this.setState({
      property: {
        ...property,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handleOwnerCheckBox(event) {
    const { checked } = event.target;
    let { sameAsOwner } = this.state;
    const { property } = this.state;
    sameAsOwner = checked;
    this.setState({ sameAsOwner });
    property.poc_phone = '+92';
    property.poc_name = '';
    this.setState({ property });
    this.setState({ pocPhone: Math.random() });
    this.setState({ isDataChanged: true });
  }

  handleExclusiveCheckbox(event) {
    const { checked } = event.target;
    const { property } = this.state;
    property.exclusiveListing = checked;
    this.setState({ property, isDataChanged: true });
  }

  handleInputChangeLead(event) {
    const { value, name } = event.target;
    const { property } = this.state;
    property[name] = value;
    this.setState({ property, isDataChanged: true });
  }

  handleType(event) {
    const { value, name } = event.target;
    const {
      property,
      property: { type },
    } = this.state;
    property[name] = value;
    this.selectedFeatures = {};
    this.setState({ btnChecked: true });
    this.setState({
      bed: null,
      bath: null,
      gasMeters: null,
      electricMeters: null,
      property: {
        ...property,
        // UPDATING PROPERTY SUBTYPE ALSO IF TYPE IS CHANGING
        subtype:
          name === 'type' && type !== value ? PROPERTY_SUBTYPES[value][0].value : property.subtype,
        bed: null,
        bath: null,
        gasMeters: null,
        electricMeters: null,
        gas_meters: null,
        electric_meters: null,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handleInputChange(event) {
    const { match } = this.props;
    const { value, name } = event.target;
    const {
      property,
      property: { type },
    } = this.state;
    let { cmLead } = this.state;
    if (match.path === editPropertyRoute) {
      this.setState({ statsLead: false });
    } else if (name === 'purpose') {
      if (value === 'sell' || value === 'rentout' || value === 'rent out') {
        this.setState({ statsLead: false });
        property.bath = 0;
        property.bed = 0;
        property.bath_max = 0;
        property.bed_max = 0;
        property.min_price = 0;
        property.max_price = 0;
        property.max_size = 0;
        property.size = 0;
        this.setState({ property, bed: 0, bedMax: 0, bath: 0, bathMax: 0 });
      } else {
        this.setState({ statsLead: true });
      }
      if (value === 'invest') {
        property.cmLead = true;
        cmLead = true;
        axios
          .get(armsProjectEndpointUrl, {
            params: {
              all: true,
            },
          })
          .then((res) => {
            this.setState({ projectNames: res.data });
          });
      } else if (
        value === 'sale' ||
        value === 'buy' ||
        value === 'rent' ||
        value === 'sell' ||
        value === 'rentout' ||
        value === 'rent out'
      ) {
        this.setState({ oldPurpose: '' });
        property.cmLead = false;
        cmLead = false;
      }
      this.setState({ cmLead });
    }
    if (name === 'max_price' || name === 'min_price') {
      if (value) {
        property[name] = value;
      } else {
        property[name] = null;
      }
    } else {
      property[name] = value;
    }
    if (property.lat === '') {
      property.lat = null;
    }
    if (property.lon === '') {
      property.lon = null;
    }
    if (name === 'lat' || name === 'lon') {
      property.locate_manually = true;
    }
    if (name === 'size') {
      if (!value) {
        property[name] = null;
      }
    }
    this.setState({ btnChecked: true });
    this.setState({
      property: {
        ...property,
        subtype:
          name === 'type' &&
          type !== value &&
          PROPERTY_SUBTYPES[value] &&
          PROPERTY_SUBTYPES[value][0]
            ? PROPERTY_SUBTYPES[value][0].value
            : property.subtype,
      },
    });
    this.setState({ isDataChanged: false });
  }

  handleUserInput = (event) => {
    const { value, name } = event.target;
    const { user } = this.state;
    if (name === 'email') {
      this.setState({ leadUserEmail: value });
    }
    user[name] = value;
    this.setState({ user, isDataChanged: true });
  };

  handleUserSearch() {
    const { userSearch } = this.state;
    this.setState({ isLoadingUsers: true });
    axios.get(`/api/user/allusers?q=${userSearch}&searchby=name`).then((response) => {
      this.setState(
        {
          isLoadingUsers: false,
          users: response.data,
        },
        () => {
          let { users } = this.state;
          users = users.map((user) => ({
            ...user,
            info: `${user.first_name} ${user.last_name} (ID: ${user.id})`,
          }));
          this.setState({ users });
        }
      );
    });
  }

  handleCMChange(event) {
    const { value, name } = event.target;
    const { property } = this.state;
    property[name] = value;
    this.setState({
      property: {
        ...property,
      },
    });
  }

  handleDropdown(event) {
    const { value, name } = event.target;
    const { property } = this.state;
    if (value === 'true' || value === true) {
      this.setState({ cmLead: true });
      axios
        .get(armsProjectEndpointUrl, {
          params: {
            all: true,
          },
        })
        .then((res) => {
          this.setState({ projectNames: res.data });
        });
      property.cmLead = true;
      property[name] = value;
      this.setState({
        property: {
          ...property,
        },
      });
    } else {
      this.setState({ cmLead: false });
      property.cmLead = false;
      property[name] = value;
      this.setState({
        property: {
          ...property,
        },
      });
    }
  }

  handleRadio(some) {
    const { property } = this.state;
    if (some === 'cm') {
      this.setState({ cmLead: true });
      property.cmLead = true;
      this.setState({
        property: {
          ...property,
        },
      });
    } else {
      this.setState({ cmLead: false });
      property.cmLead = false;
      this.setState({
        property: {
          ...property,
        },
      });
    }
  }

  handleApprove(status) {
    const { match } = this.props;
    const { property, logIds } = this.state;
    this.setState({
      loading: true,
    });
    if (
      property.purpose !== 'sell' &&
      property.purpose !== 'rentout' &&
      property.purpose !== 'rent out'
    ) {
      if (
        property.size !== 'Any' &&
        property.size &&
        property.max_size &&
        property.max_size !== 'Any'
      ) {
        property.size = parseInt(property.size, 10);
      } else {
        property.size = 0;
      }
      if (property.max_size !== 'Any') {
        property.max_size = parseInt(property.max_size, 10);
      } else {
        property.max_size = 0;
      }
    }
    if (property.min_price && property.max_price) {
      property.min_price = parseInt(property.min_price, 10);
      property.max_price = parseInt(property.max_price, 10);
    }
    if (
      property.type === 'residential' &&
      property.purpose !== 'sell' &&
      property.purpose !== 'rentout' &&
      property.purpose !== 'rent out'
    ) {
      if (property.bed) {
        property.bed = parseInt(property.bed, 10);
      } else {
        property.bed = null;
      }
      if (property.bed_max) {
        property.bed_max = parseInt(property.bed_max, 10);
      } else {
        property.bed_max = null;
      }
      if (property.bath) {
        property.bath = parseInt(property.bath, 10);
      } else {
        property.bath = null;
      }
      if (property.bath_max) {
        property.bath_max = parseInt(property.bath_max, 10);
      } else {
        property.bath_max = null;
      }
    }
    if (property.purpose === 'sale' || property.purpose === 'buy' || property.purpose === 'rent') {
      if (property.size <= property.max_size) {
        if (
          property.min_price <= property.max_price ||
          !property.min_price ||
          !property.max_price
        ) {
          if (
            property.bed <= property.bed_max ||
            property.bed === '' ||
            property.bed_max === '' ||
            !property.bed ||
            !property.bed_max
          ) {
            if (
              property.bath <= property.bath_max ||
              property.bath === '' ||
              property.bath_max === '' ||
              !property.bath ||
              !property.bath_max
            ) {
              const updatedLead = {
                wantedIds: match.params.propertyId,
                status,
                share: false,
                logId: logIds,
              };
              this.updatedWanted(updatedLead);
            } else {
              window.alert(bathAlertMsg);
              this.setState({
                loading: false,
              });
            }
          } else {
            window.alert(bedAlertMsg);
            this.setState({
              loading: false,
            });
          }
        } else {
          window.alert(priceAlertmsg);
          this.setState({
            loading: false,
          });
        }
      } else {
        window.alert(sizeAlertMsg);
        this.setState({
          loading: false,
        });
      }
    } else {
      const updatedLead = {
        wantedIds: match.params.propertyId,
        status,
        share: false,
        logId: logIds,
      };
      this.updatedWanted(updatedLead);
    }
  }

  handleLeads(reason) {
    const { property, statsAreas, logIds } = this.state;
    const { match } = this.props;
    this.setState({
      loading: true,
    });
    const updatedLead = {
      wantedIds: match.params.propertyId,
      status: 'rejected',
      reason,
      logId: logIds,
    };
    if (!property.name) {
      window.alert('Enter client name');
      this.setState({
        loading: false,
      });
    } else if (!property.type) {
      window.alert('Select property type');
      this.setState({
        loading: false,
      });
    } else if (!statsAreas) {
      window.alert('Select atleast one area');
      this.setState({
        loading: false,
      });
    } else {
      this.updatedWanted(updatedLead);
    }
  }

  handleUserCheckbox(event) {
    const { name, checked } = event.target;
    let { showUsers } = this.state;
    const { property } = this.state;
    if (name === 'await_publishing') {
      property.await_publishing = checked;
    } else {
      showUsers = checked;
      property.await_publishing = false;
      property.agency_id = null;
    }
    this.setState({ showUsers, property });
  }

  handleLeadsPending(event) {
    event.preventDefault();
    const { match } = this.props;
    const { property, followDate, followTime, followStatus, logIds } = this.state;
    if (followDate && !followTime) {
      window.alert('Time is required');
    } else {
      this.setState({ loading: true });
      if (
        property.purpose !== 'sell' &&
        property.purpose !== 'rentout' &&
        property.purpose !== 'rent out'
      ) {
        if (
          property.size !== 'Any' &&
          property.size &&
          property.max_size &&
          property.max_size !== 'Any'
        ) {
          property.size = parseInt(property.size, 10);
        } else {
          property.size = 0;
        }
        if (property.max_size !== 'Any') {
          property.max_size = parseInt(property.max_size, 10);
        } else {
          property.max_size = 0;
        }
      }
      if (property.min_price && property.max_price) {
        property.min_price = parseInt(property.min_price, 10);
        property.max_price = parseInt(property.max_price, 10);
      }
      if (
        property.type === 'residential' &&
        property.purpose !== 'sell' &&
        property.purpose !== 'rentout' &&
        property.purpose !== 'rent out'
      ) {
        if (property.bed) {
          property.bed = parseInt(property.bed, 10);
        } else {
          property.bed = null;
        }
        if (property.bed_max) {
          property.bed_max = parseInt(property.bed_max, 10);
        } else {
          property.bed_max = null;
        }
        if (property.bath) {
          property.bath = parseInt(property.bath, 10);
        } else {
          property.bath = null;
        }
        if (property.bath_max) {
          property.bath_max = parseInt(property.bath_max, 10);
        } else {
          property.bath_max = null;
        }
      }
      if (
        property.purpose === 'sale' ||
        property.purpose === 'buy' ||
        property.purpose === 'rent'
      ) {
        if (property.size <= property.max_size) {
          if (
            property.min_price <= property.max_price ||
            !property.min_price ||
            !property.max_price
          ) {
            if (
              property.bed <= property.bed_max ||
              property.bed === '' ||
              property.bed_max === '' ||
              !property.bed ||
              !property.bed_max
            ) {
              if (
                property.bath <= property.bath_max ||
                property.bath === '' ||
                property.bath_max === '' ||
                !property.bath ||
                !property.bath_max
              ) {
                const updatedLead = {
                  wantedIds: match.params.propertyId,
                  status: 'pending',
                  reason: followStatus,
                  followDate,
                  followTime,
                  logId: logIds,
                };
                this.updatedWanted(updatedLead);
              } else {
                window.alert(bathAlertMsg);
                this.setState({ loading: false });
              }
            } else {
              window.alert(bedAlertMsg);
              this.setState({ loading: false });
            }
          } else {
            window.alert(priceAlertmsg);
            this.setState({ loading: false });
          }
        } else {
          window.alert(sizeAlertMsg);
          this.setState({ loading: false });
        }
      } else {
        const updatedLead = {
          wantedIds: match.params.propertyId,
          status: 'pending',
          reason: followStatus,
          followDate,
          followTime,
          logId: logIds,
        };
        this.updatedWanted(updatedLead);
      }
    }
  }

  onMapClick = (e) => {
    const { isManualGeoTag } = this.state;
    if (isManualGeoTag) {
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          lat: e.lat,
          lon: e.lng,
          locate_manually: true,
        },
      }));
    } else {
      // console.log()
    }
  };

  setAddFeature(type, value) {
    if (value) {
      this.selectedFeatures[type] = value;
    } else {
      delete this.selectedFeatures[type];
    }
    this.setState({ isDataChanged: true });
  }

  setYearBuilt(type, value) {
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        built_year: value,
      },
    }));
    this.setState({ isDataChanged: true });
  }

  setFeature(type, value) {
    if (value) {
      if (type === 'year_built' || type === 'parking_space') {
        this.selectedFeatures[type] = parseInt(value, 10);
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            amenities: this.selectedFeatures,
          },
        }));
      }
      if (type === 'bed') {
        this.setState({ bed: parseInt(value, 10) });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            bed: parseInt(value, 10),
          },
        }));
      }
      if (type === 'electric') {
        this.setState({ electricMeters: parseInt(value, 10) });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            electric_meters: parseInt(value, 10),
          },
        }));
      }
      if (type === 'gas') {
        this.setState({ gasMeters: parseInt(value, 10) });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            gas_meters: parseInt(value, 10),
          },
        }));
      }
      if (type === 'bed_max') {
        this.setState({ bedMax: parseInt(value, 10) });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            bed_max: parseInt(value, 10),
          },
        }));
      }
      if (type === 'bath') {
        this.setState({ bath: parseInt(value, 10) });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            bath: parseInt(value, 10),
          },
        }));
      }
      if (type === 'bath_max') {
        this.setState({ bathMax: parseInt(value, 10) });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            bath_max: parseInt(value, 10),
          },
        }));
      }
      if (type === 'floors') {
        this.selectedFeatures[type] = parseInt(value, 10);
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            amenities: this.selectedFeatures,
          },
        }));
      }
      if (type === 'downpayment' || type === 'security_deposit' || type === 'maintenance_charges') {
        this.selectedFeatures[type] = parseInt(value, 10);
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            features: this.selectedFeatures,
          },
        }));
      }
    } else {
      if (type === 'bath') {
        this.setState({ bath: '' });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            bath: null,
          },
        }));
      }
      if (type === 'electric') {
        this.setState({ electricMeters: '' });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            electric_meters: null,
          },
        }));
      }
      if (type === 'gas') {
        this.setState({ gasMeters: '' });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            gas_meters: null,
          },
        }));
      }
      if (type === 'bed') {
        this.setState({ bed: '' });
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            bed: null,
          },
        }));
      } else {
        this.forceUpdate();
        delete this.selectedFeatures[type];
      }
    }
    this.setState({ isDataChanged: true });
  }

  getMapCities = async (bool) => {
    const resp = await axios.get(`${mapEndpoint}cities?secure=true`);
    const { data } = resp;
    this.setState({
      mapCities: data,
    });

    if (bool) {
      const { property } = this.state;
      if (property.locate_manually && property.city) {
        this.setMapCities(property.city);
      }
    }
  };

  static getCity(/* query */) {
    return axios
      .get('/api/city', {
        params: {
          all: true,
        },
      })
      .then((response) => ({ options: [...response.data.items, { name: 'Other', id: 'other' }] }));
  }

  static getAllCities(/* query */) {
    return axios
      .get('/api/city', {
        params: {
          all: true,
        },
      })
      .then((response) => ({ options: response.data.items }));
  }

  static getWantedCity() {
    return axios
      .get('/api/city/wanted-city', {
        params: {
          all: true,
        },
      })
      .then((response) => ({ options: response.data }));
  }

  static getProject() {
    return axios
      .get(armsProjectEndpointUrl, {
        params: {
          all: true,
        },
      })
      .then((response) => ({ options: response.data }));
  }

  setProject(project) {
    const projectTypes =
      project &&
      project.productTypes &&
      project.productTypes.map((item) => ({ name: item.name, id: item.id }));
    this.setState({ projectTypes }, () => {
      this.setState({ isLoadingExternally: false });
    });
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        projectId: project ? project.id : null,
        projectName: project ? project.name : null,
        armsProjectTypeId:
          project.productTypes && project.productTypes[0] ? project.productTypes[0].id : null,
        projectType:
          project.productTypes && project.productTypes[0] ? project.productTypes[0].name : null,
      },
    }));
    this.setState({ isDataChanged: true });
  }

  setProjectType(type) {
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        armsProjectTypeId: type ? type.id : null,
        projectType: type ? type.name : null,
      },
    }));
    this.setState({ isDataChanged: true });
  }

  setStatsCity(city) {
    this.setState({ loadingGeotagged: true });
    if (city) {
      this.setState({
        city: {
          id: city ? city.id : null,
          city_name: city ? city.city_name : '',
        },
      });
      axios
        .get(this.statsEndPoint, {
          params: {
            cityId: city.id,
          },
        })
        .then((response) => {
          this.saveResponseToState(response);
        });
      axios
        .get(`${mapEndpoint}housing-scheme-name`, {
          params: {
            cityId: city.id,
          },
        })
        .then((response) => {
          this.setState({
            housingSchemesOptions: response.data,
            loadingGeotagged: false,
          });
        });
    } else {
      this.setState({
        city: {
          id: null,
          city_name: null,
        },
        housing: {
          id: null,
          housing_scheme_name: null,
        },
        phase: {
          id: null,
          phase_sector_name: null,
        },
        block: {
          id: null,
          block_subsector_name: null,
        },
      });
      this.setState({
        housingSchemesOptions: undefined,
        phaseSectorOptions: undefined,
        blockSectorOptions: undefined,
        loadingGeotagged: false,
      });
    }
    this.setState({ cityId: city ? city.id : null });
    this.setState({ isDataChanged: true });
  }

  setStatsHousing(housing) {
    const { cityId } = this.state;
    this.setState({ loadingGeotagged: true });
    if (housing) {
      this.setState({
        housing: {
          id: housing ? housing.id : null,
          housing_scheme_name: housing ? housing.housing_scheme_name : '',
        },
      });
      if (cityId === 1) {
        axios
          .get(`${mapEndpoint}block-subsector-name`, {
            params: {
              housingSchemeId: housing.id,
              cityId,
            },
          })
          .then((response) => {
            this.setState({ blockSectorOptions: response.data, loadingGeotagged: false });
          });
        axios
          .get(this.statsEndPoint, {
            params: {
              housingSchemeId: housing.id,
              cityId,
            },
          })
          .then((response) => {
            this.saveResponseToState(response);
          });
      } else if (cityId !== 1) {
        axios
          .get(`${mapEndpoint}phase-sector-name`, {
            params: {
              housingSchemeId: housing.id,
            },
          })
          .then((response) => {
            this.setState({ phaseSectorOptions: response.data, loadingGeotagged: false });
          });
        axios
          .get(this.statsEndPoint, {
            params: {
              housingSchemeId: housing.id,
              cityId,
            },
          })
          .then((response) => {
            this.saveResponseToState(response);
          });
      }
      this.setState({
        phase: null,
        block: null,
        blockSectorOptions: undefined,
      });
    } else {
      this.setState(
        {
          housing: null,
          phase: null,
          block: null,
          phaseSectorOptions: undefined,
          blockSectorOptions: undefined,
        },
        () => {
          axios
            .get(this.statsEndPoint, {
              params: {
                cityId,
              },
            })
            .then((response) => {
              this.saveResponseToState(response);
            });
        }
      );
    }
  }

  setStatsPhase(phase) {
    const { cityId, housingSchemeId } = this.state;
    this.setState({ loadingGeotagged: true });
    if (phase) {
      this.setState({
        phase: {
          id: phase ? phase.id : null,
          phase_sector_name: phase ? phase.phase_sector_name : '',
        },
      });
      axios
        .get(`${mapEndpoint}block-subsector-name`, {
          params: {
            cityId,
            housingSchemeId,
            phaseSectorId: phase.id,
          },
        })
        .then((response) => {
          this.setState({ blockSectorOptions: response.data, loadingGeotagged: false });
        });
      axios
        .get(this.statsEndPoint, {
          params: {
            cityId,
            housingSchemeId,
            phaseSectorId: phase.id,
          },
        })
        .then((response) => {
          this.saveResponseToState(response);
        });
      this.setState({
        block: null,
      });
    } else {
      this.setState(
        {
          phase: null,
          block: null,
          blockSectorOptions: undefined,
        },
        () => {
          axios
            .get(this.statsEndPoint, {
              params: {
                housingSchemeId,
                cityId,
              },
            })
            .then((response) => {
              this.saveResponseToState(response);
            });
        }
      );
    }
  }

  setStatsBlock(block) {
    const { cityId, housingSchemeId, phaseSectorId } = this.state;
    this.setState({ loadingGeotagged: true });
    if (block) {
      this.setState({
        block: {
          id: block ? block.id : null,
          block_subsector_name: block ? block.block_subsector_name : '',
        },
      });
      axios
        .get(this.statsEndPoint, {
          params: {
            cityId,
            housingSchemeId,
            phaseSectorId,
            blockSubsectorId: block.id,
          },
        })
        .then((response) => {
          this.saveResponseToState(response);
        });
    } else {
      this.setState(
        {
          block: null,
        },
        () => {
          axios
            .get(this.statsEndPoint, {
              params: {
                cityId,
                housingSchemeId,
                phaseSectorId,
              },
            })
            .then((response) => {
              this.saveResponseToState(response);
            });
        }
      );
    }
  }

  setMapCities(city) {
    const { isManualGeoTag, mapCities, property } = this.state;
    if (mapCities.length) {
      const selectedCity = mapCities.filter(
        (c) => c.city_name.toLowerCase() === city.name.toLowerCase()
      );
      if (selectedCity.length && (isManualGeoTag || property.locate_manually)) {
        const center = this.getCentroid(JSON.parse(selectedCity[0].geoData));
        this.setState({ baseMapCenter: center, baseMapZoom: 10 });
        this.setMapCity(selectedCity[0]);
      }
    }
  }

  setCity(city) {
    const { match } = this.props;
    const { property } = this.state;
    this.setMapCities(city);
    this.setState((prevState) => ({
      isLoadingExternally: !!city && city.id !== 'other',
      areas: [],
      cityId: city.id,
      property: {
        ...prevState.property,
        city_id: city && city.id !== 'other' ? city.id : null,
        area_id: null,
      },
    }));
    if (property.cmLead || property.cmLead === 'true') {
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          other_city:
            city && city.id && city.id !== 'other' && prevState.property.other_city
              ? !prevState.property.other_city
              : true,
        },
      }));
    }
    this.setState((prevState) => ({
      multiAreaValues: [],
      property: {
        ...prevState.property,
        area_id: null,
      },
    }));
    if (city && city.id) {
      axios
        .get(areaEndpoint, {
          params: {
            city_id: city.id,
            wanted:
              match.path === wantedAddLeadRoute ||
              match.path === voiceAddLeadRoute ||
              match.path === wantedEditPropertyRoute ||
              match.path === investEditPropertyRoute ||
              match.path === voiceEditLeadRoute ||
              match.path === rwrEditPropertyRoute
                ? true
                : null,
            property:
              (match.path !== wantedAddLeadRoute &&
                match.path !== voiceAddLeadRoute &&
                match.path !== wantedEditPropertyRoute &&
                match.path !== investEditPropertyRoute &&
                match.path !== voiceEditLeadRoute) ||
              match.path === rwrEditPropertyRoute
                ? true
                : null,
          },
        })
        .then((response) => {
          this.setState({
            isLoadingExternally: false,
            areas: response.data,
          });
        });
    }
    this.setState({ isDataChanged: true });
  }

  setDescription(description) {
    const { property } = this.state;
    property.description = description.toString('html');
    this.setState({
      property,
      description,
    });
    this.setState({ isDataChanged: true });
  }

  setSellArea(area) {
    if (area) {
      const areaIds = [area.id];
      const active = [area.active];
      const multiAreaValues = [area];
      this.setState((prevState) => ({
        multiAreaValues,
        statsAreas: {
          ...prevState.statsAreas,
          areaId: areaIds,
          active,
        },
        property: {
          ...prevState.property,
          area_id: areaIds,
        },
      }));
    }
    this.setState({ isDataChanged: true });
  }

  setAgency(agency) {
    this.fetchAgencyAgents(agency.id);
    this.setState({ selectedAgentId: null });
  }

  setAgents(agent) {
    const { property } = this.state;
    let { countryCode } = this.state;
    property.user_id = agent.id;
    let agentPhone = agent.phone;
    if (agentPhone && (agentPhone.startsWith('92') || agentPhone.startsWith('+92'))) {
      countryCode = 'pk';
    }
    if (agentPhone && countryCode === 'pk') {
      agentPhone = agent.phone.replace(/\s/g, '');
      if (
        agentPhone.charAt(0) !== '+' &&
        agentPhone.charAt(0) !== '9' &&
        agentPhone.charAt(0) !== '0'
      ) {
        agentPhone = `+92${agentPhone}`;
      } else if (agentPhone.includes('9203')) {
        agentPhone = agentPhone.replace('9203', '+923');
      } else if (agentPhone.includes('923') || agentPhone.includes('92 ')) {
        // return agent.phone;
      } else {
        agentPhone = agentPhone.replace('0', '+92');
      }
    }
    if (!agentPhone) {
      agentPhone = '+92';
    }
    this.setState({
      property: {
        ...property,
        phone: agentPhone,
      },
    });
    this.setState({ selectedAgentId: agent.id });
  }

  setAgentCity(city) {
    this.setState({ selectedAgencyId: null, selectedAgentId: null });
    this.setState((prevState) => ({
      isLoadingExternally: !!city,
      property: {
        ...prevState.property,
        agency: {
          ...prevState.agency,
          city_id: city ? city.id : null,
        },
      },
    }));

    if (city && city.id) {
      axios
        .get('/api/agency', {
          params: {
            city_id: city.id,
            all: true,
          },
        })
        .then((response) => {
          this.setState(
            {
              isLoadingExternally: false,
              agencies: response.data.items,
            },
            () => {
              this.setAgencyInfo();
            }
          );
        });
    }
    this.setState({ isDataChanged: true });
  }

  setAgencyInfo() {
    let { agencies } = this.state;
    agencies = agencies.map((agency) => ({
      ...agency,
      info: `${agency.name} (ID: ${agency.id})`,
    }));
    this.setState({ agencies });
  }

  setAgentInfo() {
    let { agents } = this.state;
    agents = agents.map((agent) => {
      const newAgent = agent;
      newAgent.full_name = `${newAgent.first_name} ${newAgent.last_name} - (${newAgent.email})`;
      this.setState({ agents });
      return newAgent;
    });
  }

  setUsers(user) {
    const { property } = this.state;
    let { countryCode } = this.state;
    property.user_id = user.id;
    let userPhone = user.phone;
    if (userPhone && (userPhone.startsWith('92') || userPhone.startsWith('+92'))) {
      countryCode = 'pk';
    }
    if (userPhone && countryCode === 'pk') {
      userPhone = user.phone.replace(/\s/g, '');
      if (
        userPhone.charAt(0) !== '+' &&
        userPhone.charAt(0) !== '9' &&
        userPhone.charAt(0) !== '0'
      ) {
        userPhone = `+92${userPhone}`;
      } else if (userPhone.includes('9203')) {
        userPhone = userPhone.replace('9203', '+923');
      } else if (userPhone.includes('923') || userPhone.includes('92 ')) {
        // return agent.phone;
      } else {
        userPhone = userPhone.replace('0', '+92');
      }
    }
    if (!userPhone) {
      userPhone = '+92';
    }
    this.setState({
      property: {
        ...property,
        phone: userPhone,
      },
    });
    this.setState({ userPhone: Math.random() });
    this.setState({ selectedUserId: user.id });
    this.setState({ isDataChanged: true });
  }

  setArea(area) {
    const { match } = this.props;
    let cords = null;
    if (area) {
      if (area && area.id) {
        const { housingSchemes } = this.state;
        if (housingSchemes.length) {
          const selectedArea = housingSchemes.filter(
            (c) =>
              c.housing_scheme_name &&
              c.housing_scheme_name.toLowerCase() === area.name.toLowerCase()
          );
          if (selectedArea.length) {
            const latlon = selectedArea[0].latLon;
            const { coordinates } = latlon;
            cords = coordinates;
            this.setMapHousingScheme(selectedArea[0]);
          }
        }
      }
      if (
        match.path === wantedEditPropertyRoute ||
        match.path === investEditPropertyRoute ||
        match.path === voiceEditLeadRoute ||
        match.path === wantedProjectEditPropertyRoute ||
        match.path === wantedAddLeadRoute ||
        match.path === voiceAddLeadRoute ||
        match.path === wantedProjectAddLeadRoute ||
        match.path === rwrEditPropertyRoute
      ) {
        const areaIds = area.map((item) => item.id);
        const active = area.map((item) => item.active);
        const multiAreaValues = area;
        this.setState((prevState) => ({
          multiAreaValues,
          statsAreas: {
            ...prevState.statsAreas,
            areaId: areaIds,
            active,
          },
          property: {
            ...prevState.property,
            area_id: areaIds,
          },
        }));
      } else {
        const areaIds = area.id;
        const multiAreaValues = area;
        this.setState((prevState) => ({
          multiAreaValues,
          property: {
            ...prevState.property,
            area_id: areaIds,
          },
        }));
      }
    }

    this.setState((prevState) => ({
      baseMapCenter: cords && cords.length > 1 ? [cords[1], cords[0]] : prevState.baseMapCenter,
      baseMapZoom: cords && cords.length > 1 ? 13 : prevState.baseMapZoom,
    }));
    this.setState({ isDataChanged: true });
  }

  getAgency() {
    const { property } = this.state;
    if (property.agency) {
      return property.agency.city_id;
    }
    if (property.user && property.user.agency) {
      return property.user.agency.city_id;
    }
    return null;
  }

  editWantedProperty = () => {
    const { history } = this.props;
    const { wantedNewProperty } = this.state;
    if (wantedNewProperty && wantedNewProperty.length > 0) {
      history.push(`/cataloging/edit-property/${wantedNewProperty[0].id}`);
    }
  };

  redirectToPropertyForm = (e) => {
    e.preventDefault();
    const { match, history } = this.props;
    const { property, wantedID } = this.state;
    this.setState({ isDataChanged: false });
    axios
      .put(`/api/property/update-wanted/${match.params.propertyId}`, property)
      .then((response) => {
        if (response) {
          history.push(`/wanted/add-property/${wantedID.id}`);
        }
      });
  };

  catalogingDone = () => {
    this.setState({ catalogingDone: true });
  };

  fetchAgencyAgents = (agencyId) => {
    const { property } = this.state;
    property.agency_id = agencyId;
    this.setState({ isLoadingAgents: true, selectedAgencyId: agencyId });

    axios.get(`/api/agency/${agencyId}/users`).then((response) => {
      this.setState(
        {
          isLoadingAgents: false,
          agents: response.data,
        },
        () => {
          this.setAgentInfo();
        }
      );
    });
  };

  checkMinMaxValue = () => {
    const { property } = this.state;

    if (property.size && property.max_size && Number(property.size) > Number(property.max_size)) {
      window.alert(sizeAlertMsg);
      return false;
    }
    if (
      property.min_price &&
      property.max_price &&
      Number(property.min_price) > Number(property.max_price)
    ) {
      window.alert(priceAlertmsg);
      return false;
    }
    if (property.bath && property.bath_max && Number(property.bath) > Number(property.bath_max)) {
      window.alert(bathAlertMsg);
      return false;
    }
    if (property.bed && property.bed_max && Number(property.bed) > Number(property.bed_max)) {
      window.alert(bedAlertMsg);
      return false;
    }
    return true;
  };

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView();
    }
  };

  fetchPlotsByPoint = async (lat, lon) => {
    const resp = await axios.post(`${mapEndpoint}plots-by-point`, { lon, lat });
    const { data } = resp;
    this.setState(
      {
        mapPlots: data,
        filteredPlots: data,
        mapCenter: [lat, lon],
      },
      () => {
        let { filteredPlots, mapPlots } = this.state;
        filteredPlots = filteredPlots.map((plot) => ({
          ...plot,
          info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
        }));
        mapPlots = mapPlots.map((plot) => ({
          ...plot,
          info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
        }));
        this.setState({ filteredPlots, mapPlots });
      }
    );
    this.setStreetValue();
  };

  onPlotSelect = (plot) => {
    const center = this.getCentroid(JSON.parse(plot.geoData));

    this.setState((prevState) => ({
      mapPlot: plot,
      mapCenter: center,
      property: {
        ...prevState.property,
        lat: center[0],
        lon: center[1],
        propsure_id: plot.id,
      },
    }));
  };

  getCentroid = (geoJSON) => {
    const poly =
      geoJSON.type === 'Polygon'
        ? turf.polygon(geoJSON.coordinates)
        : turf.multiPolygon(geoJSON.coordinates);
    const centroid = turf.centroid(poly);
    const lon = centroid.geometry.coordinates[0];
    const lat = centroid.geometry.coordinates[1];
    const marker = [lat, lon];
    return marker;
  };

  setMapCity = (city) => {
    const { property } = this.state;
    const center = this.getCentroid(JSON.parse(city.geoData));
    this.setState({
      chosenMapCity: city,
      housingSchemes: '',
      chosenHousingScheme: '',
      phaseSector: '',
      chosenPhaseSector: '',
      blockSubsectors: '',
      chosenBlockSubsector: '',
      chosenMapPlot: '',
      mapPlots: '',
      mapZoom: 9,
      mapCenter: center,
      filteredPlots: '',
      chosenStreet: '',
      streets: '',
    });
    if (property.locate_manually) {
      this.getHousingSchemes(city.id, true);
    } else {
      this.getHousingSchemes(city.id);
    }
  };

  getHousingSchemes = async (cityId, bool) => {
    const { property } = this.state;
    this.setState({ loadingHousing: true });
    const resp = await axios.get(`${mapEndpoint}housing-scheme?cityId=${cityId}&&secure=true`);
    const { data } = resp;
    this.setState(
      {
        housingSchemes: data,
        mapZoom: 10,
        loadingHousing: false,
      },
      () => {
        if (bool && property.area) {
          this.setArea(property.area);
        }
      }
    );
  };

  setMapHousingScheme = (housingScheme) => {
    const center = this.getCentroid(JSON.parse(housingScheme.geoData));

    this.setState({
      chosenHousingScheme: housingScheme,
      phaseSector: '',
      blockSubsectors: '',
      mapPlots: '',
      filteredPlots: '',
      mapZoom: 11,
      chosenMapPlot: '',
      mapCenter: center,
      chosenStreet: '',
      chosenBlockSubsector: '',
      streets: '',
      chosenPhaseSector: '',
    });

    this.getPhaseSector(housingScheme.id);
  };

  getPhaseSector = async (housingSchemeId) => {
    this.setState({ loadingPhase: true });
    const resp = await axios.get(
      `${mapEndpoint}phase-sector?housingSchemeId=${housingSchemeId}&&secure=true`
    );
    const { data } = resp;
    this.setState({
      phaseSector: data,
      loadingPhase: false,
    });
    if (data.length <= 0) {
      this.getBlockSubSectorPhase(housingSchemeId);
    }
  };

  setMapPhaseSector = (phaseSector) => {
    const center = this.getCentroid(JSON.parse(phaseSector.geoData));
    this.setState({
      chosenPhaseSector: phaseSector,
      blockSubsectors: '',
      mapPlots: '',
      filteredPlots: '',
      mapZoom: 14,
      mapCenter: center,
      chosenMapPlot: '',
      chosenStreet: '',
      streets: '',
    });

    this.getBlockSubSector(phaseSector.id);
  };

  getBlockSubSectorPhase = async (housingSchemeId) => {
    const { phaseSector } = this.state;
    const resp = await axios.get(
      `${mapEndpoint}block-subsector-by-housing-scheme?housingSchemeId=${housingSchemeId}&&secure=true`
    );
    const { data } = resp;
    if (!data.length && !phaseSector.length) {
      this.getMapPlotsByHousingScheme(housingSchemeId);
    }

    this.setState({ blockSubsectors: data });
  };

  getBlockSubSector = async (phaseSectorId) => {
    const resp = await axios.get(
      `${mapEndpoint}block-subsector?phaseSectorId=${phaseSectorId}&&secure=true`
    );
    const { data } = resp;

    this.setState({
      blockSubsectors: data,
    });

    if (data.length <= 0) {
      this.getMapPlotsByPhaseSector(phaseSectorId);
    }
  };

  setMapBlockSubSector = (blockSubsector) => {
    const center = this.getCentroid(JSON.parse(blockSubsector.geoData));

    this.setState({
      chosenBlockSubsector: blockSubsector,
      mapZoom: 16,
      mapCenter: center,
      chosenMapPlot: '',
    });

    this.getMapPlotsByBlockSubSector(blockSubsector.id);
  };

  getMapPlotsByPhaseSector = async (phaseSectorId) => {
    this.setState({ loadingBlock: true });
    const resp = await axios.get(`${mapEndpoint}plots?phaseSectorId=${phaseSectorId}&&secure=true`);
    const { data } = resp;
    if (data.length <= 0) {
      this.setState({ plotsUnavailable: true });
    } else {
      this.setState(
        {
          mapPlots: data,
          filteredPlots: data,
          mapZoom: 16,
          plotsUnavailable: false,
          loadingBlock: false,
        },
        () => {
          let { filteredPlots, mapPlots } = this.state;
          filteredPlots = filteredPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          mapPlots = mapPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          this.setState({ filteredPlots, mapPlots });
        }
      );
      this.setStreetValue();
    }
  };

  getMapPlotsByHousingScheme = async (housingSchemeId) => {
    const resp = await axios.get(
      `${mapEndpoint}plots?housingSchemeId=${housingSchemeId}&&secure=true`
    );
    const { data } = resp;
    if (data.length <= 0) {
      this.setState({ plotsUnavailable: true });
    } else {
      this.setState(
        {
          mapPlots: data,
          filteredPlots: data,
          mapZoom: 16,
          plotsUnavailable: false,
          loadingBlock: false,
        },
        () => {
          let { filteredPlots, mapPlots } = this.state;
          filteredPlots = filteredPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          mapPlots = mapPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          this.setState({ filteredPlots, mapPlots });
        }
      );
    }
  };

  getMapPlotsByBlockSubSector = async (blockSubsectorId) => {
    const resp = await axios.get(
      `${mapEndpoint}plots?blockSubsectorId=${blockSubsectorId}&&secure=true`
    );
    const { data } = resp;
    if (data.length <= 0) {
      this.setState({ plotsUnavailable: true });
    } else {
      this.setState(
        {
          mapPlots: data,
          filteredPlots: data,
          mapZoom: 16,
          plotsUnavailable: false,
        },
        () => {
          let { filteredPlots, mapPlots } = this.state;
          filteredPlots = filteredPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          mapPlots = mapPlots.map((plot) => ({
            ...plot,
            info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
          }));
          this.setState({ filteredPlots, mapPlots });
        }
      );
      this.setStreetValue();
    }
  };

  filterPlotsByStreet = (v) => {
    const { mapPlots, chosenMapPlot } = this.state;
    if (chosenMapPlot) {
      this.setState({ chosenMapPlot: '' });
    }
    const plots = mapPlots.filter((i) => i.Street === v.value);
    this.setState({ filteredPlots: plots, chosenStreet: v }, () => {
      let { filteredPlots } = this.state;
      filteredPlots = filteredPlots.map((plot) => ({
        ...plot,
        info: plot.Plot_No ? plot.Plot_No : plot.plot_no,
      }));
      this.setState({ filteredPlots });
    });
  };

  setStreetValue = () => {
    const { chosenMapCity } = this.state;
    if (chosenMapCity.id === '1') {
      let streets;
      const StreetSuggestion = [];
      const { mapPlots } = this.state;
      streets = mapPlots.filter((item) => item.info !== null);
      streets = [...new Set(mapPlots.map((item) => item.Street))];
      streets.forEach((s) => {
        const obj = {
          value: s,
        };
        StreetSuggestion.push(obj);
      });
      streets = StreetSuggestion.filter((item) => item.value != null);
      this.setState({ streets });
    }
  };

  setMapPlot = (plot) => {
    const center = this.getCentroid(JSON.parse(plot.geoData));
    const { user } = this.props;
    const { property } = this.state;
    this.setState({ propsureId: plot.id });
    if (user) {
      property.geotagged_by = user.email;
    }
    property.platform = 'Graana Admin';
    [property.lat, property.lon] = center;
    this.setState({
      chosenMapPlot: plot,
      mapCenter: center,
      plotMarker: center,
    });
  };

  setPropSureId = (plotId, graanaId) => {
    const { user } = this.props;
    axios.post(`${mapEndpoint}propsure-plots`, {
      plot_id: plotId,
      propsure_id: graanaId,
      assigned_by: user ? user.email : null,
      geotag_platform: 'Graana Admin',
    });
  };

  getSelectedPlot = async (id) => {
    if (id) {
      const resp = await axios.get(`${mapEndpoint}plot/${id}?secure=true`);
      const plot = resp.data;
      this.onPlotSelect(plot[0]);
    }
  };

  getBlacklistOption = (property) => {
    if (property && property.customer) {
      return (
        <button
          type="button"
          className="dropdown-item"
          style={{ border: 'none' }}
          onClick={() => this.blacklistClient(property.customer.blacklisted)}
        >
          {property.customer && !property.customer.blacklisted
            ? 'Add to blacklist'
            : 'Remove from blacklist'}
        </button>
      );
    }
    return null;
  };

  getExclusiveBool = () => {
    const { user } = this.props;
    if (user.superadmin) {
      return false;
    }
    if (user.department && user.department.slug === 'am-ops') {
      return false;
    }
    return true;
  };

  fetchAgencyAgents = (agencyId) => {
    const { property } = this.state;
    property.agency_id = agencyId;
    this.setState({ isLoadingAgents: true, selectedAgencyId: agencyId });

    axios.get(`/api/agency/${agencyId}/users`).then((response) => {
      this.setState(
        {
          isLoadingAgents: false,
          agents: response.data,
        },
        () => {
          this.setAgentInfo();
        }
      );
    });
  };

  handleReject = (reason) => {
    if (window.confirm('Are you sure you want to decline the lead?')) {
      this.handleLeads(reason);
    }
  };

  blacklistClient(status) {
    const { property, blacklistCustomer } = this.state;
    const { match } = this.props;
    const { logIds } = this.state;
    this.setState((prevState) => ({
      actionDropdown: !prevState.actionDropdown,
    }));
    if (property.customer) {
      blacklistCustomer.blacklisted = !status;
    }
    blacklistCustomer.wanted_id = match.params.propertyId;
    if (logIds) {
      blacklistCustomer.logId = logIds;
    }
    axios
      .put(`/api/dhoondo/blacklist-client/${property.customer.id}`, blacklistCustomer)
      .then((response) => {
        if (response.data) {
          this.setState({ logIds: response.data.logId });
        }
        property.logId = logIds;
        if (status) {
          window.alert('Client removed from black-list');
          property.customer.blacklisted = !property.customer.blacklisted;
        } else {
          window.alert('Client added to black-list');
          property.customer.blacklisted = !property.customer.blacklisted;
        }
      });
  }

  saveResponseToState(response) {
    this.setState({
      cityData: response.data,
      loadingGeotagged: false,
    });
  }

  refreshStats() {
    const { property, statsAreas } = this.state;
    this.setState({
      typeListings: 0,
      purposeListings: 0,
      matchedListings: 0,
      showAreaBanner: false,
    });
    axios
      .get(this.graanaStatsEndPoint, {
        params: {
          cityId: property.city_id,
          areaId: statsAreas && statsAreas.areaId ? statsAreas.areaId.map((id) => id) : null,
          purpose: property.purpose,
          type: property.type,
          subtype: property.subtype,
          unit: property.size_unit,
          size_min: property.size && property.size !== 'Any' ? property.size : null,
          size_max: property.max_size && property.max_size !== 'Any' ? property.max_size : null,
          price_max: property.max_price ? property.max_price : null,
          price_min: property.min_price ? property.min_price : null,
          bed_min: property.bed ? property.bed : null,
          bed_max: property.bed_max ? property.bed_max : null,
          bath_min: property.bath ? property.bath : null,
          bath_max: property.bath_max ? property.bath_max : null,
        },
      })
      .then((response) => {
        this.setState({
          typeListings:
            statsAreas && statsAreas.areaId && statsAreas.areaId.length > 0
              ? response.data.totalListingsWithType
              : 'N/A',
          purposeListings:
            statsAreas && statsAreas.areaId && statsAreas.areaId.length > 0
              ? response.data.totalListingsWithTypeAndPurpose
              : 'N/A',
          matchedListings:
            statsAreas && statsAreas.areaId && statsAreas.areaId.length > 0
              ? response.data.totalListingsWithAllCriteria
              : 'N/A',
          showAreaBanner: !(
            (statsAreas && statsAreas.areaId && statsAreas.areaId.length > 0) ||
            !property.city_id
          ),
        });
      });
  }

  clearInput() {
    if (this.inputTitle) {
      if (this.inputTitle.value) {
        this.inputTitle.value = '';
      }
    }
  }

  addMorePhoneNumber() {
    const { property } = this.state;
    const { phones } = property;
    const phoneCount = phones.length + 1;
    if (phoneCount < 3) {
      this.setState({ extraNumber: false });
      phones.push('');
      this.setState({ property });
    } else {
      this.setState({ extraNumber: true });
      window.scrollTo(0, this.extraNumberRef.offsetTop);
    }
  }

  addAddressField(index) {
    const { address, addressType } = this.state;
    if (address[index] && address[index].type) {
      address.push(addressType);
      this.setState({ address });
      address[address.length - 1].type = '';
      address[address.length - 1].value = '';
    } else {
      window.alert('Fill previous field first');
    }
    this.setState({ isDataChanged: true });
  }

  removeAddressField(index) {
    const { address } = this.state;
    address.splice(index, 1);
    this.setState({ address, isDataChanged: true });
  }

  removePhoneNumber(index, contact) {
    const { property, deletedPhonesId } = this.state;
    const { phones } = property;
    deletedPhonesId.push(contact.id);
    this.setState({ removeKey: Math.random() + index + 1 });
    phones.splice(index, 1);
    this.setState({ property, deletedPhonesId });
    this.setState({ extraNumber: false });
  }

  changePhoneNumber(value, index, phone, country) {
    const { property } = this.state;
    const { phones } = property;
    if (phones[index].id) {
      phones[index].phone = value;
    } else {
      phones[index] = {
        phone: value,
        countryCode: country.countryCode,
        dialCode: country.dialCode,
      };
    }
    this.setState({ property, isDataChanged: true });
  }

  showComments() {
    const { match } = this.props;
    axios
      .get(`/api/dhoondo/wanted/${match.params.propertyId}/aira-comments`)
      .then((responseComments) => {
        this.setState({ wantedComments: responseComments.data.rows, modalVisible: true });
      });
  }

  submitComment(wantedId) {
    const { message } = this.state;
    axios
      .post(`/api/dhoondo/comment/${wantedId}`, { comment: message })
      .then(() => {
        this.clearInput();
        axios.get(`/api/dhoondo/wanted/${wantedId}/aira-comments`).then((response) => {
          this.setState({ wantedComments: response.data.rows }, () => {
            const { wantedComments } = this.state;
            this.setState({ wantedComments });
            this.scrollToBottom();
          });
        });
      })
      .then(() => {
        this.setState({ message: '' });
      });
  }

  addCM(event) {
    event.preventDefault();
    const { history, match } = this.props;
    const { property, cityId } = this.state;
    property.phone = property.phone.replace('-', '');
    property.phone = property.phone.replace(' ', '');
    property.phone = property.phone.split('');
    property.phone = property.phone.join('');
    if (match.path === voiceAddLeadRoute) {
      property.voiceLeadId = Number(match.params.leadId);
    }
    if (property.phone.charAt(0) !== '+') {
      property.phone = `+${property.phone}`;
    }
    this.setState({ loading: true });
    if (
      match.path === wantedAddLeadRoute ||
      match.path === voiceAddLeadRoute ||
      match.path === wantedProjectAddLeadRoute
    ) {
      if (property.phone.length > 10) {
        if (Number(property.min_price) > Number(property.max_price)) {
          window.alert(priceAlertmsg);
          this.setState({ loading: false });
        } else {
          this.setState({ validNumber: true });
          axios
            .post(addWantedEndpointUrl, {
              ...property,
              other_city: cityId === 'other',
            })
            .then(() => {
              this.setState({ validCMResponse: true });
              window.scrollTo(0, this.addCMRef.offsetTop);
              setTimeout(() => {
                history.push(
                  `${
                    match.path === voiceAddLeadRoute || match.path === voiceEditLeadRoute
                      ? wantedVoiceLeads
                      : '/wanted'
                  }`
                );
              }, 2000);
            });
        }
      } else {
        this.setState({ loading: false });
        this.setState({ validNumber: false });
        window.scrollTo(0, this.myRef.offsetTop);
      }
    } else if (
      match.path === wantedEditPropertyRoute ||
      match.path === investEditPropertyRoute ||
      match.path === voiceEditLeadRoute ||
      match.path === wantedProjectEditPropertyRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      if (!property.min_price) {
        property.min_price = null;
      }
      if (!property.max_price) {
        property.max_price = null;
      }
      if (property.phone) {
        property.phone = property.phone.replace('-', '');
        property.phone = property.phone.replace(' ', '');
      }
      if (!property.projectType) {
        property.projectType = 'office';
      }
      if (!property.category) {
        property.category = 'friend-&-family';
      }
      if (property.phone.length > 10) {
        if (Number(property.min_price) > Number(property.max_price)) {
          window.alert(priceAlertmsg);
          this.setState({ loading: false });
        } else {
          this.setState({ validNumber: true });
          axios
            .put(`/api/property/update-wanted/${match.params.propertyId}`, {
              ...property,
              other_city: property.city_id && cityId !== 'other' ? false : property.other_city,
            })
            .then(() => {
              this.setState({ validCMResponse: true });
              window.scrollTo(0, this.addCMRef.offsetTop);
              setTimeout(() => {
                history.push({
                  pathname: `${
                    match.path === voiceEditLeadRoute || match.path === voiceAddLeadRoute
                      ? wantedVoiceLeads
                      : '/wanted'
                  }`,
                  state: { message: 'submit' },
                });
              }, 2000);
            });
        }
      } else {
        this.setState({ loading: false });
        this.setState({ validNumber: false });
        window.scrollTo(0, this.myRef.offsetTop);
      }
    }
    this.setState({ isDataChanged: false });
  }

  updatedWanted(updatedLeads) {
    const { match, history } = this.props;
    const {
      loading,
      property,
      deletedPhonesId,
      customer,
      sameAsOwner,
      wantedUserId,
      followDate,
      followTime,
      logIds,
      oldPurpose,
    } = this.state;
    const { phones } = property;
    let wantedPropertyId;
    if (property.name) {
      customer.first_name = property.name.split(' ').slice(0, -1).join(' ');
      customer.last_name = property.name.split(' ').slice(-1).join(' ');
    }
    if (
      (match.path === wantedEditPropertyRoute ||
        match.path === investEditPropertyRoute ||
        match.path === voiceEditLeadRoute ||
        match.path === wantedProjectEditPropertyRoute ||
        match.path === rwrEditPropertyRoute) &&
      property.phone
    ) {
      property.phone = property.phone.replace('-', '');
      property.phone = property.phone.replace(' ', '');
      property.phone = property.phone.split('');
      property.phone = property.phone.join('');
      if (property.phone.charAt(0) !== '+') {
        property.phone = `+${property.phone}`;
      }
    }
    if (
      (match.path === wantedAddLeadRoute ||
        match.path === voiceAddLeadRoute ||
        match.path === wantedProjectAddLeadRoute) &&
      property.countryCode === 'pk'
    ) {
      property.phone = property.phone.split('');
      property.phone.splice(0, 1, '+9');
      property.phone = property.phone.join('');
    }
    if (property.phones) {
      if (property.phones[1] && !property.phones[1].phone) {
        deletedPhonesId.push(property.phones[1].id);
        property.phones.splice(1, 2);
      }
      if (property.phones[0] && !property.phones[0].phone) {
        deletedPhonesId.push(property.phones[0].id);
        property.phones.splice(0, 1);
      }
    }
    if (match.path === editPropertyRoute) {
      if (property.phone) {
        property.phone = property.phone.replace('-', '');
        property.phone = property.phone.replace(' ', '');
        property.phone = property.phone.split('');
        property.phone = property.phone.join('');
        if (property.phone.charAt(0) !== '+') {
          property.phone = `+${property.phone}`;
        }
      }
      if (property.owner_phone) {
        property.owner_phone = property.owner_phone.replace('-', '');
        property.owner_phone = property.owner_phone.replace(' ', '');
        property.owner_phone = property.owner_phone.split('');
        property.owner_phone = property.owner_phone.join('');
        if (property.owner_phone.charAt(0) !== '+') {
          property.owner_phone = `+${property.owner_phone}`;
        }
      }
      if (property.poc_phone) {
        property.poc_phone = property.poc_phone.replace('-', '');
        property.poc_phone = property.poc_phone.replace(' ', '');
        property.poc_phone = property.poc_phone.split('');
        property.poc_phone = property.poc_phone.join('');
        if (property.poc_phone.charAt(0) !== '+') {
          property.poc_phone = `+${property.poc_phone}`;
        }
      }
    }
    if (phones && phones.length === 1) {
      if (phones[0].phone) {
        phones[0].phone = phones[0].phone.replace('-', '');
        phones[0].phone = phones[0].phone.replace(' ', '');
        phones[0].phone = phones[0].phone.split('');
        phones[0].phone = phones[0].phone.join('');
        if (phones[0].phone.charAt(0) !== '+') {
          phones[0].phone = `+${phones[0].phone}`;
        }
      }
    }
    if (phones && phones.length === 2) {
      if (phones[0].phone) {
        phones[0].phone = phones[0].phone.replace('-', '');
        phones[0].phone = phones[0].phone.replace(' ', '');
        phones[0].phone = phones[0].phone.split('');
        phones[0].phone = phones[0].phone.join('');
        if (phones[0].phone.charAt(0) !== '+') {
          phones[0].phone = `+${phones[0].phone}`;
        }
      }
      if (phones[1].phone) {
        phones[1].phone = phones[1].phone.replace('-', '');
        phones[1].phone = phones[1].phone.replace(' ', '');
        phones[1].phone = phones[1].phone.split('');
        phones[1].phone = phones[1].phone.join('');
        if (phones[1].phone.charAt(0) !== '+') {
          phones[1].phone = `+${phones[1].phone}`;
        }
      }
    }
    if (sameAsOwner) {
      property.poc_name = property.owner_name;
      property.poc_phone = property.owner_phone;
      this.setState({ property });
    }
    if (oldPurpose === 'sale') {
      property.purpose = 'sale';
      this.setState({ property });
    }
    property.user_id = wantedUserId;
    const updatedProperty = {
      ...property,
      amenities: this.selectedFeatures,
      phones,
      area_id: property.area_id
        ? property.area_id
        : property.wanted_areas.map((areas) => areas.area_id),
      deletedPhonesId,
      features: null,
      customer,
      logId: logIds,
    };
    if (!loading) {
      this.setState({ loading: true });
      if (
        match.path === wantedEditPropertyRoute ||
        match.path === investEditPropertyRoute ||
        match.path === voiceEditLeadRoute ||
        match.path === rwrEditPropertyRoute
      ) {
        if (property.phone.length > 10) {
          this.setState({ editRCMPhoneValid: true });
          axios
            .put(`/api/property/update-wanted/${match.params.propertyId}`, updatedProperty)
            .then((responseLog) => {
              this.setState({ logIds: responseLog.data.logId });
              const { logId } = responseLog.data;
              if (this.addRCMRef) {
                window.scrollTo(0, this.addRCMRef.offsetTop);
              }
              if (
                match.path === wantedEditPropertyRoute ||
                match.path === investEditPropertyRoute ||
                match.path === rwrEditPropertyRoute
              ) {
                if (updatedLeads.status === 'approved') {
                  this.setState({ loading: true });
                  wantedPropertyId = [parseInt(match.params.propertyId, 10)];
                  const updatedLead = {
                    wantedIds: wantedPropertyId,
                    status: updatedLeads.status,
                    share: false,
                    logId,
                  };
                  axios.put(wantedUpdateEndpointUrl, updatedLead).then((response) => {
                    if (typeof response.data === 'string') {
                      window.alert(response.data);
                      this.setState({
                        loading: false,
                        validRCMResponse: false,
                      });
                    } else {
                      this.setState({ loading: false });
                      this.setState({ validRCMResponse: true });
                      setTimeout(() => {
                        history.push({
                          pathname: '/wanted',
                          state: { message: 'submit' },
                        });
                      }, 2000);
                    }
                  });
                } else if (
                  updatedLeads.reason === 'call-back' ||
                  updatedLeads.reason === 'cif-completed' ||
                  updatedLeads.reason === 'interested-to-invest' ||
                  updatedLeads.reason === 'not-answering' ||
                  updatedLeads.reason === 'powered-off' ||
                  updatedLeads.reason === 'overseas' ||
                  updatedLeads.reason === 'test-lead' ||
                  updatedLeads.reason === 'interested-to-sell' ||
                  updatedLeads.reason === 'interested-to-rentou'
                ) {
                  this.setState({ loading: true });
                  wantedPropertyId = [parseInt(match.params.propertyId, 10)];
                  const updatedLead = {
                    wantedIds: wantedPropertyId,
                    status: 'pending',
                    reason: updatedLeads.reason,
                    followup_date: followDate,
                    followup_time: followTime,
                    logId,
                  };
                  axios.put(wantedUpdateEndpointUrl, updatedLead).then((response) => {
                    if (typeof response.data === 'string') {
                      window.alert(response.data);
                      this.setState({
                        loading: false,
                        validRCMResponse: false,
                      });
                    } else {
                      this.setState({ validRCMResponse: true });
                      this.setState({ loading: false }, () => {
                        setTimeout(() => {
                          history.push({
                            pathname: '/wanted',
                            state: { message: 'submit' },
                          });
                        }, 2000);
                      });
                    }
                  });
                } else if (
                  updatedLeads.reason === 'irrelevant-query' ||
                  updatedLeads.reason === 'invalid-no' ||
                  updatedLeads.reason === 'duplicate' ||
                  updatedLeads.reason === 'low-budget'
                ) {
                  this.setState({ loading: true });
                  wantedPropertyId = [parseInt(match.params.propertyId, 10)];
                  const updatedLead = {
                    wantedIds: wantedPropertyId,
                    status: 'rejected',
                    reason: updatedLeads.reason,
                    logId,
                  };
                  axios.put(wantedUpdateEndpointUrl, updatedLead).then((response) => {
                    if (typeof response.data === 'string') {
                      window.alert(response.data);
                      this.setState({
                        loading: false,
                        validRCMResponse: false,
                      });
                    } else {
                      this.setState({ validRCMResponse: true });
                      this.setState({ loading: false }, () => {
                        setTimeout(() => {
                          history.push({
                            pathname: '/wanted',
                            state: { message: 'submit' },
                          });
                        }, 2000);
                      });
                    }
                  });
                }
              } else {
                setTimeout(() => {
                  history.push({
                    pathname: wantedProjectsRoute,
                    state: { message: 'submit' },
                  });
                }, 2000);
              }
            });
        } else {
          this.setState({ loading: false });
          this.setState({ editRCMPhoneValid: false });
        }
      }
    }
  }

  createUser(event) {
    event.preventDefault();
    const { loading, user, phoneError, userPhone, property } = this.state;
    const { match, history } = this.props;
    const { logIds } = this.state;
    user.phone = userPhone;
    user.wanted_id = match.params.propertyId;
    user.lead = property;
    if (logIds) {
      user.logId = logIds;
    }
    this.setState({ user }, () => {
      if (!loading) {
        this.setState({
          loading: true,
        });
        if (!phoneError) {
          axios
            .post('/api/user', user)
            .then((response) => {
              if (response) {
                this.setState({ logIds: response.data.logId });
                property.logId = logIds;
                this.setState({ wantedUserId: response.data.savedUser.id });
                this.setState({ isDataChanged: false }, () => {
                  window.alert('User created successfully.');
                  history.push(wantedRoute);
                });
              }
              this.setState({
                loading: false,
                userModal: false,
              });
            })
            .catch((error) => {
              if (error.response && error.response.data === 'Email already exists') {
                window.alert('User already exists');
              }
              this.setState({
                loading: false,
              });
            });
        } else {
          window.alert('Enter valid phone number');
          this.setState({ loading: false });
        }
      }
    });
  }

  updateProperty(event) {
    event.preventDefault();
    this.setState({ actionDropdown: false });
    if (event.nativeEvent.submitter.name === 'match') {
      this.handleMatch(event);
    } else if (event.nativeEvent.submitter.name === 'assign') {
      if (window.confirm('Are you sure you want to assign the lead?')) {
        this.handleApprove('approved');
      }
    } else {
      const { match, history } = this.props;
      const {
        loading,
        property,
        deletedPhonesId,
        customer,
        sameAsOwner,
        statsAreas,
        logIds,
        oldPurpose,
        catalogingDone,
        // wantedUserId,
        // wantedUserId,
        // deleteLogs,
        // wantedUserId,
      } = this.state;
      let { address, propsureId } = this.state;
      const aLength = address.length;
      if (address[aLength - 1] && address[aLength - 2]) {
        if (address[aLength - 1].type === address[aLength - 2].type) {
          address.pop();
        }
      }
      address = address.filter((ad) => ad.value);
      property.categorized_address = address;
      const { phones } = property;
      if (property.name) {
        customer.first_name = property.name.split(' ').slice(0, -1).join(' ');
        customer.last_name = property.name.split(' ').slice(-1).join(' ');
      }
      if (
        (match.path === wantedEditPropertyRoute ||
          match.path === investEditPropertyRoute ||
          match.path === voiceEditLeadRoute ||
          match.path === wantedProjectEditPropertyRoute ||
          match.path === rwrEditPropertyRoute) &&
        property.phone
      ) {
        property.phone = property.phone.replace('-', '');
        property.phone = property.phone.replace(' ', '');
        property.phone = property.phone.split('');
        property.phone = property.phone.join('');
        if (property.phone.charAt(0) !== '+') {
          property.phone = `+${property.phone}`;
        }
      }
      if (
        (match.path === wantedAddLeadRoute ||
          match.path === voiceAddLeadRoute ||
          match.path === wantedProjectAddLeadRoute) &&
        property.countryCode === 'pk'
      ) {
        if (property.phone[0] !== '+') {
          property.phone = property.phone.split('');
          property.phone.splice(0, 1, '+9');
          property.phone = property.phone.join('');
        }
      }
      if (property.phones) {
        if (property.phones[1] && !property.phones[1].phone) {
          deletedPhonesId.push(property.phones[1].id);
          property.phones.splice(1, 2);
        }
        if (property.phones[0] && !property.phones[0].phone) {
          deletedPhonesId.push(property.phones[0].id);
          property.phones.splice(0, 1);
        }
      }
      if (match.path === editPropertyRoute) {
        if (property.phone) {
          property.phone = property.phone.replace('-', '');
          property.phone = property.phone.replace(' ', '');
          property.phone = property.phone.split('');
          property.phone = property.phone.join('');
          if (property.phone.charAt(0) !== '+') {
            property.phone = `+${property.phone}`;
          }
        }
        if (property.owner_phone) {
          property.owner_phone = property.owner_phone.replace('-', '');
          property.owner_phone = property.owner_phone.replace(' ', '');
          property.owner_phone = property.owner_phone.split('');
          property.owner_phone = property.owner_phone.join('');
          if (property.owner_phone.charAt(0) !== '+') {
            property.owner_phone = `+${property.owner_phone}`;
          }
        }
        if (property.poc_phone) {
          property.poc_phone = property.poc_phone.replace('-', '');
          property.poc_phone = property.poc_phone.replace(' ', '');
          property.poc_phone = property.poc_phone.split('');
          property.poc_phone = property.poc_phone.join('');
          if (property.poc_phone.charAt(0) !== '+') {
            property.poc_phone = `+${property.poc_phone}`;
          }
        }
      }
      if (phones && phones.length === 1) {
        if (phones[0].phone) {
          phones[0].phone = phones[0].phone.replace('-', '');
          phones[0].phone = phones[0].phone.replace(' ', '');
          phones[0].phone = phones[0].phone.split('');
          phones[0].phone = phones[0].phone.join('');
          if (phones[0].phone.charAt(0) !== '+') {
            phones[0].phone = `+${phones[0].phone}`;
          }
        }
      }
      if (phones && phones.length === 2) {
        if (phones[0].phone) {
          phones[0].phone = phones[0].phone.replace('-', '');
          phones[0].phone = phones[0].phone.replace(' ', '');
          phones[0].phone = phones[0].phone.split('');
          phones[0].phone = phones[0].phone.join('');
          if (phones[0].phone.charAt(0) !== '+') {
            phones[0].phone = `+${phones[0].phone}`;
          }
        }
        if (phones[1].phone) {
          phones[1].phone = phones[1].phone.replace('-', '');
          phones[1].phone = phones[1].phone.replace(' ', '');
          phones[1].phone = phones[1].phone.split('');
          phones[1].phone = phones[1].phone.join('');
          if (phones[1].phone.charAt(0) !== '+') {
            phones[1].phone = `+${phones[1].phone}`;
          }
        }
      }
      if (sameAsOwner) {
        property.poc_name = property.owner_name;
        property.poc_phone = property.owner_phone;
        this.setState({ property });
      }
      property.propsure_id = propsureId;
      if (property.locate_manually) {
        property.propsure_id = null;
        propsureId = null;
        this.setState({ mapPlot: null });
      }
      if (match.path === voiceAddLeadRoute) {
        property.voiceLeadId = Number(match.params.leadId);
      }
      if (oldPurpose === 'sale') {
        property.purpose = 'sale';
        this.setState({ property });
      }
      const updatedProperty = {
        ...property,
        amenities: this.selectedFeatures,
        cataloging_done: catalogingDone || null,
        phones,
        area_id: property.area_id
          ? property.area_id
          : property.wantedAreas && property.wanted_areas.map((areas) => areas.area_id),
        deletedPhonesId,
        features: null,
        customer,
        logId: logIds,
      };
      if (!loading) {
        this.setState({ loading: true });
        if (
          match.path === wantedEditPropertyRoute ||
          match.path === investEditPropertyRoute ||
          match.path === voiceEditLeadRoute ||
          match.path === rwrEditPropertyRoute
        ) {
          if (
            property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out'
          ) {
            if (
              property.size !== 'Any' &&
              property.size &&
              property.max_size &&
              property.max_size !== 'Any'
            ) {
              property.size = parseInt(property.size, 10);
            } else {
              property.size = 0;
            }
            if (property.max_size !== 'Any' && property.size !== 'Any') {
              property.max_size = parseInt(property.max_size, 10);
            } else {
              property.max_size = 0;
            }
          }
          if (property.min_price && property.max_price) {
            property.min_price = parseInt(property.min_price, 10);
            property.max_price = parseInt(property.max_price, 10);
          }
          if (
            property.type === 'residential' &&
            property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out'
          ) {
            if (property.bed) {
              property.bed = parseInt(property.bed, 10);
            } else {
              property.bed = 0;
            }
            if (property.bed_max) {
              property.bed_max = parseInt(property.bed_max, 10);
            } else {
              property.bed_max = 0;
            }
            if (property.bath) {
              property.bath = parseInt(property.bath, 10);
            } else {
              property.bath = 0;
            }
            if (property.bath_max) {
              property.bath_max = parseInt(property.bath_max, 10);
            } else {
              property.bath_max = 0;
            }
          }
          if (property.size <= property.max_size) {
            if (
              property.min_price <= property.max_price ||
              !property.min_price ||
              !property.max_price
            ) {
              if (
                property.bed <= property.bed_max ||
                property.bed === '' ||
                property.bed_max === '' ||
                !property.bed ||
                !property.bed_max
              ) {
                if (
                  property.bath <= property.bath_max ||
                  property.bath === '' ||
                  property.bath_max === '' ||
                  !property.bath ||
                  !property.bath_max
                ) {
                  if (property.phone.length > 10) {
                    this.setState({ editRCMPhoneValid: true });
                    if (statsAreas) {
                      if (
                        (property.purpose !== 'sell' &&
                          property.purpose !== 'rentout' &&
                          property.purpose !== 'rent out') ||
                        ((property.purpose === 'sell' ||
                          property.purpose === 'rentout' ||
                          property.purpose === 'rent out') &&
                          property.properties &&
                          property.properties.length > 0) ||
                        match.path === voiceEditLeadRoute ||
                        match.path === voiceAddLeadRoute
                      ) {
                        axios
                          .put(
                            `/api/property/update-wanted/${match.params.propertyId}`,
                            updatedProperty
                          )
                          .then((/* response */) => {
                            this.setState(
                              {
                                validRCMResponse: true,
                                // wantedSaved:
                                //   !(
                                //     property.purpose !== 'sell' &&
                                //     property.purpose !== 'rentout' &&
                                //     property.purpose !== 'rent out'
                                //   ) ||
                                //   ((property.purpose === 'sell' ||
                                //     property.purpose === 'rentout' ||
                                //     property.purpose === 'rent out') &&
                                //     property.properties &&
                                //     property.properties.length > 0) ||
                                //   match.path === voiceEditLeadRoute ||
                                //   match.path === voiceAddLeadRoute
                                //     ? match.params.propertyId
                                //     : null,
                              },
                              () => {
                                // const { wantedSaved } = this.state;

                                if (
                                  match.path === wantedEditPropertyRoute ||
                                  match.path === investEditPropertyRoute
                                ) {
                                  setTimeout(() => {
                                    history.push({
                                      pathname: `${
                                        match.path === voiceEditLeadRoute ||
                                        match.path === voiceAddLeadRoute
                                          ? wantedVoiceLeads
                                          : '/wanted'
                                      }`,
                                      state: { message: 'submit' },
                                    });
                                  }, 2000);
                                } else if (match.path === rwrEditPropertyRoute) {
                                  setTimeout(() => {
                                    history.push({
                                      pathname: '/rwr-management',
                                      state: { message: 'submit' },
                                    });
                                  }, 2000);
                                } else {
                                  setTimeout(() => {
                                    history.push({
                                      pathname: wantedProjectsRoute,
                                      state: { message: 'submit' },
                                    });
                                  }, 2000);
                                }
                                if (match.path === rwrEditPropertyRoute) {
                                  setTimeout(() => {
                                    history.push({
                                      pathname: '/rwr-management',
                                      state: { message: 'submit' },
                                    });
                                  }, 2000);
                                } else if (match.path === wantedEditPropertyRoute) {
                                  setTimeout(() => {
                                    history.push({
                                      pathname: '/wanted',
                                      state: { message: 'submit' },
                                    });
                                  }, 2000);
                                } else if (
                                  match.path === voiceEditLeadRoute ||
                                  match.path === voiceAddLeadRoute
                                ) {
                                  setTimeout(() => {
                                    history.push({
                                      pathname: wantedVoiceLeads,
                                      state: { message: 'submit' },
                                    });
                                  }, 4000);
                                }
                              }
                            );
                            if (this.addCMRef) {
                              window.scrollTo(0, this.addRCMRef.offsetTop);
                            }
                          });
                      } else {
                        this.setState({
                          //   wantedSaved: match.params.propertyId,
                          //   leadPropertyPayload: updatedProperty,
                        });
                      }
                    }
                  } else {
                    this.setState({ loading: false });
                    this.setState({ editRCMPhoneValid: false });
                  }
                } else {
                  window.alert(bathAlertMsg);
                  this.setState({ loading: false });
                }
              } else {
                window.alert(bedAlertMsg);
                this.setState({ loading: false });
              }
            } else {
              window.alert(priceAlertmsg);
              this.setState({ loading: false });
            }
          } else {
            window.alert(sizeAlertMsg);
            this.setState({ loading: false });
          }
        } else if (
          match.path === wantedAddLeadRoute ||
          match.path === voiceAddLeadRoute ||
          match.path === wantedProjectAddLeadRoute
        ) {
          if (this.checkMinMaxValue()) {
            if (property.countryCode !== 'pk') {
              this.setState({ editRCMPhoneValid: true });
              axios
                .post(addWantedEndpointUrl, { ...updatedProperty, other_city: false })
                .then((/* response */) => {
                  this.setState({ validRCMResponse: true });
                  window.scrollTo(0, this.addRCMRef.offsetTop);
                  setTimeout(() => {
                    history.push(
                      match.path === voiceAddLeadRoute || match.path === voiceEditLeadRoute
                        ? wantedVoiceLeads
                        : '/wanted'
                    );
                  }, 2000);
                });
            } else if (property.phone.length > 10) {
              this.setState({ editRCMPhoneValid: true });
              axios
                .post(addWantedEndpointUrl, { ...updatedProperty, other_city: false })
                .then((/* response */) => {
                  this.setState(
                    {
                      validRCMResponse: true,
                      // wantedSaved:
                      //   property.purpose === 'sell' ||
                      //   property.purpose === 'rentout' ||
                      //   property.purpose === 'rent out'
                      //     ? response.data.id
                      //     : false,
                    },
                    () => {
                      setTimeout(() => {
                        history.push(
                          match.path === voiceAddLeadRoute || match.path === voiceEditLeadRoute
                            ? wantedVoiceLeads
                            : '/wanted'
                        );
                      }, 2000);
                    }
                  );
                  if (this.addRCMRef) {
                    window.scrollTo(0, this.addRCMRef.offsetTop);
                  }
                });
            } else {
              this.setState({ loading: false });
              this.setState({ editRCMPhoneValid: false });
            }
          } else {
            this.setState({ loading: false });
          }
        } else if (match.path === '/media-requests/edit-property/:propertyId') {
          axios
            .put(`/api/property/${match.params.propertyId}`, updatedProperty)
            .then((/* response */) => {
              history.push({
                pathname: '/media-requests',
                state: { message: 'submit' },
              });
            });
        } else if (match.path === editPropertyRoute || match.path === wantedEditPropertyInfoRoute) {
          const previousStatus = property.status;
          axios.get(`/api/property/${match.params.propertyId}`).then((response) => {
            if (previousStatus !== response.data.property.status) {
              window.alert(
                'The information displayed on the page is outdated, please reload to view the latest changes.'
              );
              this.setState({ loading: false });
            } else if (phones && phones.length >= 1) {
              if (phones.length === 1) {
                if (phones[0].phone) {
                  if (phones[0].phone.length > 10 && property.phone.length > 10) {
                    axios
                      .put(`/api/property/${match.params.propertyId}`, updatedProperty)
                      .then((/* response */) => {
                        this.setPropSureId(propsureId, match.params.propertyId);
                        history.push({
                          pathname: propertiesRoute,
                          state: { message: 'submit' },
                        });
                      });
                  } else {
                    this.setState({ loading: false });
                    this.setState({ editPropertyPhoneValid: false }, () => {
                      window.scrollTo(0, this.additionalNumberRef.offsetTop);
                    });
                  }
                } else {
                  this.setState({ loading: false });
                  this.setState({ editPropertyPhoneValid: false }, () => {
                    window.scrollTo(0, this.additionalNumberRef.offsetTop);
                  });
                }
              } else if (phones.length === 2) {
                if (phones[0].phone && phones[1].phone) {
                  if (
                    phones[0].phone.length > 10 &&
                    phones[1].phone.length > 10 &&
                    property.phone.length > 10
                  ) {
                    axios
                      .put(`/api/property/${match.params.propertyId}`, updatedProperty)
                      .then((/* response */) => {
                        this.setPropSureId(propsureId, match.params.propertyId);
                        history.push({
                          pathname: propertiesRoute,
                          state: { message: 'submit' },
                        });
                      });
                  } else {
                    this.setState({ loading: false });
                    this.setState({ editPropertyPhoneValid: false }, () => {
                      window.scrollTo(0, this.additionalNumberRef.offsetTop);
                    });
                  }
                } else {
                  this.setState({ loading: false });
                  this.setState({ editPropertyPhoneValid: false }, () => {
                    window.scrollTo(0, this.additionalNumberRef.offsetTop);
                  });
                }
              }
            } else if (property.phone && property.phone.length > 10) {
              axios
                .put(`/api/property/${match.params.propertyId}`, updatedProperty)
                .then((/* response */) => {
                  this.setPropSureId(propsureId, match.params.propertyId);
                  history.push({
                    pathname:
                      match.path === wantedEditPropertyInfoRoute ? wantedRoute : propertiesRoute,
                    state: { message: 'submit' },
                  });
                });
            } else {
              this.setState({ loading: false });
              this.setState({ editPropertyPhoneValid: false }, () => {
                window.scrollTo(0, this.additionalNumberRef.offsetTop);
              });
            }
          });
        }
      }
    }
    this.setState({ isDataChanged: false });
  }

  updateCataloguer(id) {
    this.setState(
      {
        wantedProperty: {
          readyForCataloguer: true,
        },
      },
      () => {
        const { wantedProperty } = this.state;
        axios.put(`/api/property/${id}`, wantedProperty).then(() => {
          window.alert('Property assigned to cataloguer');
        });
      }
    );
  }

  checkArea() {
    const { property, isLoadingExternally, areas, changeArea } = this.state;
    if (
      property.area &&
      Object.prototype.hasOwnProperty.call(property.area, 'active') &&
      !property.area.active &&
      !changeArea
    ) {
      return <input type="text" value={property.area.name} className="form-control" disabled />;
    }
    return (
      <Select
        style={{ borderRadius: 5 }}
        placeholder="Select area..."
        name="area"
        isLoading={isLoadingExternally}
        value={property.area_id}
        onChange={(value) => this.setArea(value)}
        options={areas}
        valueKey="id"
        labelKey="name"
        clearable={false}
        required
      />
    );
  }

  renderGeneralFeatrues() {
    const { property } = this.state;
    const { type } = property;
    const { bed, bath, gasMeters, electricMeters } = this.state;
    if (!type || !PROPERTY_FEATURES[type] || !PROPERTY_FEATURES[type].general) {
      return null;
    }
    const renderFeatures = [];
    let rentalFeatures = [];
    let option;
    let renderIndex;
    let featureLabel;
    let key;
    Object.keys(PROPERTY_FEATURES[type].general).forEach((feature, index, array) => {
      if (feature === 'year_built') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Year Built</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="year_built"
                className="form-select block custom-select"
                value={property.built_year ? property.built_year : this.selectedFeatures.year_built}
                onChange={(event) => this.setYearBuilt(event.target.name, event.target.value)}
              >
                <option value="">Year Built</option>
                {new Array(100).fill(undefined).map((val, id) => (
                  <option key={`${moment().year() - id}`} value={moment().year() - id}>
                    {moment().year() - id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else if (feature === 'electric') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Electric Meters</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="electric"
                className="form-select custom-select"
                value={electricMeters}
                onChange={(event) => this.setFeature(event.target.name, event.target.value)}
              >
                <option value="">Any</option>
                {new Array(30).fill(undefined).map((val, id) => (
                  <option key={`electricoption ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else if (feature === 'gas') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Gas Meters</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="gas"
                className="form-select custom-select"
                value={gasMeters}
                onChange={(event) => this.setFeature(event.target.name, event.target.value)}
              >
                <option value="">Any</option>
                {new Array(30).fill(undefined).map((val, id) => (
                  <option key={`gasoption ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else if (feature === 'bed') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Bed</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="bed"
                className="form-select custom-select"
                value={bed}
                onChange={(event) => this.setFeature(event.target.name, event.target.value)}
              >
                <option value="">Any</option>
                {new Array(30).fill(undefined).map((val, id) => (
                  <option key={`bedoption ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else if (feature === 'bath') {
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Bath</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name="bath"
                className="form-select custom-select"
                value={bath}
                onChange={(event) => this.setFeature(event.target.name, event.target.value)}
              >
                <option value="">Any</option>
                {new Array(30).fill(undefined).map((val, id) => (
                  <option key={`bathoption ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      } else {
        featureLabel = PROPERTY_FEATURES[type].general[feature].label;
        key = PROPERTY_FEATURES[type].general[feature].id;
        option = (
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">{featureLabel}</label>
            <div className="col-md-6 col-sm-6" key={feature}>
              <select
                name={key}
                className="form-select custom-select"
                value={this.selectedFeatures[key]}
                onChange={(event) => this.setFeature(event.target.name, event.target.value)}
              >
                <option value="">{featureLabel}</option>
                {new Array(10).fill(undefined).map((val, id) => (
                  <option key={`feature ${id + 1}`} value={1 + id}>
                    {1 + id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      }
      renderIndex = array.length / 2 > index ? 0 : 1;
      if (renderFeatures[renderIndex]) {
        renderFeatures[renderIndex].push(option);
      } else {
        renderFeatures[renderIndex] = [option];
      }
    });
    if (property.purpose === 'rent') {
      rentalFeatures = PROPERTY_FEATURES.rentalOptions.map((item, index) => (
        <div className="form-group row" key={`feature ${index + 1}`}>
          <label className="control-label col-md-3 col-sm-3">{item.label}</label>
          <div
            className={`col-md-6 col-sm-6 ${
              item.label === securityDeposit ||
              item.label === 'Downpayment' ||
              item.label === maintinanaceCharges
                ? 'price-label-container'
                : ''
            }`}
          >
            <input
              className="form-control"
              type="number"
              name={item.name}
              display="price"
              placeholder="Price in (PKR)"
              value={this.selectedFeatures[item.name]}
              onChange={(event) => this.setFeature(event.target.name, event.target.value)}
              onWheel={(e) => e.target.blur()}
              min={1}
            />
            {(item.label === securityDeposit ||
              item.label === 'Downpayment' ||
              item.label === maintinanaceCharges) &&
            this.selectedFeatures[item.name] >= 1000 ? (
              <span className="price-label">
                {convertPrice(this.selectedFeatures[item.name], 'PKR')}
              </span>
            ) : null}
          </div>
        </div>
      ));
    }

    return (
      <div>
        {renderFeatures[0]}
        <div>{renderFeatures[1]}</div>
        <div>{rentalFeatures}</div>
      </div>
    );
  }

  renderWantedFeatures() {
    const { property, statsLead, cmLead } = this.state;
    const { type } = property;
    const { bed, bath, bedMax, bathMax } = this.state;
    if (type === 'house') {
      return null;
    }
    if (!type || !PROPERTY_FEATURES[type] || !PROPERTY_FEATURES[type].general) {
      return null;
    }
    let renderBed;
    let renderBath;
    Object.keys(PROPERTY_FEATURES[type].general).forEach((feature) => {
      if (feature === 'bed') {
        renderBed = (
          <div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Min Beds</label>
              <div className={`${statsLead && !cmLead ? col3 : col2}`} key={feature}>
                <select
                  name="bed"
                  className="form-select custom-select"
                  value={bed}
                  onChange={(event) => this.setFeature(event.target.name, event.target.value)}
                  style={{ backgroundColor: '#fff' }}
                >
                  <option value={null}>Any</option>
                  {new Array(30).fill(undefined).map((val, id) => (
                    <option key={`bedoption ${id + 1}`} value={1 + id}>
                      {1 + id}
                    </option>
                  ))}
                </select>
              </div>
              <label className="control-label col-md-2 col-sm-2">Max Beds</label>
              <div className={`${statsLead && !cmLead ? col3 : col2}`} key={feature}>
                <select
                  name="bed_max"
                  className="form-select custom-select"
                  value={bedMax}
                  onChange={(event) => this.setFeature(event.target.name, event.target.value)}
                  style={{ backgroundColor: '#fff' }}
                >
                  <option value={null}>Any</option>
                  {new Array(30).fill(undefined).map((val, id) => (
                    <option key={`bedoption ${id + 1}`} value={1 + id}>
                      {1 + id}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      } else if (feature === 'bath') {
        renderBath = (
          <div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Min Baths</label>
              <div className={`${statsLead && !cmLead ? col3 : col2}`} key={feature}>
                <select
                  name="bath"
                  className="form-select custom-select"
                  value={bath}
                  onChange={(event) => this.setFeature(event.target.name, event.target.value)}
                  style={{ backgroundColor: '#fff' }}
                >
                  <option value={null}>Any</option>
                  {new Array(30).fill(undefined).map((val, id) => (
                    <option key={`bathoption ${id + 1}`} value={1 + id}>
                      {1 + id}
                    </option>
                  ))}
                </select>
              </div>
              <label className="control-label col-md-2 col-sm-2">Max Baths</label>
              <div className={`${statsLead && !cmLead ? col3 : col2}`} key={feature}>
                <select
                  name="bath_max"
                  className="form-select custom-select"
                  value={bathMax}
                  onChange={(event) => this.setFeature(event.target.name, event.target.value)}
                  style={{ backgroundColor: '#fff' }}
                >
                  <option value={null}>Any</option>
                  {new Array(30).fill(undefined).map((val, id) => (
                    <option key={`bathoption ${id + 1}`} value={1 + id}>
                      {1 + id}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      }
    });

    return (
      <div>
        {renderBed}
        <div>{renderBath}</div>
      </div>
    );
  }

  renderSellWantedFeatures() {
    const { property } = this.state;
    const { type } = property;
    const { bed, bath } = this.state;
    if (!type || !PROPERTY_FEATURES[type] || !PROPERTY_FEATURES[type].general) {
      return null;
    }
    const renderFeatures = [];
    let option;
    let renderIndex;
    Object.keys(PROPERTY_FEATURES[type].general).forEach((feature, index, array) => {
      if (feature === 'bed') {
        option = (
          <div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Bed</label>
              <div className="col-md-6 col-sm-6" key={feature}>
                <select
                  name="bed"
                  className="form-select custom-select"
                  value={bed}
                  onChange={(event) => this.setFeature(event.target.name, event.target.value)}
                >
                  <option value="">Any</option>
                  {new Array(30).fill(undefined).map((val, id) => (
                    <option key={`bedoption ${id + 1}`} value={1 + id}>
                      {1 + id}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      } else if (feature === 'bath') {
        option = (
          <div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Bath</label>
              <div className="col-md-6 col-sm-6" key={feature}>
                <select
                  name="bath"
                  className="form-select custom-select"
                  value={bath}
                  onChange={(event) => this.setFeature(event.target.name, event.target.value)}
                >
                  <option value="">Any</option>
                  {new Array(30).fill(undefined).map((val, id) => (
                    <option key={`bathoption ${id + 1}`} value={1 + id}>
                      {1 + id}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      }
      renderIndex = array.length / 2 > index ? 0 : 1;
      renderFeatures[renderIndex] = [option];
    });

    return (
      <div>
        {renderFeatures[0]}
        <div>{renderFeatures[1]}</div>
      </div>
    );
  }

  renderAdditionalFeatures() {
    const { property } = this.state;
    const { type } = property;
    if (!type || !PROPERTY_FEATURES[type]) {
      return null;
    }
    const options = [];
    let optionIndex;
    let elem;
    PROPERTY_FEATURES[type].features.options.map((option, index) => {
      elem = (
        <div className="">
          <label key={`feature ${Math.random()} ${index + 1}`}>
            <input
              type="checkbox"
              name={option.replace(/ /g, '_')}
              defaultChecked={this.selectedFeatures[option.replace(/ /g, '_')] || false}
              onChange={(event) => this.setAddFeature(event.target.name, event.target.checked)}
            />{' '}
            {option.replace(/\b\w/g, (l) => l.toUpperCase())}
          </label>
        </div>
      );
      optionIndex = index < 12 ? 0 : 1;
      if (options[optionIndex]) {
        options[optionIndex].push(elem);
      } else {
        options[optionIndex] = [elem];
      }
      return true;
    });
    return (
      <div className="">
        <div className="">
          <h5 className="gray">{PROPERTY_FEATURES[type].features.title}</h5>
          <div className="row">
            <div className="col-sm-6">{options[0]}</div>
            <div className="col-sm-6">{options[1]}</div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h5 className="gray">Utilities</h5>
              {PROPERTY_FEATURES[type].utilities.map((utility, index) => (
                <div className="row">
                  <div className="col-sm-12">
                    <label key={`feature ${Math.random()} ${index + 1}`}>
                      <input
                        type="checkbox"
                        name={utility}
                        defaultChecked={this.selectedFeatures[utility.replace(/ /g, '_')] || false}
                        onChange={(event) =>
                          this.setAddFeature(event.target.name, event.target.checked)
                        }
                      />{' '}
                      {utility.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-sm-12 no-padding">
            <h5 className="gray">Facing</h5>
            <div className="row">
              {[
                'north',
                'north_west',
                'north_east',
                'south',
                'south_west',
                'south_east',
                'east',
                'west',
              ].map((amenity, index) => (
                <div className="col-sm-6">
                  <label key={`feature ${Math.random()} ${index + 1}`}>
                    <input
                      type="checkbox"
                      className="marginFix"
                      defaultChecked={this.selectedFeatures[amenity] || false}
                      onChange={(event) =>
                        this.setAddFeature(event.target.name, event.target.checked)
                      }
                      name={amenity}
                      special={`${amenity.replace(/\b\w/g, (l) => l.toUpperCase())}`}
                    />
                    {amenity
                      .split('_')
                      .join(' ')
                      .split('-')
                      .join(' ')
                      .replace(/\b\w/g, (l) => l.toUpperCase())}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFollowUp() {
    const { followModal, followStatus, followDate, followTime, focusedTemp, timeMaterial } =
      this.state;
    return (
      <Modal size="sm" isOpen={followModal}>
        <ModalHeader
          toggle={() => {
            this.setState({ followModal: false });
          }}
        >
          <div className="grey" style={{ textTransform: 'capitalize' }}>
            Follow Up: {followStatus ? followStatus.replace(/-/g, ' ') : ''}
          </div>
        </ModalHeader>
        <ModalBody>
          <form id="followup-form" onSubmit={this.handleLeadsPending}>
            <div className="modalInput">
              <label style={{ marginRight: 5 }} className="control-label">
                Follow Up Date
              </label>
              <SingleDatePicker
                style={{ display: styleInlineBlock }}
                date={followDate}
                onDateChange={(date) => this.setState({ followDate: date })}
                placeholder="Select Date..."
                focused={focusedTemp}
                onFocusChange={({ focused }) => this.setState({ focusedTemp: focused })}
                id="date_input"
                numberOfMonths={1}
                required={!!followTime}
              />
            </div>
            <div className="modalInput">
              <label style={{ marginRight: 5 }} className="control-label">
                Follow Up Time
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  value={timeMaterial}
                  onChange={(val) =>
                    this.setState({ followTime: moment(val).format('HH:mm'), timeMaterial: val })
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
            <Button
              style={{ position: 'relative', left: '200px' }}
              className="btn btn-primary"
              color="primary"
            >
              Save
            </Button>
          </form>
        </ModalBody>
      </Modal>
    );
  }

  renderCreateUser() {
    const { userModal, user, leadUserEmail, userPhoneCheck, loading, userPhone } = this.state;
    return (
      <Modal size="md" isOpen={userModal}>
        <ModalHeader
          toggle={() => {
            this.setState({ userModal: false });
          }}
        >
          <div className="gray">Sign Up</div>
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={(event) => {
              this.createUser(event);
            }}
          >
            <div>
              <input
                className="modalInput"
                type="text"
                name="first_name"
                placeholder="First name*"
                required
                onChange={this.handleUserInput}
                pattern="[a-zA-Z][a-zA-Z ]*"
                title="This field cannot start with an empty space or include a number."
                value={user.first_name}
              />
              <input
                className="modalInput"
                type="text"
                name="last_name"
                placeholder="Last name*"
                required
                onChange={this.handleUserInput}
                pattern="[a-zA-Z][a-zA-Z ]*"
                title="This field cannot start with an empty space or include a number."
                value={user.last_name}
              />
              <input
                className="modalInput"
                type="email"
                name="email"
                placeholder="Email Address*"
                value={leadUserEmail}
                required
                onChange={this.handleUserInput}
                autoComplete="off"
              />
              <input
                className="modalInput"
                type="password"
                name="password"
                placeholder="Password*"
                required
                onChange={this.handleUserInput}
                autoComplete="new-password"
              />
              {userPhoneCheck ? (
                <PhoneInput
                  name="phone"
                  country="pk"
                  inputStyle={{ width: '100%' }}
                  enableLongNumbers
                  onChange={(phone) => this.handleUserPhone(phone)}
                  required
                  value={userPhone}
                />
              ) : (
                <input
                  name="phone"
                  className="modalInput"
                  required
                  value={userPhone}
                  onChange={(value) => this.handleUserPhone(value)}
                />
              )}
              <h5 style={{ marginTop: '10px' }}>Birthday</h5>
              <div className="row space-2">
                <div className="col-5">
                  <select
                    name="month"
                    className="form-select custom-select"
                    onChange={this.handleUserInput}
                    required
                  >
                    <option value="this.state.user.month">Month</option>
                    {[
                      'January',
                      'February',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'August',
                      'September',
                      'October',
                      'November',
                      'December',
                    ].map((month, index) => (
                      <option key={month} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3 no-padding">
                  <select
                    name="day"
                    className="form-select custom-select"
                    onChange={this.handleUserInput}
                    required
                  >
                    <option value="user.day">Day</option>
                    {PropertyForm.getArray(31).map((val, index) => (
                      <option key={val} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-4">
                  <select
                    name="year"
                    className="form-select custom-select"
                    onChange={this.handleUserInput}
                    required
                  >
                    <option value="this.state.user.year">Year</option>
                    {PropertyForm.getArray(100).map((val, index) => (
                      <option key={val} value={2016 - index}>
                        {2016 - index}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <label>
                <input type="checkbox" /> I’d like to receive coupons, promotions, surveys, and
                updates via email about Graana and its partners.
              </label>
              <Button
                className={`btn btn-block btn-xlg btn-danger space-1 ${loading ? 'disabled' : ''}`}
              >
                <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} /> Sign up
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }

  renderComments() {
    const { modalVisible, wantedComments, message, property } = this.state;
    return (
      <Modal size="md" isOpen={modalVisible}>
        <ModalHeader
          toggle={() =>
            this.setState({
              modalVisible: false,
            })
          }
        >
          <div className="grey">Comment History</div>
        </ModalHeader>
        <ModalBody>
          <div className="x_panel">
            {wantedComments && wantedComments.length < 1 ? (
              <div style={{ position: 'absolute', top: '43%', left: '26%' }}>
                <h4 style={{ color: '#D3D3D3' }}>No comments to show</h4>
              </div>
            ) : null}
            <div className="custom-scroll commentsMainWrap">
              {wantedComments
                ? wantedComments.map((commentItem, index) => (
                    <div key={`${index + 1}`} className="commentHistoryWrap">
                      <p>
                        {commentItem.comment}
                        <span
                          style={{ textTransform: 'capitalize', color: '#26a59a' }}
                          className="commentdate"
                        >
                          {commentItem.system_user
                            ? `${commentItem.system_user.first_name} ${commentItem.system_user.last_name}`
                            : ''}
                        </span>
                        <span className="commentdate">
                          {moment(commentItem.createdAt).format('LLL')}
                        </span>
                      </p>
                    </div>
                  ))
                : null}
              <div
                style={{ float: 'left', clear: 'both' }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
              />
            </div>
            <div className="commentInputMain">
              <input
                type="text"
                name="comment"
                display="Comment"
                placeholder="Press Enter to Comment"
                required
                pattern="\s*\S+.*"
                title="This field cannot start with an empty space."
                id="inputTitle"
                ref={(el) => {
                  this.inputTitle = el;
                }}
                className="form-control"
                onChange={(event) => {
                  this.setState({ message: event.target.value });
                }}
                onKeyUp={(event) => {
                  event.preventDefault();
                  if (event.key === 'Enter' && !(message === '')) {
                    this.submitComment(property.id);
                  }
                }}
              />
              {message === '' ? null : (
                <button
                  aria-label="save"
                  type="submit"
                  className="fa fa-paper-plane-o commentBtn"
                  onClick={() => {
                    this.submitComment(property.id);
                  }}
                />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderMatchButton() {
    const { property } = this.state;
    return (
      property.status === 'pending' &&
      property.reason &&
      property.substatus !== 'good' &&
      property.purpose !== 'sell' &&
      property.purpose !== 'rentout' &&
      property.purpose !== 'rent out' && (
        <button type="submit" name="match" className="dropdown-item" style={{ border: 'none' }}>
          Match Properties
        </button>
      )
    );
  }

  renderAssignButton() {
    const { property, cmLead } = this.state;
    const { match } = this.props;
    let isAddLead;
    if (
      match.path === wantedAddLeadRoute ||
      match.path === voiceAddLeadRoute ||
      match.path === wantedProjectAddLeadRoute
    ) {
      isAddLead = true;
    }
    return (
      !cmLead &&
      !isAddLead &&
      property.substatus !== 'good' &&
      (((property.purpose === 'sell' ||
        property.purpose === 'rentout' ||
        property.purpose === 'rent out') &&
        property.properties &&
        property.properties.length) ||
        property.purpose === 'sale' ||
        property.purpose === 'buy' ||
        property.purpose === 'rent') && (
        <button type="submit" name="assign" className="dropdown-item" style={{ border: 'none' }}>
          Assign
        </button>
      )
    );
  }

  renderEditButton() {
    const { property } = this.state;
    const { match } = this.props;
    return (property.purpose === 'sell' ||
      property.purpose === 'rentout' ||
      property.purpose === 'rent out') &&
      property.properties &&
      property.properties.length &&
      match.path !== wantedAddLeadRoute &&
      match.path !== voiceAddLeadRoute ? (
      <Link
        style={{ textDecoration: 'none' }}
        to={`/cataloging/edit-property/${
          property.properties && property.properties.map((savedProperty) => savedProperty.id)
        }`}
      >
        <button
          type="button"
          name="editProperty"
          className="dropdown-item"
          style={{ border: 'none' }}
        >
          Edit Property
        </button>
      </Link>
    ) : null;
  }

  // renderCataloguerButton() {
  //   const { property, wantedProperty } = this.state;
  //   const { match } = this.props;
  //   return (property.purpose === 'sell' ||
  //     property.purpose === 'rentout' ||
  //     property.purpose === 'rent out') &&
  //     property.properties &&
  //     property.properties.length &&
  //     match.path !== wantedAddLeadRoute &&
  //     match.path !== voiceAddLeadRoute &&
  //     property.properties[0] &&
  //     !property.properties[0].readyForCataloguer &&
  //     !wantedProperty.readyForCataloguer &&
  //     !property.properties[0].readyForCataloguer &&
  //     property.properties[0].cataloguerCheck === 1 ? (
  //     <button
  //       type="button"
  //       name="cataloguerProperty"
  //       className="dropdown-item"
  //       onClick={() =>
  //         this.updateCataloguer(
  //           property.properties.map((wantedPropertyCatalog) => wantedPropertyCatalog.id)
  //         )
  //       }
  //       style={{ border: 'none' }}
  //     >
  //       Ready for Cataloguer
  //     </button>
  //   ) : null;
  // }

  renderUserButton() {
    const { property } = this.state;
    const { match } = this.props;
    let isWantedProperty;
    if (
      match.path === wantedEditPropertyRoute ||
      match.path === investEditPropertyRoute ||
      match.path === wantedProjectEditPropertyRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      isWantedProperty = true;
    }
    return (
      !property.user_id &&
      isWantedProperty && (
        <button
          type="button"
          name="createUser"
          className="dropdown-item"
          onClick={() =>
            this.setState({
              userModal: true,
              actionDropdown: false,
              leadUserEmail: property.email,
            })
          }
          style={{ border: 'none' }}
        >
          Create User
        </button>
      )
    );
  }

  renderActionButtons() {
    const { property, actionDropdown, loading, cmLead } = this.state;
    const { match } = this.props;
    return match.path === rwrEditPropertyRoute ||
      match.path === voiceEditLeadRoute ||
      match.path === wantedAddLeadRoute ||
      match.path === voiceAddLeadRoute ? (
      <div className="form-group row">
        <div className="col-md-8 col-sm-8 offset-md-3">
          <Button className={`btn btn-success btn-lg ${loading && 'disabled'}`}>
            <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} />
            Save
          </Button>
        </div>
      </div>
    ) : (
      <div className="form-group row">
        <div className="col-md-8 col-sm-8 offset-md-3">
          <Dropdown
            isOpen={actionDropdown}
            style={{
              marginRight: 5,
              marginBottom: 5,
              display: styleInlineFlex,
            }}
            toggle={() =>
              this.setState((prevState) => ({
                actionDropdown: !prevState.actionDropdown,
              }))
            }
          >
            <DropdownToggle caret size="lg" color="primary">
              Action
              <i className={`fa fa-spinner fa-pulse ${!loading && 'd-none'}`} />{' '}
            </DropdownToggle>
            <DropdownMenu top>
              {(property.purpose === 'sell' ||
                property.purpose === 'rentout' ||
                property.purpose === 'rent out') &&
              ((property.properties && property.properties.length <= 0) ||
                match.path === voiceAddLeadRoute ||
                (match.path === voiceEditLeadRoute &&
                  property.properties &&
                  property.properties.length >= 0)) ? null : (
                <button
                  type="submit"
                  name="update"
                  className="dropdown-item"
                  style={{ border: 'none' }}
                >
                  Save
                </button>
              )}
              {this.getBlacklistOption(property)}
              {this.renderMatchButton()}
              {this.renderAssignButton()}
              {this.renderEditButton()}
              {/* {this.renderCataloguerButton()} */}
              {this.renderUserButton()}
            </DropdownMenu>
          </Dropdown>
          {(match.path === wantedEditPropertyRoute ||
            match.path === investEditPropertyRoute ||
            match.path === wantedProjectEditPropertyRoute ||
            match.path === rwrEditPropertyRoute) && (
            <div style={{ display: styleInlineBlock }}>
              {property.substatus !== 'good' && !cmLead && (
                <UncontrolledDropdown
                  style={{
                    display: styleInlineFlex,
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <DropdownToggle caret size="lg" color="warning">
                    Follow Up
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() =>
                        this.setState({
                          followModal: true,
                          followStatus: 'call-back',
                        })
                      }
                      style={{ border: 'none' }}
                    >
                      Call back
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.setState({
                          followModal: true,
                          followStatus: 'cif-completed',
                        })
                      }
                      style={{ border: 'none' }}
                    >
                      CIF completed
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.setState({
                          followModal: true,
                          followStatus: 'not-answering',
                        })
                      }
                      style={{ border: 'none' }}
                    >
                      Not answering
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.setState({
                          followModal: true,
                          followStatus: 'powered-off',
                        })
                      }
                      style={{ border: 'none' }}
                    >
                      Powered off
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.setState({
                          followModal: true,
                          followStatus: 'overseas',
                        })
                      }
                      style={{ border: 'none' }}
                    >
                      Overseas
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.setState({
                          followModal: true,
                          followStatus: 'test-lead',
                        })
                      }
                      style={{ border: 'none' }}
                    >
                      Test Lead
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.setState({
                          followModal: true,
                          followStatus: 'other',
                        })
                      }
                      style={{ border: 'none' }}
                    >
                      Other
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {property.substatus !== 'good' && !cmLead && (
                <UncontrolledDropdown
                  style={{
                    display: styleInlineFlex,
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <DropdownToggle caret size="lg" color="danger">
                    Reject
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => this.handleReject('irrelevant-query')}
                      style={{ border: 'none' }}
                    >
                      Irrelevant Query
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleReject('invalid-no')}
                      style={{ border: 'none' }}
                    >
                      Invalid No.
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleReject('duplicate')}
                      style={{ border: 'none' }}
                    >
                      Duplicate
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderGeotagButtons() {
    const { isManualGeoTag } = this.state;
    return (
      <div className="form-group row">
        <div className="col-md-6 col-sm-6 text-end" style={{ paddingRight: 0, paddingBottom: 5 }}>
          <button
            type="button"
            className={`btn btn-outline-primary ${!isManualGeoTag ? 'active' : ''}`}
            onClick={() => this.handleManualGeotagging(false, 'geoTag')}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            PropSure Geotagging
          </button>
        </div>
        <div className="col-md-6 col-sm-6" style={{ paddingLeft: 0, paddingBottom: 5 }}>
          <button
            type="button"
            className={`btn btn-outline-primary ${isManualGeoTag ? 'active' : ''}`}
            onClick={() => this.handleManualGeotagging(true, 'manual')}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            Manual Geotagging
          </button>
        </div>
      </div>
    );
  }

  renderCancelButton() {
    return (
      <div className="col-md-3">
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={() =>
            this.setState(() => ({
              changePlot: false,
              isManualGeoTag: false,
              chosenMapCity: '',
              chosenHousingScheme: '',
              chosenPhaseSector: '',
              chosenBlockSubsector: '',
              chosenMapPlot: '',
              housingSchemes: '',
              phaseSector: '',
              blockSubsectors: '',
              filteredPlots: '',
              mapPlots: '',
              streets: '',
              chosenStreet: '',
            }))
          }
        >
          Cancel
        </button>
      </div>
    );
  }

  renderLead() {
    let { isWantedProperty, isAddLead } = this.state;
    let wantedPropertyAreas = '';
    const {
      property,
      statsLead,
      cmLead,
      isLoadingExternally,
      multiAreaValues,
      areas,
      removeKey,
      setCountryCode,
      editRCMPhoneValid,
      editPropertyPhoneValid,
      extraNumber,
      wantedNewProperty,
      // wantedSaved,
      validRCMResponse,
      // leadPropertyPayload,
    } = this.state;
    const {
      // history,
      match,
    } = this.props;
    // console.log('resp', wantedNewProperty);
    const { type } = property;
    let sellRentoutProperty = false;
    if (
      property.purpose === 'sell' ||
      property.purpose === 'rentout' ||
      property.purpose === 'rent out'
    ) {
      sellRentoutProperty = true;
    }
    if (
      match.path === wantedAddLeadRoute ||
      match.path === voiceAddLeadRoute ||
      match.path === wantedProjectAddLeadRoute
    ) {
      isWantedProperty = true;
      isAddLead = true;
    }
    if (
      match.path === wantedEditPropertyRoute ||
      match.path === investEditPropertyRoute ||
      match.path === voiceEditLeadRoute ||
      match.path === wantedProjectEditPropertyRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      isWantedProperty = true;
    }
    if (isWantedProperty && property && property.wanted_areas) {
      property.wanted_areas.map((area) => {
        wantedPropertyAreas = wantedPropertyAreas.concat(area.area?.name, ', ');
        return area;
      });
    }
    if (wantedPropertyAreas) {
      wantedPropertyAreas = wantedPropertyAreas.slice(0, -2);
    }
    if (!isWantedProperty) {
      property.type = property.type.toLowerCase();
    }
    return (
      <div className="x_content">
        <br />
        <form
          id="demo-form2"
          className="form-horizontal form-label-left"
          method="post"
          onSubmit={this.updateProperty}
        >
          {isWantedProperty ? (
            <div>
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Client Name*</label>
                <div className={`${statsLead && !cmLead ? col8 : col6}`}>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={property ? property.name : null}
                    onChange={this.handleInputChangeLead}
                    required
                    pattern="\s*\S+.*"
                    title="This field cannot start with an empty space."
                    style={{ backgroundColor: '#fff' }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {isWantedProperty ? (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Purpose*</label>
              <div className={`${statsLead && !cmLead ? col8 : col6}`}>
                <select
                  name="purpose"
                  value={property.purpose}
                  className="form-select custom-select"
                  onChange={this.handleInputChange}
                  required
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => this.setState({ oldPurpose: '' })}
                >
                  <option value="">Select Purpose</option>
                  <option value="buy">Buy</option>
                  <option value="rent">Rent</option>
                  <option value="sell">Sell</option>
                  <option value="rentout">Rent Out</option>
                  {isAddLead ? <option value="invest">Invest</option> : null}
                </select>
              </div>
            </div>
          ) : null}
          {isWantedProperty ? (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Property Type*</label>
              <div className={`${statsLead && !cmLead ? col3 : col2}`}>
                <select
                  name="type"
                  value={property.type}
                  className="form-select custom-select"
                  onChange={this.handleInputChange}
                  required
                  style={{ backgroundColor: '#fff' }}
                >
                  <option value="">Select type</option>
                  <option value="residential">Residential</option>
                  <option value="plot">Plot</option>
                  <option value="commercial">Commercial</option>
                </select>
              </div>
              <label className="control-label col-md-2 col-sm-2">Subtype*</label>
              <div className={`${statsLead && !cmLead ? col3 : col2}`}>
                <select
                  style={{ textTransform: 'capitalize', backgroundColor: '#fff' }}
                  value={property.subtype}
                  name="subtype"
                  className="form-select custom-select"
                  onChange={this.handleInputChange}
                >
                  {PROPERTY_SUBTYPES[property.type]
                    ? PROPERTY_SUBTYPES[property.type].map((subtype) => (
                        <option key={subtype.name} value={subtype.value}>
                          {subtype.name}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>
          ) : null}
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">City*</label>
            <div className={`${statsLead && !cmLead ? col8 : col6}`} style={{ zIndex: '2' }}>
              <Select.Async
                style={{ borderRadius: 5, backgroundColor: '#fff' }}
                name="city"
                multi={false}
                value={property.city_id}
                onChange={(value) => this.setCity(value)}
                loadOptions={(input) =>
                  isWantedProperty
                    ? PropertyForm.getWantedCity(input)
                    : PropertyForm.getAllCities(input)
                }
                valueKey="id"
                labelKey="name"
                clearable={false}
                required
              />
            </div>
          </div>
          {isWantedProperty ? (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">
                {!sellRentoutProperty ? 'Areas*' : 'Area*'}
              </label>
              <div className={`${statsLead && !cmLead ? col8 : col6}`} style={{ zIndex: '1' }}>
                <Select
                  style={{ borderRadius: 5, backgroundColor: '#fff' }}
                  name="area"
                  isLoading={isLoadingExternally}
                  multi={
                    !!(
                      property.purpose !== 'sell' &&
                      property.purpose !== 'rentout' &&
                      property.purpose !== 'rent out'
                    )
                  }
                  value={
                    property.purpose !== 'sell' &&
                    property.purpose !== 'rentout' &&
                    property.purpose !== 'rent out'
                      ? multiAreaValues
                      : multiAreaValues[0]
                  }
                  onChange={(value) =>
                    property.purpose !== 'sell' &&
                    property.purpose !== 'rentout' &&
                    property.purpose !== 'rent out'
                      ? this.setArea(value)
                      : this.setSellArea(value)
                  }
                  options={areas}
                  valueKey="id"
                  labelKey="name"
                  clearable={false}
                  required
                />
              </div>
            </div>
          ) : null}
          {property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out' && (
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Size Unit*</label>
                <div className={`${statsLead && !cmLead ? col8 : col6}`}>
                  <select
                    name="size_unit"
                    className="form-select custom-select"
                    value={property.size_unit}
                    onChange={this.handleInputChange}
                    style={{ backgroundColor: '#fff' }}
                  >
                    <option value="sqft">Square Feet</option>
                    <option value="sqyd">Square Yard</option>
                    <option value="sqm">Square Meter</option>
                    <option value="kanal">Kanal</option>
                    <option value="marla">Marla</option>
                    <option value="acre">Acre</option>
                  </select>
                </div>
              </div>
            )}
          {isWantedProperty &&
          property.purpose !== 'sell' &&
          property.purpose !== 'rentout' &&
          property.purpose !== 'rent out' ? (
            <div>
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Min Size</label>
                <div className={`${statsLead && !cmLead ? col3 : col2}`}>
                  <select
                    style={{ textTransform: 'capitalize', backgroundColor: '#fff' }}
                    value={property.size}
                    name="size"
                    className="form-select custom-select"
                    onChange={this.handleInputChange}
                  >
                    {SIZES[property.size_unit]
                      ? SIZES[property.size_unit].min.map((sizes) => (
                          <option key={sizes} value={sizes}>
                            {sizes}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <label className="control-label col-md-2 col-sm-2">Max Size</label>
                <div className={`${statsLead && !cmLead ? col3 : col2}`}>
                  <select
                    style={{ textTransform: 'capitalize', backgroundColor: '#fff' }}
                    value={property.max_size}
                    name="max_size"
                    className="form-select custom-select"
                    onChange={this.handleInputChange}
                  >
                    {SIZES[property.size_unit]
                      ? SIZES[property.size_unit].max.map((sizes) => (
                          <option key={sizes} value={sizes}>
                            {sizes}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
          ) : null}
          {isWantedProperty &&
            property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out' && (
              <div>
                <div className="form-group row">
                  <label className="control-label col-md-3 col-sm-3">Min Price</label>
                  <div className={`${statsLead && !cmLead ? col3 : col2} price-label-container`}>
                    <input
                      type="number"
                      name="min_price"
                      className="form-control custom-select"
                      min={0}
                      value={property.min_price}
                      onChange={this.handleInputChange}
                      onWheel={(e) => e.target.blur()}
                      style={{ backgroundColor: '#fff' }}
                    />
                    {property.min_price >= 1000 ? (
                      <span className="price-label">{convertPrice(property.min_price, 'PKR')}</span>
                    ) : null}
                  </div>
                  <label className="control-label col-md-2 col-sm-2">Max Price</label>
                  <div className={`${statsLead && !cmLead ? col3 : col2} price-label-container`}>
                    <input
                      type="number"
                      name="max_price"
                      className="form-control custom-select"
                      min={0}
                      value={property.max_price}
                      onChange={this.handleInputChange}
                      onWheel={(e) => e.target.blur()}
                      style={{ backgroundColor: '#fff' }}
                    />
                    {property.max_price >= 1000 ? (
                      <span className="price-label">{convertPrice(property.max_price, 'PKR')}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          {type &&
          (isWantedProperty || isAddLead) &&
          property.purpose !== 'sell' &&
          property.purpose !== 'rentout' &&
          property.purpose !== 'rent out'
            ? this.renderWantedFeatures()
            : null}
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Phone Number*</label>
            <div className="col-md-6 col-sm-6" style={{ display: 'inherit' }}>
              {property.phone && setCountryCode ? (
                <PhoneInput
                  name="phone"
                  country="pk"
                  value={property.phone}
                  onChange={(phone, country) => this.handlePhoneProperty(phone, country)}
                  buttonStyle={{ height: '35px' }}
                  enableLongNumbers
                  countryCodeEditable={false}
                  style={{ backgroundColor: '#fff' }}
                />
              ) : (
                <input
                  name="phone"
                  type="text"
                  className="form-control"
                  value={property.phone}
                  onChange={this.handleInputChange}
                  style={{ backgroundColor: '#fff' }}
                />
              )}
            </div>
          </div>
          {!editRCMPhoneValid && (
            <div className="form-group row">
              <div className="col-md-6 col-sm-6 offset-md-3">
                <Alert color="danger">Please enter a valid number</Alert>
              </div>
            </div>
          )}
          <div className="">
            {property.phones &&
              property.phones.map((phone, index) => (
                <div key={`${removeKey} ${index + 1}`} className="form-group row">
                  <label className="control-label col-md-3 col-sm-3">
                    {' '}
                    Additional Phone # {index + 1}
                  </label>
                  <div className="col-md-6 col-sm-6">
                    <PhoneInput
                      name="phone"
                      country="pk"
                      value={phone.phone}
                      onChange={(value, country) =>
                        this.changePhoneNumber(value, index, phone, country)
                      }
                      enableLongNumbers
                      countryCodeEditable={false}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </div>
                  <div className="col-md-3 additionalPhonesA">
                    <button
                      className="btn btn-danger btn-sm additionalPhonesB"
                      type="button"
                      title="remove"
                      onClick={() => this.removePhoneNumber(index, phone)}
                      style={{ width: '71px' }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            {!editPropertyPhoneValid && (
              <div className="form-group row">
                <div
                  ref={(ref) => {
                    this.additionalNumberRef = ref;
                  }}
                  className="col-md-6 col-sm-6 offset-md-3"
                >
                  <Alert color="danger">Please enter valid number</Alert>
                </div>
              </div>
            )}
            {extraNumber && (
              <div className="form-group row">
                <div
                  ref={(ref) => {
                    this.extraNumberRef = ref;
                  }}
                  className="col-md-6 col-sm-6 offset-md-3"
                >
                  <Alert color="danger">Only two additional phone numbers are allowed</Alert>
                </div>
              </div>
            )}
          </div>
          {isWantedProperty && !isAddLead ? (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Client Email</label>
              <label className="col-md-6 col-sm-6" style={{ paddingTop: '8px' }}>
                {property.email}
              </label>
            </div>
          ) : null}
          {isWantedProperty && !isAddLead ? (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Comments by Client</label>
              <lable className="col-md-6 col-sm-6" style={{ paddingTop: '8px' }}>
                {property.comments}
              </lable>
            </div>
          ) : null}
          {isWantedProperty || isAddLead ? (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Notes for Agent</label>
              <div className={`${statsLead && !cmLead ? col8 : col6}`}>
                <textarea
                  type="textarea"
                  name="description"
                  className="form-control"
                  rows="4"
                  value={property.description}
                  onChange={this.handleInputChange}
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
            </div>
          ) : null}
          {isWantedProperty && !isAddLead ? (
            <div className="row space-1">
              <div className="col-sm-12 text-center">
                <button
                  type="button"
                  className="btn btn-md btn-primary"
                  onClick={() => this.showComments()}
                >
                  Comments
                </button>
              </div>
            </div>
          ) : null}
          {isWantedProperty && !isAddLead ? (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Agree to Terms of Service</label>
              <div className="col-md-6 col-sm-6">
                <input
                  name="terms_signed"
                  type="checkbox"
                  checked={property.terms_signed}
                  style={{ marginTop: '12px' }}
                  disabled
                />
              </div>
            </div>
          ) : null}
          {
            // new wanted add/edit property flow
          }
          {wantedNewProperty &&
          wantedNewProperty.length > 0 &&
          (property.purpose === 'sell' ||
            property.purpose === 'rentout' ||
            property.purpose === 'rent out') ? (
            <>
              <div className="clearfix mb-3" style={{ border: '1px solid whitesmoke' }} />
              <div
                className="col-12"
                style={{
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '17.72px',
                  lineHeight: '30px',
                  color: '#37394F',
                }}
              >
                Linked Property
              </div>
              <div className="col-md-6 col-sm-6 offset-md-3">
                <Card
                  className="row"
                  style={{
                    height: '200px',
                    position: 'relative',
                    width: '100%',
                    borderRadius: '8px',
                    cursor: 'default',
                    marginBottom: '18px',
                    boxShadow: ' 0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
                    border: '0px solid #37394F',
                  }}
                >
                  <div className="row mt-3">
                    <div className="col-1">
                      <img
                        src={appImages.wantedProperty}
                        style={{ width: '20px', height: '20px' }}
                        alt="wanted property"
                      />
                    </div>
                    <div
                      className="col-6"
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#37394F',
                      }}
                    >
                      {wantedNewProperty[0]?.custom_title}
                    </div>
                  </div>
                  <button
                    className="btn"
                    type="button"
                    style={{
                      backgroundColor: '#E7ECF0',
                      position: 'absolute',
                      left: '85%',
                      color: '#7E7E7E',
                      top: '4%',
                      width: '55px',
                      fontWeight: 'normal',
                    }}
                    onClick={() => {
                      this.editWantedProperty();
                    }}
                  >
                    Open
                  </button>
                  <div className="row mt-2">
                    <div className="col-1">
                      <img
                        src={appImages.locationIcon}
                        style={{ width: '20px', height: '20px' }}
                        alt="location"
                      />
                    </div>
                    <div
                      className="col-6"
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '11.64px',
                        lineHeight: '20px',
                        color: '#7E7E7E',
                      }}
                    >
                      {wantedNewProperty[0]?.area?.name}, {wantedNewProperty[0]?.city?.name}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-1">
                      <img
                        src={appImages.userIcon}
                        style={{ width: '20px', height: '20px' }}
                        alt="client"
                      />
                    </div>
                    <div
                      className="col-6"
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '11.64px',
                        lineHeight: '20px',
                        color: '#7E7E7E',
                      }}
                    >
                      {wantedNewProperty[0]?.user?.first_name}{' '}
                      {wantedNewProperty[0]?.user?.last_name}
                    </div>
                  </div>
                  <div
                    className="clearfix mb-3"
                    style={{ height: '1px', border: '1px solid whitesmoke' }}
                  />
                  <div className="row">
                    <div
                      className="col-2"
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '11.64px',
                        lineHeight: '20px',
                        color: '#7E7E7E',
                      }}
                    >
                      <img
                        src={appImages.wantedBath}
                        style={{ width: '20px', height: '20px', paddingRight: '6px' }}
                        alt="wanted bath"
                      />
                      {wantedNewProperty[0].bath ? wantedNewProperty[0]?.bath : 'Nill'}
                    </div>
                    <div
                      className="col-2"
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '11.64px',
                        lineHeight: '20px',
                        color: '#7E7E7E',
                      }}
                    >
                      <img
                        src={appImages.wantedBed}
                        style={{ width: '20px', height: '20px', paddingRight: '4px' }}
                        alt="wanted bed"
                      />{' '}
                      {wantedNewProperty[0].bed ? wantedNewProperty[0]?.bed : 'Nill'}
                    </div>
                    <div
                      className="col-3"
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '11.64px',
                        lineHeight: '20px',
                        color: '#7E7E7E',
                      }}
                    >
                      {convertPrice(wantedNewProperty[0]?.price, 'PKR')}
                    </div>
                  </div>
                </Card>
              </div>
            </>
          ) : null}

          {property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out' && <div className="ln_solid" />}
          {property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out' && <div className="ln_solid" />}
          {(isWantedProperty &&
            property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out') ||
          (isWantedProperty &&
            (property.purpose === 'sell' ||
              property.purpose === 'rentout' ||
              property.purpose === 'rent out') &&
            match.path !== voiceEditLeadRoute &&
            property.properties &&
            property.properties.length > 0) ||
          (match.path === voiceEditLeadRoute && property.properties && property.properties < 1) ||
          (match.path === voiceAddLeadRoute &&
            property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out') ||
          match.path === wantedAddLeadRoute
            ? this.renderActionButtons()
            : null}
          {(property.purpose === 'sell' ||
            property.purpose === 'rentout' ||
            property.purpose === 'rent out') &&
            ((property.properties && property.properties.length <= 0) ||
              match.path === voiceAddLeadRoute ||
              (match.path === voiceEditLeadRoute &&
                property.properties &&
                property.properties.length >= 0)) && (
              <div style={{ position: 'relative' }}>
                {/* <AddProperties
                  match={match}
                  history={history}
                  wantedSaved={wantedSaved}
                  leadPropertyPayload={leadPropertyPayload}
                /> */}

                {/* <button
                  type="submit"
                  className="btn"
                  style={{
                    border: '1px solid #37394F',
                    marginLeft: '13%',
                    color: '#37394F',
                    position: 'absolute',
                    top: '2px',
                    height: '42px',
                    width: '120px',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '11.5897px',
                    lineHeight: '17px',
                    letterSpacing: '0.03em',
                  }}
                  onClick={(e) => this.redirectToPropertyForm(e)}
                >
                  ADD PROPERTY
                </button> */}

                {this.renderActionButtons()}
              </div>
            )}
          {validRCMResponse && (
            <div className="form-group row">
              <div
                ref={(r) => {
                  this.addRCMRef = r;
                }}
                className="col-md-6 col-sm-6 offset-md-3"
              >
                <Alert color="success">{isAddLead ? 'RCM lead added' : 'RCM lead edited'}</Alert>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }

  renderAgentDetails() {
    const {
      showUsers,
      property,
      isLoadingExternally,
      selectedAgencyId,
      selectedAgentId,
      agencies,
      isLoadingAgents,
      agents,
      userSearch,
      isLoadingUsers,
      selectedUserId,
      users,
    } = this.state;
    return (
      <div>
        <div className="x_title">
          <h2>Add Agent/User Details</h2>
          <div className="clearfix" />
        </div>
        <form className="form-horizontal form-label-left">
          <div className="form-group row">
            <div className="col-md-1 col-sm-1 offset-md-3" style={{ marginRight: '-57px' }}>
              <input
                type="checkbox"
                name="showUsers"
                checked={showUsers}
                onChange={this.handleUserCheckbox}
                style={{ marginTop: 13 }}
              />
            </div>
            <label className="control-label col-md-3 col-sm-3" style={{ textAlign: 'left' }}>
              Property for Client
            </label>
          </div>
          {showUsers ? (
            <div className="form-group row">
              <div className="col-md-1 col-sm-1 offset-md-3" style={{ marginRight: '-57px' }}>
                <input
                  type="checkbox"
                  name="await_publishing"
                  checked={property.await_publishing}
                  onChange={this.handleUserCheckbox}
                  style={{ marginTop: 13 }}
                />
              </div>
              <label className="control-label col-md-3 col-sm-3" style={{ textAlign: 'left' }}>
                Client asked not to publish
              </label>
            </div>
          ) : null}
          {!showUsers ? (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">City*</label>
              <div className="col-md-6 col-sm-6">
                <Select.Async
                  name="city"
                  multi={false}
                  value={this.getAgency()}
                  onChange={(value) => this.setAgentCity(value)}
                  // loadOptions={(input) => AddProperties.getCity(input)}
                  valueKey="id"
                  labelKey="name"
                  clearable={false}
                  required
                />
              </div>
            </div>
          ) : null}
          <div>
            {property.area && (
              <strong>
                {Object.prototype.hasOwnProperty.call(property.area, 'active') &&
                  !property.area.active && (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">
                        Please Activate the area
                      </label>
                      <div className="col-md-6 col-sm-6" style={{ paddingTop: '8px' }}>
                        <Link to={`/areas/edit-area/${property.area.id}`}>
                          {property.area.name}
                        </Link>
                      </div>
                    </div>
                  )}
              </strong>
            )}
            {!showUsers && (
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Agency</label>
                <div className="col-md-6 col-sm-6">
                  <Select
                    name="agency"
                    isLoading={isLoadingExternally}
                    multi={false}
                    value={selectedAgencyId}
                    onChange={(value) => this.setAgency(value)}
                    options={agencies}
                    valueKey="id"
                    labelKey="info"
                    clearable={false}
                  />
                </div>
              </div>
            )}
            {!showUsers && (
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Agent*</label>
                <div className="col-md-6 col-sm-6">
                  <Select
                    name="agent"
                    isLoading={isLoadingAgents}
                    multi={false}
                    value={selectedAgentId}
                    onChange={(value) => this.setAgents(value)}
                    options={agents}
                    valueKey="id"
                    labelKey="full_name"
                    clearable={false}
                    required
                  />
                </div>
              </div>
            )}
            {showUsers && (
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">User*</label>
                <div className="userSearch">
                  <input
                    // style={{
                    //   width: '50%',
                    //   display: 'inline-flex',
                    //   position: 'relative',
                    //   top: '2px',
                    // }}
                    className="form-control"
                    value={userSearch}
                    onChange={(event) =>
                      this.setState({
                        userSearch: event.target.value ? event.target.value : undefined,
                      })
                    }
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.handleUserSearch();
                      }
                    }}
                    placeholder="Press Enter to search user"
                  />
                  <button
                    aria-label="save"
                    type="submit"
                    className="fa fa-search commentBtn"
                    onClick={() => {
                      this.handleUserSearch();
                    }}
                  />
                </div>
                <div className="col-md-3 col-sm-3">
                  <Select
                    name="user"
                    isLoading={isLoadingUsers}
                    multi={false}
                    value={selectedUserId}
                    onChange={(value) => this.setUsers(value)}
                    options={users}
                    valueKey="id"
                    labelKey="info"
                    clearable={false}
                    required
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }

  renderProperty() {
    let { isWantedProperty, isAddLead } = this.state;
    let wantedPropertyAreas = '';
    const {
      property,
      statsLead,
      cmLead,
      btnChecked,
      changeArea,
      mainLoading,
      isManualGeoTag,
      locateManually,
      changePlot,
      mapZoom,
      chosenMapCity,
      chosenHousingScheme,
      chosenPhaseSector,
      chosenBlockSubsector,
      mapPlots,
      mapCenter,
      plotMarker,
      mapCities,
      loadingHousing,
      housingSchemes,
      loadingPhase,
      phaseSector,
      loadingBlock,
      blockSubsectors,
      streets,
      chosenStreet,
      chosenMapPlot,
      filteredPlots,
      plotsUnavailable,
      mapPlot,
      address,
      removeKey,
      setCountryCode,
      editRCMPhoneValid,
      editPropertyPhoneValid,
      extraNumber,
      sameAsOwner,
      pocPhone,
      description,
      //    wantedSaved,
      loading,
      validRCMResponse,
      isLoadingExternally,
      showUsers,
      selectedAgencyId,
      selectedAgentId,
      agencies,
      isLoadingAgents,
      agents,
      userSearch,
      isLoadingUsers,
      selectedUserId,
      users,
      actionDropdown,
    } = this.state;
    const {
      // history,
      match,
    } = this.props;
    const { type } = property;
    if (
      match.path === wantedAddLeadRoute ||
      match.path === voiceAddLeadRoute ||
      match.path === wantedProjectAddLeadRoute
    ) {
      isWantedProperty = true;
      isAddLead = true;
    }
    if (
      match.path === wantedEditPropertyRoute ||
      match.path === investEditPropertyRoute ||
      match.path === voiceEditLeadRoute ||
      match.path === wantedProjectEditPropertyRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      isWantedProperty = true;
    }
    if (isWantedProperty && property && property.wanted_areas) {
      property.wanted_areas.map((area) => {
        wantedPropertyAreas = wantedPropertyAreas.concat(area.area?.name, ', ');
        return area;
      });
    }
    if (wantedPropertyAreas) {
      wantedPropertyAreas = wantedPropertyAreas.slice(0, -2);
    }
    if (!isWantedProperty) {
      property.type = property.type.toLowerCase();
    }
    return (
      <div className="x_content">
        <br />
        <form
          id="demo-form2"
          className="form-horizontal form-label-left"
          method="post"
          onSubmit={this.updateProperty}
        >
          {(!property.user_id && match.path === editPropertyRoute) ||
          selectedAgentId ||
          (property.wanted && property.wanted.voiceLeadId) ? (
            <div>
              <div className="x_title">
                <h2>Add Agent/User Details</h2>
                <div className="clearfix" />
              </div>
              <div className="form-group row">
                <div className="col-md-1 col-sm-1 offset-md-3" style={{ marginRight: '-57px' }}>
                  <input
                    type="checkbox"
                    name="showUsers"
                    checked={showUsers}
                    onChange={this.handleUserCheckbox}
                    style={{ marginTop: 13 }}
                  />
                </div>
                <label className="control-label col-md-3 col-sm-3" style={{ textAlign: 'left' }}>
                  Property for Client
                </label>
              </div>
              {showUsers ? (
                <div className="form-group row">
                  <div className="col-md-1 col-sm-1 offset-md-3" style={{ marginRight: '-57px' }}>
                    <input
                      type="checkbox"
                      name="await_publishing"
                      checked={property.await_publishing}
                      onChange={this.handleUserCheckbox}
                      style={{ marginTop: 13 }}
                    />
                  </div>
                  <label className="control-label col-md-3 col-sm-3" style={{ textAlign: 'left' }}>
                    Client asked not to publish
                  </label>
                </div>
              ) : null}
              {!showUsers ? (
                <div className="form-group row">
                  <label className="control-label col-md-3 col-sm-3">City*</label>
                  <div className="col-md-6 col-sm-6">
                    <Select.Async
                      name="city"
                      multi={false}
                      value={this.getAgency()}
                      onChange={(value) => this.setAgentCity(value)}
                      // loadOptions={(input) => AddProperties.getCity(input)}
                      valueKey="id"
                      labelKey="name"
                      clearable={false}
                      required
                    />
                  </div>
                </div>
              ) : null}
              <div>
                {property.area && (
                  <strong>
                    {Object.prototype.hasOwnProperty.call(property.area, 'active') &&
                      !property.area.active && (
                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">
                            Please Activate the area
                          </label>
                          <div className="col-md-6 col-sm-6" style={{ paddingTop: '8px' }}>
                            <Link to={`/areas/edit-area/${property.area.id}`}>
                              {property.area.name}
                            </Link>
                          </div>
                        </div>
                      )}
                  </strong>
                )}
                {!showUsers && (
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">Agency</label>
                    <div className="col-md-6 col-sm-6">
                      <Select
                        name="agency"
                        isLoading={isLoadingExternally}
                        multi={false}
                        value={selectedAgencyId}
                        onChange={(value) => this.setAgency(value)}
                        options={agencies}
                        valueKey="id"
                        labelKey="info"
                        clearable={false}
                      />
                    </div>
                  </div>
                )}
                {!showUsers && (
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">Agent*</label>
                    <div className="col-md-6 col-sm-6">
                      <Select
                        name="agent"
                        isLoading={isLoadingAgents}
                        multi={false}
                        value={selectedAgentId}
                        onChange={(value) => this.setAgents(value)}
                        options={agents}
                        valueKey="id"
                        labelKey="full_name"
                        clearable={false}
                        required
                      />
                    </div>
                  </div>
                )}
                {showUsers && (
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">User*</label>
                    <div className="userSearch">
                      <input
                        // style={{
                        //   width: '50%',
                        //   display: 'inline-flex',
                        //   position: 'relative',
                        //   top: '2px',
                        // }}
                        className="form-control"
                        value={userSearch}
                        onChange={(event) =>
                          this.setState({
                            userSearch: event.target.value ? event.target.value : undefined,
                          })
                        }
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            this.handleUserSearch();
                          }
                        }}
                        placeholder="Press Enter to search user"
                      />
                      <button
                        aria-label="save"
                        type="submit"
                        className="fa fa-search commentBtn"
                        onClick={() => {
                          this.handleUserSearch();
                        }}
                      />
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <Select
                        name="user"
                        isLoading={isLoadingUsers}
                        multi={false}
                        value={selectedUserId}
                        onChange={(value) => this.setUsers(value)}
                        options={users}
                        valueKey="id"
                        labelKey="info"
                        clearable={false}
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {match.path === editPropertyRoute || match.path === wantedEditPropertyInfoRoute ? (
            <div className="x_title">
              <h2>Edit Property Details</h2>
              <div className="clearfix" />
            </div>
          ) : null}
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Purpose*</label>
            <div className="col-md-6 col-sm-6">
              <select
                name="purpose"
                value={property.purpose}
                className="form-select custom-select"
                onChange={this.handleInputChange}
              >
                <option disabled selected value>
                  Select purpose
                </option>
                <option value="sale">Sale</option>
                <option value="rent">Rent</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Type*</label>
            <div className="col-md-6 col-sm-6" value={property.type}>
              <input
                name="type"
                type="radio"
                value="residential"
                checked={property.type === 'residential' && (property.subtype || btnChecked)}
                onChange={this.handleType}
                required
              />
              &nbsp;Residential
              <br />
              <input
                name="type"
                type="radio"
                value="plot"
                checked={property.type === 'plot'}
                onChange={this.handleType}
              />
              &nbsp;Plot
              <br />
              <input
                name="type"
                type="radio"
                value="commercial"
                checked={property.type === 'commercial'}
                onChange={this.handleType}
              />
              &nbsp;Commercial
              <br />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Subtype*</label>
            <div className="col-md-6 col-sm-6">
              <select
                style={{ textTransform: 'capitalize' }}
                value={property.subtype}
                name="subtype"
                className="form-select custom-select"
                onChange={this.handleInputChange}
              >
                {PROPERTY_SUBTYPES[property.type]
                  ? PROPERTY_SUBTYPES[property.type].map((subtype) => (
                      <option key={subtype.name} value={subtype.value}>
                        {subtype.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">City*</label>
            <div className={`${statsLead && !cmLead ? col8 : col6}`} style={{ zIndex: '2' }}>
              <Select.Async
                style={{ borderRadius: 5 }}
                name="city"
                multi={false}
                value={property.city_id}
                onChange={(value) => this.setCity(value)}
                loadOptions={(input) =>
                  isWantedProperty
                    ? PropertyForm.getWantedCity(input)
                    : PropertyForm.getAllCities(input)
                }
                valueKey="id"
                labelKey="name"
                clearable={false}
                required
              />
            </div>
          </div>
          <div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Area*</label>
              <div className="col-md-6 col-sm-6" style={{ zIndex: '1' }}>
                {this.checkArea()}
              </div>
              {property.area && !property.area.active && !mainLoading && (
                <div className="col-md-3">
                  <Link to={`/areas/edit-area/${property.area.id}`}>
                    <button type="button" className="btn btn-sm btn-danger">
                      Activate area
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ms-2"
                    onClick={() =>
                      this.setState((prevState) => ({
                        changeArea: !prevState.changeArea,
                        property: {
                          ...prevState.property,
                          area_id: null,
                        },
                      }))
                    }
                  >
                    {changeArea ? 'Cancel' : 'Change area'}
                  </button>
                </div>
              )}
            </div>
            {property.area && !property.area.active && !changeArea && !mainLoading && (
              <div className="form-group row">
                <div className="col-md-6 offset-md-3 text-center">
                  <div className="alert alert-danger" role="alert">
                    Error: Area is inactive. Please activate the area or add a new one.
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Title*</label>
            <div className="col-md-6 col-sm-6">
              <input
                type="text"
                name="custom_title"
                className="form-control"
                value={property.custom_title}
                onChange={this.handleInputChange}
                pattern="[a-zA-Z0-9.][a-zA-Z0-9. ]*"
                title="Field cannot start with empty space."
                required
              />
            </div>
          </div>
          {!property.custom_title_generated ? (
            <div className="form-group row">
              <div className="offset-md-3 col-sm-6 col-md-6">
                <div className="alert alert-primary text-center" role="alert">
                  Title generated by System or Internal User
                </div>
              </div>
            </div>
          ) : null}
          {property.custom_title_generated ? (
            <div className="form-group row">
              <div className="offset-md-3 col-sm-6 col-md-6">
                <div className="alert alert-primary text-center" role="alert">
                  Title added by End User
                </div>
              </div>
            </div>
          ) : null}
          <div>
            <div className="x_title">
              <h2>Property Geotagging</h2>
              <div className="clearfix" />
            </div>
            {isManualGeoTag || (locateManually && changePlot && isManualGeoTag) ? (
              <div>
                <div className="col-md-12 col-sm-12" style={{ zIndex: '0' }}>
                  {this.renderGeotagButtons()}
                  <GoogleMapContainer onSelect={this.onMapClick} />
                </div>
                <div className="form-group row" style={{ paddingTop: 70 }}>
                  <div className="col-md-6 col-sm-6 offset-md-3">
                    <div className="alert alert-primary text-center">
                      <b>Latitude: </b>
                      {`${property.lat} `}
                      <b>Longitude: </b>
                      {property.lon}
                    </div>
                  </div>

                  {changePlot && this.renderCancelButton()}
                </div>
              </div>
            ) : null}
            {(!isManualGeoTag && locateManually && changePlot) ||
            (!isManualGeoTag &&
              (!property.geotagged_date || (property.propsure_id && changePlot))) ? (
              <div>
                {this.renderGeotagButtons()}
                <div className="form-group row">
                  <div className="col-md-12 col-sm-12" style={{ zIndex: '0' }}>
                    <MapContainer
                      mapZoom={mapZoom}
                      city={chosenMapCity}
                      housingScheme={chosenHousingScheme}
                      phaseSector={chosenPhaseSector}
                      blockSubsector={chosenBlockSubsector}
                      mapPlots={mapPlots}
                      mapCenter={mapCenter}
                      plotMarker={plotMarker}
                      onSelect={this.setMapPlot}
                      onClick={this.onMapClick}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="control-label col-md-3 col-sm-3">Select City</label>
                  <div className="col-md-6 col-sm6">
                    <Select
                      name="map-city"
                      multi={false}
                      value={chosenMapCity || ''}
                      onChange={(value) => {
                        this.setMapCity(value);
                      }}
                      options={mapCities}
                      valueKey="id"
                      labelKey="city_name"
                      clearable={false}
                    />
                  </div>
                  {changePlot && this.renderCancelButton()}
                </div>
                {loadingHousing ? <Loading /> : null}
                {housingSchemes && housingSchemes.length > 0 && (
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">Housing Scheme</label>
                    <div className="col-md-6 col-sm-6">
                      <Select
                        name="housing-scheme"
                        multi={false}
                        value={chosenHousingScheme || ''}
                        onChange={(value) => {
                          this.setMapHousingScheme(value);
                        }}
                        options={housingSchemes}
                        valueKey="id"
                        labelKey="housing_scheme_name"
                        clearable={false}
                      />
                    </div>
                  </div>
                )}
                {loadingPhase ? <Loading /> : null}
                {phaseSector && phaseSector.length > 0 && (
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">Select Phase / Sector</label>
                    <div className="col-md-6 col-sm-6">
                      <Select
                        name="phase-sector"
                        multi={false}
                        value={chosenPhaseSector || ''}
                        onChange={(value) => {
                          this.setMapPhaseSector(value);
                        }}
                        options={phaseSector}
                        valueKey="id"
                        labelKey="phase_sector_name"
                        clearable={false}
                      />
                    </div>
                  </div>
                )}
                {loadingBlock ? <Loading /> : null}
                {blockSubsectors.length > 0 && (
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">
                      {' '}
                      Select Block / Sub-Sector{' '}
                    </label>
                    <div className="col-md-6 col-sm-6">
                      <Select
                        name="block-subsector"
                        multi={false}
                        value={chosenBlockSubsector || ''}
                        onChange={(value) => {
                          this.setMapBlockSubSector(value);
                        }}
                        options={blockSubsectors}
                        valueKey="id"
                        labelKey="block_subsector_name"
                        clearable={false}
                      />
                    </div>
                  </div>
                )}
                {streets.length > 0 && chosenMapCity.id === '1' && (
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3"> Select Street No </label>
                    <div className="col-md-6 col-sm-6">
                      <Select
                        name="map-plot"
                        multi={false}
                        value={chosenStreet || ''}
                        onChange={(value) => {
                          this.filterPlotsByStreet(value);
                        }}
                        valueKey="value"
                        labelKey="value"
                        options={streets}
                        clearable={false}
                      />
                    </div>
                  </div>
                )}
                {mapPlots.length > 0 && (
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3"> Select Plot </label>
                    <div className="col-md-6 col-sm-6">
                      <Select
                        name="map-plot"
                        multi={false}
                        value={chosenMapPlot || ''}
                        onChange={(value) => {
                          this.setMapPlot(value);
                        }}
                        options={filteredPlots}
                        valueKey="id"
                        labelKey="info"
                        clearable={false}
                      />
                    </div>
                  </div>
                )}
                {plotsUnavailable && (
                  <div className="form-group row">
                    <div className="col-md-6 col-sm-6 offset-md-3">
                      <h6>Geo Data for Plots not available in this area</h6>
                    </div>
                  </div>
                )}
                {chosenMapPlot && (
                  <div className="form-group row">
                    <div className="col-md-6 col-sm-6 offset-md-3">
                      <div className="alert alert-primary">{`Propsure Plot ID is ${chosenMapPlot.id}`}</div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {!changePlot && property.geotagged_date && !isManualGeoTag && property.propsure_id ? (
              <div>
                <div className="form-group row">
                  <div className="col-sm-6 col-md-6 offset-sm-3" style={{ zIndex: '0' }}>
                    <MapContainer
                      mapZoom={18}
                      mapPlots={mapPlots}
                      mapCenter={mapCenter}
                      onSelect={this.onPlotSelect}
                      plotMarker={mapCenter}
                      onClick={this.onMapClick}
                    />
                  </div>
                </div>
                <br />
                <div className="form-group row">
                  <div className="col-md-6 col-sm-6 offset-md-3">
                    <div className="alert alert-primary text-center">
                      <b>Plot No: </b>
                      {`${mapPlot && mapPlot.info}, ${
                        mapPlot && mapPlot.housing_scheme.housing_scheme_name
                      }, ${mapPlot && mapPlot.city.city_name} and `}
                      <b>Propsure ID: </b>
                      {`${property.propsure_id}.`}
                      <br />
                      <b>Lat: </b>
                      {`${property.lat} `}
                      and <b>Long: </b>
                      {property.lon}
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3" style={{ paddingLeft: 0 }}>
                    <button
                      className={`btn btn-lg btn-outline-primary ${changePlot ? 'active' : ''}`}
                      type="button"
                      onClick={() => this.setState({ changePlot: true })}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {locateManually && !changePlot ? (
              <div>
                <div className="form-group row">
                  <div className="col-sm-6 col-md-6 offset-sm-3" style={{ zIndex: '0' }}>
                    <MapContainer
                      mapZoom={18}
                      mapPlots={mapPlots}
                      mapCenter={mapCenter}
                      onSelect={this.onPlotSelect}
                      plotMarker={mapCenter}
                      onClick={this.onMapClick}
                    />
                  </div>
                </div>
                <br />
                <div className="form-group row">
                  <div className="col-md-6 col-sm-6 offset-md-3">
                    <div className="alert alert-primary text-center">
                      <b>Lat: </b>
                      {`${property.lat} `}
                      and <b>Long: </b>
                      {property.lon}
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3" style={{ paddingLeft: 0 }}>
                    <button
                      className={`btn btn-lg btn-outline-primary ${changePlot ? 'active' : ''}`}
                      type="button"
                      onClick={() => this.setState({ changePlot: true, isManualGeoTag: true })}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="ln_solid" />
          </div>
          {address && (
            <div className="">
              <div className="x_title">
                <h2>Other Particulars</h2>
                <div className="clearfix" />
              </div>
              {property.address && (
                <div style={{ paddingTop: 15 }}>
                  <label className="control-label col-md-3 col-sm-3">Old Address</label>
                  <label className="col-md-6 col-sm-6">{property.address}</label>
                </div>
              )}
              <div style={{ paddingTop: 15 }}>
                <label className="control-label col-md-3 col-sm-3">Address</label>
                {address.map((ad, index) => {
                  const aLength = address.length;
                  let subsectorCheck = false;
                  let blockCheck = false;
                  let phaseCheck = false;
                  let galiCheck = false;
                  let streetCheck = false;
                  let plotCheck = false;
                  let i = 0;
                  while (i < aLength) {
                    if (address[i].type === 'subsector') {
                      subsectorCheck = true;
                    }
                    if (address[i].type === 'block') {
                      blockCheck = true;
                    }
                    if (address[i].type === 'phase') {
                      phaseCheck = true;
                    }
                    if (address[i].type === 'gali') {
                      galiCheck = true;
                    }
                    if (address[i].type === 'street') {
                      streetCheck = true;
                    }
                    if (address[i].type === 'plot_no') {
                      plotCheck = true;
                    }
                    i += 1;
                  }
                  return (
                    <div
                      key={`${removeKey} ${index + 1}`}
                      style={{ position: 'relative', top: '-36px' }}
                      className="form-group row"
                    >
                      <div className="col-md-2 col-sm-2 offset-md-3" style={{ paddingRight: 0 }}>
                        <select
                          name="addressType"
                          className="form-select custom-select"
                          onChange={(e) => this.handleAddressType(e, index)}
                          value={address[index].type ? address[index].type : ''}
                        >
                          <option value="">Select type</option>
                          <option disabled={streetCheck} value="street">
                            Street
                          </option>
                          <option disabled={subsectorCheck} value="subsector">
                            Subsector
                          </option>
                          <option disabled={blockCheck} value="block">
                            Block
                          </option>
                          <option disabled={phaseCheck} value="phase">
                            Phase
                          </option>
                          <option disabled={galiCheck} value="gali">
                            Gali
                          </option>
                          <option disabled={plotCheck} value="plot_no">
                            Plot no.
                          </option>
                        </select>
                      </div>
                      <div className="col-md-4 col-sm-4" style={{ paddingLeft: 10 }}>
                        <input
                          name="address"
                          type="text"
                          maxLength={65}
                          className="form-control"
                          value={address[index].value ? address[index].value : ''}
                          onChange={(e) => this.handleAddressInput(e, index)}
                        />
                        <p>
                          Max Length : 65 Letters &nbsp; &nbsp; &nbsp;{' '}
                          {address[index].remainingLetters} remaining
                        </p>
                      </div>
                      {address.length < 6 && address[index] === address[aLength - 1] ? (
                        <div id={`${index + 1}`} className="col-md-3 additionalPhonesA">
                          <button
                            aria-label="button"
                            className="btn btn-primary btn-sm additionalPhonesB fa fa-plus"
                            type="button"
                            title="add"
                            onClick={() => this.addAddressField(index)}
                          />
                        </div>
                      ) : null}
                      {(address[index].type && address[index] !== address[aLength - 1]) ||
                      address[5] ? (
                        <div id={`${index + 7}`} className="col-md-3 additionalPhonesA">
                          <button
                            aria-label="button"
                            className="btn btn-primary btn-sm additionalPhonesB fa fa-minus"
                            type="button"
                            title="remove"
                            onClick={() => this.removeAddressField(index)}
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out' && (
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Size Unit*</label>
                <div className={`${statsLead && !cmLead ? col8 : col6}`}>
                  <select
                    name="size_unit"
                    className="form-select custom-select"
                    value={property.size_unit}
                    onChange={this.handleInputChange}
                  >
                    <option value="sqft">Square Feet</option>
                    <option value="sqyd">Square Yard</option>
                    <option value="sqm">Square Meter</option>
                    <option value="kanal">Kanal</option>
                    <option value="marla">Marla</option>
                    <option value="acre">Acre</option>
                  </select>
                </div>
              </div>
            )}
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Size*</label>
            <div className="col-md-6 col-sm-6">
              <input
                type="number"
                name="size"
                className="form-control"
                value={property.size}
                onChange={this.handleInputChange}
                onWheel={(e) => e.target.blur()}
                min={1}
                step="0.001"
                onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()}
                required={
                  property.purpose !== 'sell' &&
                  property.purpose !== 'rent out' &&
                  property.purpose !== 'rentout'
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Price*</label>
            <div className="col-md-6 col-sm-6 price-label-container">
              <input
                type="number"
                name="price"
                className="form-control"
                value={property.price}
                onChange={this.handleInputChange}
                onWheel={(e) => e.target.blur()}
                min={1}
                required
              />
              {property.price >= 1000 ? (
                <span className="price-label">{convertPrice(property.price, 'PKR')}</span>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Video URL</label>
            <div className="col-md-6 col-sm-6">
              <input
                type="textarea"
                name="video"
                className="form-control"
                value={property.video || ''}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Comments</label>
            <div className="col-md-6 col-sm-6">
              <input
                type="text"
                name="comments"
                className="form-control"
                value={property.comments}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Agent Phone Number*</label>
            <div className="col-md-6 col-sm-6" style={{ display: 'inherit' }}>
              {property.phone && setCountryCode ? (
                <PhoneInput
                  name="phone"
                  country="pk"
                  value={property.phone}
                  onChange={(phone, country) => this.handlePhoneProperty(phone, country)}
                  buttonStyle={{ height: '35px' }}
                  enableLongNumbers
                  countryCodeEditable={false}
                />
              ) : (
                <input
                  name="phone"
                  type="text"
                  className="form-control"
                  value={property.phone}
                  onChange={this.handleInputChange}
                />
              )}
            </div>
            {property.phones ? (
              <div className="col-md-3 additionalPhonesA">
                <button
                  className="btn btn-primary btn-sm additionalPhonesB"
                  type="button"
                  title="add"
                  onClick={() => this.addMorePhoneNumber()}
                >
                  Add More
                </button>
              </div>
            ) : null}
          </div>
          {!editRCMPhoneValid && (
            <div className="form-group row">
              <div className="col-md-6 col-sm-6 offset-md-3">
                <Alert color="danger">Please enter a valid number</Alert>
              </div>
            </div>
          )}
          <div className="">
            {property.phones &&
              property.phones.map((phone, index) => (
                <div key={`${removeKey} ${index + 1}`} className="form-group row">
                  <label className="control-label col-md-3 col-sm-3">
                    {' '}
                    Additional Phone # {index + 1}
                  </label>
                  <div className="col-md-6 col-sm-6">
                    <PhoneInput
                      name="phone"
                      country="pk"
                      value={phone.phone}
                      onChange={(value, country) =>
                        this.changePhoneNumber(value, index, phone, country)
                      }
                      enableLongNumbers
                      countryCodeEditable={false}
                    />
                  </div>
                  <div className="col-md-3 additionalPhonesA">
                    <button
                      className="btn btn-danger btn-sm additionalPhonesB"
                      type="button"
                      title="remove"
                      onClick={() => this.removePhoneNumber(index, phone)}
                      style={{ width: '71px' }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            {!editPropertyPhoneValid && (
              <div className="form-group row">
                <div
                  ref={(ref) => {
                    this.additionalNumberRef = ref;
                  }}
                  className="col-md-6 col-sm-6 offset-md-3"
                >
                  <Alert color="danger">Please enter valid number</Alert>
                </div>
              </div>
            )}
            {extraNumber && (
              <div className="form-group row">
                <div
                  ref={(ref) => {
                    this.extraNumberRef = ref;
                  }}
                  className="col-md-6 col-sm-6 offset-md-3"
                >
                  <Alert color="danger">Only two additional phone numbers are allowed</Alert>
                </div>
              </div>
            )}
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Owner Name</label>
              <div className="col-md-6 col-sm-6">
                <input
                  type="text"
                  name="owner_name"
                  className="form-control"
                  value={property.owner_name}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Owner Phone Number</label>
              <div className="col-md-6 col-sm-6">
                {setCountryCode ? (
                  <PhoneInput
                    name="owner_phone"
                    country="pk"
                    value={property.owner_phone}
                    onChange={(phone, country) => this.handlePhoneOwner(phone, country)}
                    enableLongNumbers
                    countryCodeEditable={false}
                  />
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Same as Owner</label>
              <div className="col-md-6 col-sm-6">
                <input
                  name="same_as_owner"
                  type="checkbox"
                  checked={sameAsOwner}
                  style={{ marginTop: '12px' }}
                  onChange={this.handleOwnerCheckBox}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">POC Name</label>
              <div className="col-md-6 col-sm-6">
                <input
                  type="text"
                  name="poc_name"
                  className="form-control"
                  value={property.poc_name}
                  onChange={this.handleInputChange}
                  disabled={!!sameAsOwner}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">POC Phone Number</label>
              <div className="col-md-6 col-sm-6">
                {setCountryCode && (
                  <PhoneInput
                    key={pocPhone}
                    name="poc_phone"
                    country="pk"
                    value={property.poc_phone}
                    onChange={(phone, country) => this.handlePhonePoc(phone, country)}
                    enableLongNumbers
                    countryCodeEditable={false}
                    disabled={!!sameAsOwner}
                  />
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Exclusive Listing</label>
              <div className="col-md-6 col-sm-6">
                <input
                  name="same_as_owner"
                  type="checkbox"
                  checked={property.exclusiveListing}
                  style={{ marginTop: '12px' }}
                  onChange={this.handleExclusiveCheckbox}
                  disabled={this.getExclusiveBool()}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Description</label>
            <div style={{ zIndex: 1 }} className="col-md-6 col-sm-6">
              <RichTextEditor
                value={description}
                toolbarConfig={RTE_TOOLBAR_CONFIG}
                onChange={(e) => {
                  this.setDescription(e);
                }}
              />
            </div>
          </div>
          {property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out' && <div className="ln_solid" />}
          {type && (
            <div>
              <div>
                <div>
                  <h2>Additional Details</h2>
                  <div className="ln_solid" />
                  {this.renderGeneralFeatrues()}
                  <div>
                    {property.purpose === 'sale' && (
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">Down payment</label>
                        <div className="col-md-6 col-sm-6 price-label-container">
                          <input
                            className="form-control"
                            type="number"
                            name="downpayment"
                            display="price"
                            placeholder="Price in (PKR)"
                            value={this.selectedFeatures.downpayment}
                            onChange={(event) => {
                              this.setFeature(event.target.name, event.target.value);
                            }}
                            onWheel={(e) => e.target.blur()}
                            min={1}
                          />
                          {this.selectedFeatures.downpayment >= 1000 ? (
                            <span className="price-label">
                              {convertPrice(this.selectedFeatures.downpayment, 'PKR')}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-6 col-sm-6 offset-md-3">
                    {this.renderAdditionalFeatures()}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                {(property.jazzUser || property.EasyPaisa) && property.user.cnicIssuanceDate ? (
                  <>
                    <div className="control-label col-md-4 col-sm-3">
                      <input
                        type="checkbox"
                        name="eligibleForReward"
                        checked={property.eligibleForReward}
                        style={{ marginTop: '12px' }}
                        onChange={this.handleCheckbox}
                      />
                      &nbsp;
                      <strong> Eligible for reward program</strong>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          )}
          {property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out' && <div className="ln_solid" />}
          {(isWantedProperty &&
            property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose !== 'rent out') ||
          (isWantedProperty &&
            (property.purpose === 'sell' ||
              property.purpose === 'rentout' ||
              property.purpose === 'rent out') &&
            property.properties &&
            property.properties.length > 0) ||
          (property.purpose !== 'sell' &&
            property.purpose !== 'rentout' &&
            property.purpose === 'rent out') ||
          isAddLead
            ? this.renderActionButtons()
            : null}
          {(property.purpose === 'sell' ||
            property.purpose === 'rentout' ||
            property.purpose === 'rent out') &&
            property.properties &&
            property.properties.length <= 0 && (
              <div style={{ position: 'relative' }}>
                {/* <AddProperties match={match} history={history} wantedSaved={wantedSaved} /> */}

                {/* <button
                  type="submit"
                  className="btn"
                  style={{
                    border: '1px solid #37394F',
                    marginLeft: '13%',
                    color: '#37394F',
                    position: 'absolute',
                    top: '2px',
                    height: '42px',
                    width: '120px%',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '11.5897px',
                    lineHeight: '17px',
                    letterSpacing: '0.03em',
                  }}
                  onClick={() => this.redirectToPropertyForm()}
                >
                  ADD PROPERTY
                </button> */}

                {this.renderActionButtons()}
              </div>
            )}
          {property.status === 'pending' && property.edited_at === null && !property.catalogue ? (
            <div className="form-group row">
              <div className="col-md-8 col-sm-8 offset-md-3">
                <Dropdown
                  isOpen={actionDropdown}
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                    display: styleInlineFlex,
                  }}
                  toggle={() =>
                    this.setState((prevState) => ({
                      actionDropdown: !prevState.actionDropdown,
                    }))
                  }
                >
                  <DropdownToggle caret size="lg" color="primary">
                    Action
                    <i className={`fa fa-spinner fa-pulse ${!loading && 'd-none'}`} />{' '}
                  </DropdownToggle>
                  <DropdownMenu top>
                    <button
                      type="submit"
                      name="update"
                      className="dropdown-item"
                      style={{ border: 'none' }}
                    >
                      Save
                    </button>
                    <button
                      type="submit"
                      name="cataloging"
                      className="dropdown-item"
                      style={{ border: 'none' }}
                      onClick={() => this.catalogingDone()}
                    >
                      Cataloging Done
                    </button>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <div className="form-group row">
              <div className="col-md-8 col-sm-8 offset-md-3">
                <Button
                  outline
                  name="update"
                  className={`btn btn-lg ${loading ? 'disabled' : ''}`}
                  color="success"
                  style={{ marginRight: 5, marginBottom: 5 }}
                >
                  <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} />
                  Save
                </Button>
              </div>
            </div>
          )}
          {validRCMResponse && (
            <div className="form-group row">
              <div
                ref={(r) => {
                  this.addRCMRef = r;
                }}
                className="col-md-6 col-sm-6 offset-md-3"
              >
                <Alert color="success">{isAddLead ? 'RCM lead added' : 'RCM lead edited'}</Alert>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }

  renderCMLead() {
    let { isWantedProperty, isAddLead } = this.state;
    let wantedPropertyAreas = '';
    const { match } = this.props;
    const {
      property,
      cmLead,
      statsLead,
      cityId,
      projectNames,
      isLoadingExternally,
      projectTypes,
      validNumber,
      loading,
      validCMResponse,
    } = this.state;
    if (
      match.path === wantedAddLeadRoute ||
      match.path === voiceAddLeadRoute ||
      match.path === wantedProjectAddLeadRoute
    ) {
      isWantedProperty = true;
      isAddLead = true;
    }
    if (
      match.path === wantedEditPropertyRoute ||
      match.path === investEditPropertyRoute ||
      match.path === voiceEditLeadRoute ||
      match.path === wantedProjectEditPropertyRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      isWantedProperty = true;
    }
    if (isWantedProperty && property && property.wanted_areas) {
      property.wanted_areas.map((area) => {
        wantedPropertyAreas = wantedPropertyAreas.concat(area.area?.name, ', ');
        return area;
      });
    }
    if (wantedPropertyAreas) {
      wantedPropertyAreas = wantedPropertyAreas.slice(0, -2);
    }
    if (!isWantedProperty) {
      property.type = property.type.toLowerCase();
    }
    return (
      <div className="x_content">
        <br />
        <form id="demo-form2" className="form-horizontal form-label-left" onSubmit={this.addCM}>
          {isAddLead ? (
            <div>
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Name*</label>
                <div className="col-md-6 col-sm-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={property.name}
                    onChange={this.handleInputChange}
                    required
                    pattern="\s*\S+.*"
                    title="This field cannot start with an empty space."
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Purpose*</label>
                <div className={`${statsLead && !cmLead ? col8 : col6}`}>
                  <select
                    name="purpose"
                    value={property.purpose}
                    className="form-select custom-select"
                    onChange={this.handleInputChange}
                    required
                  >
                    <option value="">Select Purpose</option>
                    <option value="buy">Buy</option>
                    <option value="rent">Rent</option>
                    <option value="sell">Sell</option>
                    <option value="rentout">Rent Out</option>
                    <option value="invest">Invest</option>
                  </select>
                </div>
              </div>
            </div>
          ) : null}
          {isWantedProperty && !isAddLead ? (
            <div>
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">Name*</label>
                <div className="col-md-6 col-sm-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={property.name}
                    onChange={this.handleInputChangeLead}
                    required
                    pattern="\s*\S+.*"
                    title="This field cannot start with an empty space."
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">City*</label>
            <div className="col-md-6 col-sm-6">
              <Select.Async
                style={{ borderRadius: 5 }}
                name="city"
                multi={false}
                value={cityId}
                onChange={(value) => this.setCity(value)}
                loadOptions={(input) => PropertyForm.getCity(input)}
                valueKey="id"
                labelKey="name"
                clearable={false}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Project Name*</label>
            <div className="col-md-6 col-sm-6">
              <Select
                style={{ borderRadius: 5 }}
                name="projectId"
                multi={false}
                value={property.projectId}
                onChange={(value) => this.setProject(value)}
                options={projectNames}
                valueKey="id"
                labelKey="name"
                clearable={false}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Product Type*</label>
            <div className="col-md-6 col-sm-6">
              <Select
                style={{ borderRadius: 5 }}
                name="armsProjectTypeId"
                isLoading={isLoadingExternally}
                multi={false}
                value={
                  property.armsProjectTypeId ? property.armsProjectTypeId : property.projectType
                }
                onChange={(value) => this.setProjectType(value)}
                options={projectTypes}
                valueKey={`${property.armsProjectTypeId ? 'id' : 'name'}`}
                labelKey="name"
                clearable={false}
                required
              />
            </div>
          </div>
          {match.path !== voiceAddLeadRoute && match.path !== voiceEditLeadRoute && (
            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3">Category</label>
              <div className="col-md-6 col-sm-6">
                <select
                  name="category"
                  className="form-select custom-select"
                  value={property.category}
                  onChange={this.handleInputChange}
                >
                  <option value="friend-&-family">Friend & Family</option>
                  <option value="walk-in">Walk In</option>
                </select>
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="control-label col-md-3 col-sm-3">Min Price</label>
            <div className="col-md-2 col-sm-2 price-label-container">
              <input
                type="number"
                name="min_price"
                value={property.min_price}
                onChange={this.handleCMChange}
                className="form-control"
                onWheel={(e) => e.target.blur()}
                min={1}
              />
              {property.min_price >= 1000 ? (
                <span className="price-label">{convertPrice(property.min_price, 'PKR')}</span>
              ) : null}
            </div>
            <label className="control-label col-md-2 col-sm-2">Max Price</label>
            <div className="col-md-2 col-sm-2 price-label-container">
              <input
                type="number"
                name="max_price"
                value={property.max_price}
                onChange={this.handleCMChange}
                className="form-control"
                onWheel={(e) => e.target.blur()}
                min={1}
              />
              {property.max_price >= 1000 ? (
                <span className="price-label">{convertPrice(property.max_price, 'PKR')}</span>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className={`control-label ${col3}`}>Phone Number*</label>
            <div
              ref={(r) => {
                this.myRef = r;
              }}
              className="col-md-6 col-sm-6"
            >
              <PhoneInput
                name="phone"
                country="pk"
                value={property.phone}
                onChange={(phone, country) => this.handlePhoneProperty(phone, country)}
                required
                enableLongNumbers
                countryCodeEditable={false}
              />
            </div>
          </div>
          {!validNumber ? (
            <div className="form-group row">
              <div className="col-md-6 col-sm-6 offset-md-3">
                <Alert id="addNumber" color="danger">
                  Please enter a valid number
                </Alert>
              </div>
            </div>
          ) : null}
          <div className="ln_solid" />
          <div className="form-group row">
            <div className="col-md-6 col-sm-6 offset-md-3">
              <Button className={`btn btn-success btn-lg ${loading ? 'disabled' : ''}`}>
                <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} />
                Save
              </Button>
            </div>
          </div>
          {validCMResponse ? (
            <div className="form-group row">
              <div
                ref={(r) => {
                  this.addCMRef = r;
                }}
                className="col-md-6 col-sm-6 offset-md-3"
              >
                <Alert color="success">{isAddLead ? 'CM lead added' : 'CM lead edited'}</Alert>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  }

  renderStats() {
    const {
      city,
      cityOptions,
      housingSchemesOptions,
      housing,
      phaseSectorOptions,
      phase,
      blockSectorOptions,
      block,
      loadingGeotagged,
      cityData,
      typeListings,
      property,
      purposeListings,
      matchedListings,
      showAreaBanner,
    } = this.state;
    return (
      <div style={{ paddingLeft: 0, marginLeft: '-6px' }} className="col-md-2 col-sm-2">
        <div
          style={{
            paddingLeft: '3px',
            paddingRight: '3px',
            paddingTop: '3px',
            paddingBottom: '3px',
            borderRadius: 5,
          }}
          className="jumbotron"
        >
          <h3 className="text-center">Stats</h3>
          <div className="row">
            <div className="col-sm-12">
              <Select
                style={{ borderRadius: 5 }}
                name="city"
                multi={false}
                value={city ? city.id : null}
                onChange={(value) => this.setStatsCity(value)}
                options={cityOptions}
                valueKey="id"
                labelKey="city_name"
                clearable
                placeholder="Select City"
              />
            </div>
          </div>
          {housingSchemesOptions && housingSchemesOptions.length ? (
            <div className="row">
              <div className="col-sm-12">
                <Select
                  style={{ borderRadius: 5 }}
                  name="housing"
                  multi={false}
                  value={housing ? housing.id : null}
                  onChange={(value) => this.setStatsHousing(value)}
                  options={housingSchemesOptions}
                  valueKey="id"
                  labelKey="housing_scheme_name"
                  placeholder="Select Housing Scheme"
                  clearable
                />
              </div>
            </div>
          ) : null}
          {phaseSectorOptions && phaseSectorOptions.length ? (
            <div className="row">
              <div className="col-sm-12">
                <Select
                  style={{ borderRadius: 5 }}
                  name="phase"
                  multi={false}
                  value={phase ? phase.id : null}
                  onChange={(value) => this.setStatsPhase(value)}
                  options={phaseSectorOptions}
                  valueKey="id"
                  labelKey="phase_sector_name"
                  placeholder="Select Phase/Sector"
                  clearable
                  required
                />
              </div>
            </div>
          ) : null}
          {blockSectorOptions && blockSectorOptions.length ? (
            <div className="row">
              <div className="col-sm-12">
                <Select
                  style={{ borderRadius: 5 }}
                  name="block"
                  multi={false}
                  value={block ? block.id : null}
                  onChange={(value) => this.setStatsBlock(value)}
                  options={blockSectorOptions}
                  valueKey="id"
                  labelKey="block_subsector_name"
                  placeholder="Select Block/Subsector"
                  clearable
                  required
                />
              </div>
            </div>
          ) : null}
          {loadingGeotagged ? <Loading /> : null}
          <div className="table-responsive" style={{ borderRadius: 5 }}>
            {!loadingGeotagged ? (
              <div className="container-fluid px-2 py-2">
                <div className="row">
                  <div
                    className="col mx-2 text-center"
                    style={{ height: '95px', backgroundColor: 'white' }}
                  >
                    <h3 style={{ lineHeight: '2' }}>{cityData ? cityData.total : null}</h3>
                    <h6 style={{ color: '#26aa95' }}>Total plots in area</h6>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="container-fluid px-2 py-2">
            <div className="row">
              <div
                className="col mx-2 text-center"
                style={{ height: '95px', backgroundColor: 'white', borderRadius: 5 }}
              >
                <h3 style={{ lineHeight: '2' }}>{typeListings || null}</h3>
                <h6 style={{ color: '#26aa95' }}>Listings in {property.type}</h6>
              </div>
            </div>
            <div className="row" style={{ marginTop: 5 }}>
              <div
                className="col mx-2 text-center"
                style={{ height: '95px', backgroundColor: 'white', borderRadius: 5 }}
              >
                <h3 style={{ lineHeight: '2' }}>{purposeListings || null}</h3>
                <h6 style={{ color: '#26aa95' }}>Listings for {property.purpose}</h6>
              </div>
            </div>
            <div className="row" style={{ marginTop: 5 }}>
              <div
                className="col mx-2 text-center"
                style={{ height: '95px', backgroundColor: 'white', borderRadius: 5 }}
              >
                <h3 style={{ lineHeight: '2' }}>{matchedListings || null}</h3>
                <h6 style={{ color: '#26aa95' }}>Listings as per requirements</h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 text-center">
              {showAreaBanner && <div className="alert alert-primary">Please select an area</div>}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 text-center">
              <button type="button" className="btn btn-primary" onClick={() => this.refreshStats()}>
                Refresh Stats
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLoader() {
    const { mainLoading } = this.state;
    return <Loader loading={mainLoading} position="fixed" transparent />;
  }

  render() {
    let { isWantedProperty } = this.state;
    let wantedPropertyAreas = '';
    const { match } = this.props;
    const { property, cmLead, statsLead, isDataChanged } = this.state;
    if (
      match.path === wantedAddLeadRoute ||
      match.path === voiceAddLeadRoute ||
      match.path === wantedProjectAddLeadRoute
    ) {
      isWantedProperty = true;
    }
    if (
      match.path === wantedEditPropertyRoute ||
      match.path === investEditPropertyRoute ||
      match.path === voiceEditLeadRoute ||
      match.path === wantedProjectEditPropertyRoute ||
      match.path === rwrEditPropertyRoute
    ) {
      isWantedProperty = true;
    }
    if (isWantedProperty && property && property.wanted_areas) {
      property.wanted_areas.map((area) => {
        wantedPropertyAreas = wantedPropertyAreas.concat(area.area?.name, ', ');
        return area;
      });
    }
    if (wantedPropertyAreas) {
      wantedPropertyAreas = wantedPropertyAreas.slice(0, -2);
    }
    if (!isWantedProperty) {
      property.type = property.type.toLowerCase();
    }
    return (
      <CanAdd>
        <div>
          {this.renderLoader()}
          <div className="row animated fadeIn">
            {isDataChanged
              ? window.addEventListener('beforeunload', handleUnload)
              : window.removeEventListener('beforeunload', handleUnload)}
            <AlertMessage isDataChanged={isDataChanged} />
            {this.renderFollowUp()}
            {this.renderCreateUser()}
            {this.renderComments()}
            <div className="col-12">
              <div className="row">
                <div
                  className={`${
                    statsLead && !cmLead ? 'col-md-10 col-sm-10' : 'col-md-12 col-sm-12'
                  }`}
                >
                  <div className="x_panel">
                    <div className="x_title">
                      {match.path === wantedAddLeadRoute ||
                      match.path === voiceAddLeadRoute ||
                      match.path === wantedProjectAddLeadRoute ? (
                        <h2 style={{ display: styleInlineBlock }}>Add Lead Details</h2>
                      ) : null}
                      {match.path === wantedEditPropertyRoute ||
                      match.path === investEditPropertyRoute ||
                      match.path === voiceEditLeadRoute ||
                      match.path === rwrEditPropertyRoute ||
                      match.path === wantedProjectEditPropertyRoute ? (
                        <h2 style={{ display: styleInlineBlock }}>Edit Lead Details</h2>
                      ) : null}
                      <div className="clearfix" />
                    </div>
                    {(match.path === wantedEditPropertyRoute ||
                      match.path === investEditPropertyRoute ||
                      match.path === wantedAddLeadRoute ||
                      match.path === voiceAddLeadRoute ||
                      match.path === voiceEditLeadRoute ||
                      match.path === rwrEditPropertyRoute ||
                      match.path === wantedProjectEditPropertyRoute) &&
                    !cmLead
                      ? this.renderLead()
                      : null}
                    {match.path === editPropertyRoute || match.path === wantedEditPropertyInfoRoute
                      ? this.renderProperty()
                      : null}
                    {cmLead || match.path === wantedProjectAddLeadRoute
                      ? this.renderCMLead()
                      : null}
                  </div>
                </div>
                {statsLead && !cmLead ? this.renderStats() : null}
              </div>
            </div>
          </div>
        </div>
      </CanAdd>
    );
  }
}

PropertyForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  propertyError: PropTypes.instanceOf(Boolean).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  back: state.back.back,
  wantedID: state.wantedID.wantedID,
  propertyID: state.propertyID.propertyID,
  propertyRequestParams: state.propertyRequestParams.propertyRequestParams,
  propertyError: state.propertyError.propertyError,
});

export default connect(mapStateToProps)(PropertyForm);
