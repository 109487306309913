export function generateImageUrl(url, resolution = '840') {
  if (url?.includes('original') || url?.includes('images/200')) {
    return `${process.env.REACT_APP_IMAGES_URL}${url
      ?.replace('original', resolution)
      .replace('200', resolution)
      .replace('/images', 'images')
      .replace('https://images.graana.rocks', '')}`;
  }

  // return url?.includes('cloudinary')
  //   ? url.replace('https://res.cloudinary.com', '')?.replace('http://res.cloudinary.com', '')
  //   : url;
  return url;
}
